import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "recompose";

import {postJob, putJob, getJobs, postJobPicture} from "../../stores/jobs/actions";
import {getNbModerated, getStaticDatas, handleError} from "../../stores/infos/actions";
import {JOBS} from "../../constants/routes";
import Spinner from "../common/Spinner";

import CustomForm from "../common/form/CustomForm";
import AuthService from "../../services/AuthService";
import {ROLE_ADMIN, ROLE_MODERATOR} from "../../constants/roles";

// Intial State of the Component
const INITIAL_STATE = {
  id:'',
  title:'',
  level:'',
  contract:'',
  salary: 0,
  duration: 0,
  hours: 0,
  description:'',
  studies:'',
  experience:'',
  company_name:'',
  activity:'',
  city:'',
  company_contact:'',
  phone:'',
  mail:'',
  address:'',
  push: false,
  cities: {}
};

//TODO : check if media needed
const stateWithJob = (job, media) => ({
  id: job.id,
  title: job.title,
  level:job.level,
  contract:job.contract,
  salary: job.salary,
  duration: job.duration,
  hours: job.hours,
  description:job.description,
  studies:job.studies,
  experience:job.experience,
  company_name:job.company_name,
  activity:job.activity,
  city:job.city,
  company_contact:job.company_contact,
  phone:job.phone,
  mail:job.mail,
  address:job.address,
  imagePath: media ? media.image_path : ''
});

export class JobForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    pending: PropTypes.bool.isRequired,
    sending: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    jobs: PropTypes.array,
    getStaticDatas: PropTypes.func.isRequired,
    postJob: PropTypes.func.isRequired,
    putJob: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired
  };

  static defaultProps = {
    jobs: null
  };

  static getDerivedStateFromProps(props, state) {
    let newState = {...state};

    if (!props.roles && !props.pending) {
      props.getRoles();
    }
    if (!props.cities) {
      props.getStaticDatas();
    }
    if (props.cities) {
      console.log(props.cities);
      newState = {...newState, cities: props.cities};
    }
    if (!props.jobs && !props.pending && props.token && props.match && props.match.params.jobId) {
      props.getJobs(props.token);
    }
    if (props.jobs && props.match && props.match.params.jobId && state.id === INITIAL_STATE.id) {
      const jobs = props.jobs;
      const currentJobId = props.match.params.jobId;
      const foundJob = jobs.find(job => job.id === currentJobId);

      if (foundJob) {
        const media = foundJob.medias ?
          foundJob.medias.find(media => media.position === 0) : null;
        newState = {...newState, ...stateWithJob(foundJob, media)};
      }
    }

    if (props.sending && !state.sending) { // Start sending
      return {sending: true};
    }
    if (!props.sending && state.sending) { // End sending
      if (state.pictureToUpload && state.id) { // picture To Upload after deal sent
        props.postJobPicture(props.token, state.id, state.pictureToUpload, (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(state.pictureToUpload.name + ' -> '
            + percentCompleted + '% (' + progressEvent.loaded + ' / ' + progressEvent.total + ')');
        });
        return {
          pictureToUpload: null,
          sending: false
        };
      } else if (!state.pictureToUpload) {
        props.history.push(JOBS);
      }
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  onSubmit = (event, formState) => {

    // Retrieve ImageToUpload if available
    if (formState.pictureToUpload) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        pictureToUpload: formState.pictureToUpload
      });
    }

    if (this.state.id === INITIAL_STATE.id) {
      this.props.postJob(
        this.props.token,
        formState
      )
        .then((jobId => {
          if (this.state.pictureToUpload) {
            this.setState({id: jobId});
          }
        }))
        .catch((error) => {
          this.props.handleError(error);
        });
    } else {
      this.props.putJob(
        this.props.token,
        this.state.id,
        formState
      )
        .then(jobId => {
          this.props.getNbModerated(this.props.token);
          if (this.state.pictureToUpload) {
            this.setState({id: jobId});
          }
        })
        .catch((error) => {
          this.props.handleError(error);
        });
    }
    event.preventDefault();
  };

  render() {

    const {pending, sending} = this.props;

    const {id, cities} = this.state;

    const buttonLabel = sending ?
      (<Spinner variant="nomargin" />)
      : id === ''  ? 'Créer' : 'Éditer';

    const load = (<Spinner />);

    let formFields = [
      {id: 'title', type:'textField', label: 'Titre', isMandatory:true},
      {id: 'level', type:'textField', gridMD:12, label: 'Niveau', isMandatory:true},
      {id: 'contract', type:'textField', gridMD:12, label: 'Contrat', isMandatory:true},
      {id: 'salary', type:'textField', subtype:'number', gridMD:6, label: 'Salaire (en K€)', isMandatory:true},
      {id: 'duration', type:'textField', subtype:'number', gridMD:6, label: 'Durée', isMandatory:true},
      {id: 'hours', type:'textField', subtype:'number', gridMD:6, label: 'Heures', isMandatory:true},
      {id: 'description', type:'richText', gridMD:12, label: 'Description', isMandatory:true},
      {id: 'studies', type:'textField', gridMD:12, label: 'Etudes', isMandatory:true},
      {id: 'experience', type:'textField', gridMD:12, label: 'Expérience', isMandatory:true},
      {id: 'company_name', type:'textField', gridMD:12, label: 'Nom de l\'entreprise', isMandatory:true},
      {id: 'activity', type:'textField', gridMD:12, label: 'Activité', isMandatory:true},
      {id: 'city', type:'selectField', gridMD:6, options: cities, label: 'Ville', isMandatory:true},
      {id: 'company_contact', type:'textField', gridMD:12, label: 'Contact de l\'entreprise', isMandatory:true},
      {id: 'phone', type:'textField', gridMD:6, label: 'Téléphone', isMandatory:true},
      {id: 'mail', type:'textField', gridMD:6, label: 'Mail', isMandatory:true},
      {id: 'address', type:'textField', label: 'Adresse', isMandatory:true},
    ];

    if (AuthService.isGranted(ROLE_MODERATOR, this.props.authUser, this.props.roles)) {
      formFields.push({id: 'push', type:'checkboxField', label: 'Notifier les utilisateurs', isMandatory:true});
    }

    const customForm = (
      <CustomForm
        data={this.state}
        formFields={formFields}
        buttonLabel={buttonLabel}
        onSubmit={this.onSubmit}
        sending={sending}
      />
    );

    if (pending) {
      return (load);
    } else {
      return (customForm);
    }
  }
}

function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    roles: state.roles.content,
    token: state.users.authUser.ra,
    cities: state.infos.communes,
    jobs: state.jobs.content,
    pending: state.jobs.pending || state.users.pending,
    sending: state.jobs.sending
  };
}

export default compose(
  connect(mapStateToProps, {
    getJobs, postJob, putJob, handleError, getStaticDatas, postJobPicture, getNbModerated
  }),
)(JobForm);
