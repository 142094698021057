import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import {VERDUN_GREY_BLUE} from "../../../constants/colors";

/**
 * Style of Custom Text Field elements
 *
 */
const styles = () => ({
  textField: {
    width: '100%'
  },
  cssUnderline: {
    '&:after': {
      borderBottomColor: VERDUN_GREY_BLUE,
    },
  },
  cssLabel: {
    '&$cssFocused': {
      color: VERDUN_GREY_BLUE
    },
  },
  cssFocused: {},
});

/**
 * Class CustomSelectField
 *
 * @Component : <CustomSelectField />
 */
const CustomSelectField = props => {
  // Constant
  const {
    id,
    label,
    onChange,
    value,
    isMandatory,
    classes,
    gridMD,
    noLabel,
    disabled
  } = props;

  // Element
  const textField = (
    <TextField
      className={classes.textField}
      select
      id={id}
      onChange={onChange}
      disabled={disabled}

      value={value ? value : ''}
      label={noLabel ? null : `${label}` + (isMandatory ? '*' : '')}

      margin="normal"
      variant="filled"
      InputProps={{
        classes: {
          underline: classes.cssUnderline,
        }
      }}
      InputLabelProps={{
        FormLabelClasses: {
          root: classes.cssLabel,
          focused: classes.cssFocused,
        }
      }}
    >
      {props.children}
    </TextField>
  );

  return (
    <Grid item xs={12} sm={12} md={gridMD}>
      {textField}
    </Grid>
  );
};


CustomSelectField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  children: PropTypes.array.isRequired,
  isMandatory: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  gridMD: PropTypes.number.isRequired,
  noLabel: PropTypes.bool,
  disabled: PropTypes.bool
};

CustomSelectField.defaultProps = {
  value: null,
  label: '',
  isMandatory: false,
  noLabel: false,
  disabled: false
};

export default withStyles(styles)(CustomSelectField);
