import React, {Component} from 'react';
import PropTypes from "prop-types";
import {compose} from "recompose";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {connect} from "react-redux";
import {resetError, resetInfo} from "../../stores/infos/actions";
import {VERDUN_GRADIENT_BLUE} from "../../constants/colors";

const styles = () => ({
  info: {
    backgroundColor: VERDUN_GRADIENT_BLUE,
  }
});

export class SnackBar extends Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    resetError: PropTypes.func.isRequired,
    resetInfo: PropTypes.func.isRequired,
    error: PropTypes.string,
    info: PropTypes.string
  };

  static defaultProps = {
    error: null,
    info: null
  };

  handleErrorSnackbarClose = () => {
    this.props.resetError()
  };

  handleInfoSnackbarClose = () => {
    this.props.resetInfo()
  };

  handleReload = () => {
    window.location.reload();
  };

  // Render

  render() {
    const {error, info, classes} = this.props;
    return (
      <div>
        <Snackbar
          open={error !== null}
          message={error || ''}
          onClose={this.handleErrorSnackbarClose}
          action={[
            <Button
              id="error"
              key="undo"
              color="secondary"
              size="small"
              variant="outlined"
              onClick={this.handleErrorSnackbarClose}
            >
              Fermer
            </Button>,
          ]}
        />

        <Snackbar
          open={info !== null}
          message={info || ''}
          autoHideDuration={4000}
          onClose={this.handleInfoSnackbarClose}
          ContentProps={{
            classes: {
              root: classes.info
            }
          }}
          action={[
            <IconButton
              id="closeinfo"
              key="close"
              color="inherit"
              onClick={this.handleInfoSnackbarClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    error: state.infos.error,
    info: state.infos.info
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    resetError,
    resetInfo,
  })
)(SnackBar);
