
export const VERDUN_GRADIENT_PURPLE = '#C669FF';
export const VERDUN_DARK_BLUE = '#003958';
export const VERDUN_GREY_BLUE = '#7F9CAC';
export const VERDUN_DARK_BLUE_TRANSPARENT = '#80003958';

export const VERDUN_BLUE_LIGHT = '#F0F6FA';
export const VERDUN_GREY = '#CCCCCC';
export const VERDUN_BORDER = '#BFCDD5';

export const VERDUN_GRADIENT_BLUE = '#4DD9FC';
