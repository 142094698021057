import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import LocationForm from "../../components/forms/LocationForm";
import LargePaper from "../../components/common/LargePaper";

export const LocationEditPage = props => {
  return (
    <LargePaper title="Éditer un POI Annuaire">
      <LocationForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

LocationEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withAuthorization('Annuaire')(LocationEditPage);
