import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "recompose";

import {putSignal, getSignals, postSignalPicture} from "../../stores/signals/actions";
import {getNbModerated, getStaticDatas, handleError} from "../../stores/infos/actions";
import {SIGNALS} from "../../constants/routes";
import Spinner from "../common/Spinner";

import CustomForm from "../common/form/CustomForm";
import {ROLE_MODERATOR} from "../../constants/roles";
import AuthService from "../../services/AuthService";

// Intial State of the Component
const INITIAL_STATE = {
  id:'',
  title:'',
  city:'',
  address:'',
  state:0,
  cities: {}
};

//TODO : check if media needed
const stateWithSignal = (signal, media) => ({
  id: signal.id,
  title: signal.title,
  city:signal.city,
  address:signal.address,
  state:signal.state,
  imagePath: media ? media.image_path : ''
});

const signalStates = {
  0: 'Envoyé',
  1: 'Lu',
  2: 'En cours de traitement',
  3: 'Traité',
  4: 'Rejeté'
};

export class SignalForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    pending: PropTypes.bool.isRequired,
    sending: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    jobs: PropTypes.array,
    getStaticDatas: PropTypes.func.isRequired,
    putSignal: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired
  };

  static defaultProps = {
    signals: null
  };

  static getDerivedStateFromProps(props, state) {
    let newState = {...state};

    if (!props.roles && !props.pending) {
      props.getRoles();
    }
    if (!props.cities) {
      props.getStaticDatas();
    }
    if (props.cities) {
      newState = {...newState, cities: props.cities};
    }
    if (!props.signals && !props.pending && props.token && props.match && props.match.params.signalId) {
      props.getSignals(props.token);
    }
    if (props.signals && props.match && props.match.params.signalId && state.id === INITIAL_STATE.id) {
      const signals = props.signals;
      const currentSignalId = props.match.params.signalId;
      const foundSignal = signals.find(signal => signal.id === currentSignalId);

      if (foundSignal) {
        const media = foundSignal.medias ?
          foundSignal.medias.find(media => media.position === 0) : null;
        newState = {...newState, ...stateWithSignal(foundSignal, media)};
      }
    }

    if (props.sending && !state.sending) { // Start sending
      return {sending: true};
    }
    if (!props.sending && state.sending) { // End sending
      if (state.pictureToUpload && state.id) { // picture To Upload after deal sent
        props.postSignalPicture(props.token, state.id, state.pictureToUpload, (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(state.pictureToUpload.name + ' -> '
            + percentCompleted + '% (' + progressEvent.loaded + ' / ' + progressEvent.total + ')');
        });
        return {
          pictureToUpload: null,
          sending: false
        };
      } else if (!state.pictureToUpload) {
        props.history.push(SIGNALS);
      }
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  onSubmit = (event, formState) => {

    // Retrieve ImageToUpload if available
    if (formState.pictureToUpload) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        pictureToUpload: formState.pictureToUpload
      });
    }

    this.props.putSignal(
      this.props.token,
      this.state.id,
      formState
    )
    .then(signalId => {
      this.props.getNbModerated(this.props.token);
      if (this.state.pictureToUpload) {
        this.setState({id: signalId});
      }
    })
    .catch((error) => {
      this.props.handleError(error);
    });

    event.preventDefault();
  };

  render() {

    const {pending, sending} = this.props;

    const {id, cities} = this.state;

    const buttonLabel = sending ?
      (<Spinner variant="nomargin" />)
      : id === ''  ? 'Créer' : 'Éditer';

    const load = (<Spinner />);

    let formFields = [
      {id: 'title', type:'textField', label: 'Titre', isMandatory:true},
      {id: 'imagePath', picture: 'pictureToUpload', type: 'pictureDropzone', isMandatory: false},
      {id: 'city', type:'selectField', gridMD:6, options: cities, label: 'Ville', isMandatory:true},
      {id: 'address', type:'textField', label: 'Adresse', isMandatory:true},
      {id: 'state', type:'selectField', label: 'Etat de suivi', options: signalStates, isMandatory:true},
    ];

    if (AuthService.isGranted(ROLE_MODERATOR, this.props.authUser, this.props.roles)) {
      formFields.push({id: 'push', type:'checkboxField', label: 'Notifier les utilisateurs', isMandatory:true});
    }

    const customForm = (
      <CustomForm
        data={this.state}
        formFields={formFields}
        buttonLabel={buttonLabel}
        onSubmit={this.onSubmit}
        sending={sending}
      />
    );

    if (pending) {
      return (load);
    } else {
      return (customForm);
    }
  }
}

function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    roles: state.roles.content,
    token: state.users.authUser.ra,
    cities: state.infos.communes,
    signals: state.signals.content,
    pending: state.signals.pending || state.users.pending,
    sending: state.signals.sending
  };
}

export default compose(
  connect(mapStateToProps, {
    getSignals, putSignal, handleError, getStaticDatas, postSignalPicture, getNbModerated
  }),
)(SignalForm);
