import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose} from "recompose";

import {withStyles} from "@material-ui/core/styles/index";
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete'
import UpIcon from '@material-ui/icons/ArrowUpward';
import DownIcon from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import stylesDefault from "../../../constants/stylesDefault";

/**
 * Style of Row elements
 *
 * @param theme
 * @returns json
 */
const styles = theme => ({
  Row: {
    webkitTransform: 'translateZ(0)',
    webkitTransition: 'all 0.2s ease-out',
    transition: 'all 0.2s ease-out',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light + ' !important',
    },
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.secondary.light,
    }
  },
  optionsTableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 10,
    paddingLeft: 10
  }
});

/**
 * Class CustomTableRow
 *
 * @Component : <CustomTableRow />
 * @Extend : React.Component
 */
class CustomTableRow extends Component {

  // ----------------
  // Type of each Props

  static propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    children: PropTypes.array.isRequired,
    deleteDialogText: PropTypes.string,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    sending: PropTypes.bool.isRequired,
    element: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    onUpOrder: PropTypes.func,
    onDownOrder: PropTypes.func,
    withDeleteButton: PropTypes.bool,
    dataLength: PropTypes.number.isRequired
  };

  // ----------------
  // Default Values

  static defaultProps = {
    deleteDialogText: 'Êtes-vous sur de vouloir supprimer cet élément ?',
    onUpOrder: null,
    onDownOrder: null,
    onDelete: null,
    withDeleteButton: true
  };

  static getDerivedStateFromProps(props) {
    let newState = null;
    if (!props.sending) {
      newState = {
        ...newState,
        deletingItemId: '',
        upItemId: '',
        downItemId: '',
      }
    }
    return newState;
  }

  // ----------------
  // Constructor

  constructor(props, context) {
    super(props, context);
    this.state = {
      openConfirmModal: false,
      deletingItemId: '',
      upItemId: '',
      downItemId: '',
    };
  }

  // ----------------
  // Handler

  handleEdit = event => {
    event.stopPropagation();
    this.props.onEdit(this.props.element);
  };

  handleToggleDeleteModal = () => {
    this.setState({openConfirmModal: !this.state.openConfirmModal});
  };

  handleDelete = event => {
    event.stopPropagation();
    event.preventDefault();

    // Prepare State
    this.setState({openConfirmModal: false});
    this.setState({deletingItemId: this.props.element.id});

    // Delete
    this.props.onDelete(this.props.element);
  };

  handleUp = event => {
    event.preventDefault();
    this.setState({upItemId: this.props.element.id});
    this.props.onUpOrder(this.props.element);
  };

  handleDown = event => {
    event.preventDefault();
    this.setState({downItemId: this.props.element.id});
    this.props.onDownOrder(this.props.element);
  };


  // ----------------
  // Rendering

  render() {
    // Constant
    const {
      element,
      deleteDialogText,
      fullScreen,
      sending,
      classes
    } = this.props;

    // Element

    const editButton = (
      <Tooltip title='Editer'>
        <IconButton
          id="edit"
          aria-label="update"
          onClick={this.handleEdit}
          color="primary"
          disabled={sending}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    );

    const deleteButton = (
      <Tooltip title='Supprimer'>
        <IconButton
          id="delete"
          aria-label="update"
          onClick={this.handleToggleDeleteModal}
          color="primary"
          disabled={sending}
        >
          <DeleteIcon />
          {sending && this.state.deletingItemId === element.id &&
          <CircularProgress size={50} style={stylesDefault.fabProgress} />}
        </IconButton>
      </Tooltip>
    );

    const upButton = (
      <Tooltip title='Remonter dans la liste'>
        <IconButton
          id="up"
          aria-label="update"
          onClick={this.handleUp}
          color="primary"
          disabled={sending || element.order < 1}
        >
          <UpIcon />
          {sending && this.state.upItemId === element.id &&
          <CircularProgress size={50} style={stylesDefault.fabProgress} />}
        </IconButton>
      </Tooltip>
    );

    const downButton = (
      <Tooltip title='Descendre dans la liste'>
        <IconButton
          id="down"
          aria-label="update"
          onClick={this.handleDown}
          color="primary"
          disabled={sending || !(element.order < this.props.dataLength -1)}
        >
          <DownIcon />
          {sending && this.state.downItemId === element.id &&
          <CircularProgress size={50} style={stylesDefault.fabProgress} />}
        </IconButton>
      </Tooltip>
    );

    const deleteModal = (
      <Dialog
        fullScreen={fullScreen}
        open={this.state.openConfirmModal}
        onClose={this.handleToggleDeleteModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
        >
          Attention
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {deleteDialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleDelete}
            color="primary"
            autoFocus
          >
            Oui
          </Button>
          <Button
            onClick={this.handleToggleDeleteModal}
            color="primary"
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    );

    return (
      <TableRow
        key={element.id}
        tabIndex={-1}
        hover
      >
        {this.props.children}
        <TableCell className={classes.optionsTableCell} >
          {this.props.onUpOrder && upButton}
          {this.props.onDownOrder && downButton}
          {editButton}
          {this.props.withDeleteButton && deleteButton}
          {this.props.withDeleteButton && deleteModal}
        </TableCell>
      </TableRow>
    );
  }
}

export default compose(
  withMobileDialog(),
  withStyles(styles),
)(CustomTableRow);
