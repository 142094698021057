import React from 'react';
import { connect } from 'react-redux';
import { firebase } from '../services/firebase';
import { userLoginAction } from '../stores/users/actions';

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    state = {
      authUser: null,
    };

    componentDidMount() {
      const { onSetAuthUser } = this.props;
      firebase.auth.onAuthStateChanged((authUser) => {
        this.setState({ authUser });
        if (authUser) {
          onSetAuthUser(authUser);
        } else {
          onSetAuthUser(null);
        }
      });
    }

    render() {
      return (
        <Component authUser={this.state.authUser}/>
      );
    }
  }

  const mapDispatchToProps = dispatch => ({
    onSetAuthUser: authUser => dispatch(userLoginAction(authUser)),
  });

  return connect(null, mapDispatchToProps)(WithAuthentication);
};

export default withAuthentication;
