import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {compose} from "recompose";
import {Link, withRouter} from "react-router-dom";

import {withStyles} from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Divider from "@material-ui/core/Divider/Divider";
import List from "@material-ui/core/List/List";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Hidden from "@material-ui/core/Hidden/Hidden";
import Drawer from "@material-ui/core/Drawer/Drawer";
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import LogoutIcon from '@material-ui/icons/CancelPresentation';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';


import LocationCity from '@material-ui/icons/LocationCity';
import Commute from '@material-ui/icons/Commute';
import HowToVote from '@material-ui/icons/HowToVote';
import Weekend from '@material-ui/icons/Weekend';
import School from '@material-ui/icons/School';
import Healing from '@material-ui/icons/Healing';
import Group from '@material-ui/icons/Group';
import Assignment from '@material-ui/icons/Assignment';
import Warning from '@material-ui/icons/Warning';
import ArtTrack from '@material-ui/icons/ArtTrack';
import NotificationsActive from '@material-ui/icons/NotificationsActive';
import Chip from "@material-ui/core/Chip";

import {VERDUN_DARK_BLUE, VERDUN_BLUE_LIGHT} from "../constants/colors";
import {
  ADMINS,
  HOMEPAGE,
  SIGN_IN,
  LOCATIONS,
  TRANSPORTS,
  CENTERS,
  HOUSINGS,
  SURVEYS, TREAT_CENTERS, CITIZENSHIP_CENTERS, JOBS, SIGNALS, ARTISTS, FREE_PUSH
} from "../constants/routes";
import {ROLE_ADMIN} from "../constants/roles";
import AuthService from '../services/AuthService';
import Spinner from "../components/common/Spinner";
import LargePaper from "../components/common/LargePaper";
import { auth } from '../services/firebase';
import {getNbModerated} from "../stores/infos/actions";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  nbToModerate: {
    backgroundColor: VERDUN_DARK_BLUE,
    color: VERDUN_BLUE_LIGHT
  },
  toolbar: theme.mixins.toolbar,
  drawerHeader: {
    ...theme.mixins.toolbar,
    backgroundColor: VERDUN_DARK_BLUE,
    color: VERDUN_BLUE_LIGHT,
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    paddingLeft: '24px'
  },
  drawerPaper: {
    width: drawerWidth,
    margin: 'auto'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    maxWidth: '100%'
  },
});

const drawer = (classes, handleLogout, location, authUser, roles, datas) => (
  <div>
    <div className={classes.drawerHeader}>
      <Typography variant="h6" color="inherit" noWrap>
        Universo Back-office
      </Typography>
    </div>

    <Divider />

    {AuthService.isGranted(ROLE_ADMIN, authUser, roles) && (
      <div>
        <List>
          <Link to={HOMEPAGE} style={{ textDecoration: 'none' }}>
            <ListItem
              button
              key='home'
              selected={location.pathname === HOMEPAGE}
            >
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary='Accueil' />
            </ListItem>
          </Link>
        </List>

        <Divider />
      </div>
    )}

    <List>
      <Link to={LOCATIONS} style={{ textDecoration: 'none' }}>
        <ListItem
          button
          key='location'
          selected={location.pathname === LOCATIONS}
        >
          <ListItemIcon><LocationCity /></ListItemIcon>
          <ListItemText primary='Annuaire' />
          {AuthService.isGranted(ROLE_ADMIN, authUser, roles) && datas && datas['locations'] > 0 &&
            <Chip label={datas['locations'].toString()} className={classes.nbToModerate}/>
          }
        </ListItem>
      </Link>
        <Link to={TRANSPORTS} style={{ textDecoration: 'none' }}>
          <ListItem
            button
            key='transport'
            selected={location.pathname === TRANSPORTS}
          >
            <ListItemIcon><Commute /></ListItemIcon>
            <ListItemText primary='Se bouger' />
            {AuthService.isGranted(ROLE_ADMIN, authUser, roles) && datas && datas['transports'] > 0 &&
              <Chip label={datas['transports'].toString()} className={classes.nbToModerate}/>
            }
          </ListItem>
        </Link>
        <Link to={CENTERS} style={{ textDecoration: 'none' }}>
          <ListItem
            button
            key='training'
            selected={location.pathname === CENTERS}
          >
            <ListItemIcon><School /></ListItemIcon>
            <ListItemText primary='Se former' />
            {AuthService.isGranted(ROLE_ADMIN, authUser, roles) && datas && datas['trainings'] > 0 &&
              <Chip label={datas['trainings'].toString()} className={classes.nbToModerate}/>
            }
          </ListItem>
        </Link>
        <Link to={TREAT_CENTERS} style={{ textDecoration: 'none' }}>
          <ListItem
            button
            key='treat'
            selected={location.pathname === TREAT_CENTERS}
          >
            <ListItemIcon><Healing /></ListItemIcon>
            <ListItemText primary='Se soigner' />
            {AuthService.isGranted(ROLE_ADMIN, authUser, roles) && datas && datas['treats'] > 0 &&
              <Chip label={datas['treats'].toString()} className={classes.nbToModerate}/>
            }
          </ListItem>
        </Link>
        <Link to={CITIZENSHIP_CENTERS} style={{ textDecoration: 'none' }}>
          <ListItem
            button
            key='citizenship'
            selected={location.pathname === CITIZENSHIP_CENTERS}
          >
            <ListItemIcon><Group /></ListItemIcon>
            <ListItemText primary='Citoyenneté' />
            {AuthService.isGranted(ROLE_ADMIN, authUser, roles) && datas && datas['citizenships'] > 0 &&
              <Chip label={datas['citizenships'].toString()} className={classes.nbToModerate}/>
            }
          </ListItem>
        </Link>
        <Link to={HOUSINGS} style={{ textDecoration: 'none' }}>
          <ListItem
            button
            key='housing'
            selected={location.pathname === HOUSINGS}
          >
            <ListItemIcon><Weekend /></ListItemIcon>
            <ListItemText primary='Se loger' />
            {AuthService.isGranted(ROLE_ADMIN, authUser, roles) && datas && datas['housings'] > 0 &&
              <Chip label={datas['housings'].toString()} className={classes.nbToModerate}/>
            }
          </ListItem>
        </Link>
      <Link to={SURVEYS} style={{ textDecoration: 'none' }}>
        <ListItem
          button
          key='surveys'
          selected={location.pathname === SURVEYS}
        >
          <ListItemIcon><HowToVote /></ListItemIcon>
          <ListItemText primary='Sondages' />
          {AuthService.isGranted(ROLE_ADMIN, authUser, roles) && datas && datas['surveys'] > 0 &&
            <Chip label={datas['surveys'].toString()} className={classes.nbToModerate}/>
          }
        </ListItem>
      </Link>
      <Link to={JOBS} style={{ textDecoration: 'none' }}>
        <ListItem
          button
          key='jobs'
          selected={location.pathname === JOBS}
        >
          <ListItemIcon><Assignment /></ListItemIcon>
          <ListItemText primary='Emplois' />
          {AuthService.isGranted(ROLE_ADMIN, authUser, roles) && datas && datas['jobs'] > 0 &&
            <Chip label={datas['jobs'].toString()} className={classes.nbToModerate}/>
          }
        </ListItem>
      </Link>
      <Link to={ARTISTS} style={{ textDecoration: 'none' }}>
        <ListItem
          button
          key='artists'
          selected={location.pathname === ARTISTS}
        >
          <ListItemIcon><ArtTrack /></ListItemIcon>
          <ListItemText primary='Artistes' />
          {AuthService.isGranted(ROLE_ADMIN, authUser, roles) && datas && datas['artists'] > 0 &&
            <Chip label={datas['artists'].toString()} className={classes.nbToModerate}/>
          }
        </ListItem>
      </Link>
      {AuthService.isGranted(ROLE_ADMIN, authUser, roles) && (
      <Link to={SIGNALS} style={{ textDecoration: 'none' }}>
        <ListItem
          button
          key='suggestions'
          selected={location.pathname === SIGNALS}
        >
          <ListItemIcon><Warning /></ListItemIcon>
          <ListItemText primary='Signalements' />
          {AuthService.isGranted(ROLE_ADMIN, authUser, roles) && datas && datas['suggestions'] > 0 &&
            <Chip label={datas['suggestions'].toString()} className={classes.nbToModerate}/>
          }
        </ListItem>
      </Link>)}
      {AuthService.isGranted(ROLE_ADMIN, authUser, roles) && (
        <Link to={FREE_PUSH} style={{ textDecoration: 'none' }}>
          <ListItem
            button
            key='free_push'
            selected={location.pathname === FREE_PUSH}
          >
            <ListItemIcon><NotificationsActive /></ListItemIcon>
            <ListItemText primary='Notification libre' />
          </ListItem>
        </Link>)}
    </List>

    {AuthService.isGranted(ROLE_ADMIN, authUser, roles) && (
      <div>
        <Divider />
        <List>
          <Link to={ADMINS}>
            <ListItem
              button
              key='admins'
              selected={location.pathname.startsWith(ADMINS)}
            >
              <ListItemIcon><SupervisorAccountIcon /></ListItemIcon>
              <ListItemText primary='Utilisateurs' />
            </ListItem>
          </Link>
        </List>
      </div>
    )}
    <Divider />

    <List>
      <ListItem button key='logout' onClick={handleLogout}>
        <ListItemIcon><LogoutIcon /></ListItemIcon>
        <ListItemText primary='Se déconnecter' />
      </ListItem>
    </List>
  </div>
);

const INITIAL_STATE = {
  mobileOpen: false,
  datas: null
};

const withMenu = title => (Component) => {
  class withMenu extends React.Component {

    static propTypes = {
      classes: PropTypes.object.isRequired,
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }).isRequired,
    };

    constructor(props) {
      super(props);
      this.state = {...INITIAL_STATE};
    }

    static getDerivedStateFromProps(props, state) {
      let newState = null;
      if (props.authUser && !props.datas && !props.pending) {
        props.getNbModerated(props.authUser.ra);
      }
      if (props.datas && !props.pending) {
        if (state.datas === INITIAL_STATE.datas) {
          newState = {...newState, datas: props.datas};
        }
        if (props.locations !== state.datas && state.datas !== INITIAL_STATE.datas) {
          newState = {...newState, datas: props.datas}
        }
      }
      return newState;
    }

    handleDrawerToggle = () => {
      this.setState(state => ({mobileOpen: !state.mobileOpen}));
    };

    handleLogout = () => {
      auth.doSignOut();
      //TODO : check if logout needed
      //this.props.logout();
      this.props.history.push(SIGN_IN);
    };

    render() {
      return (
        <div className={this.props.classes.root}>
          <AppBar position="fixed" className={this.props.classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerToggle}
                className={this.props.classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" noWrap>
                {title}
              </Typography>
            </Toolbar>
          </AppBar>
          <nav className={this.props.classes.drawer}>
            <Hidden lgUp implementation="css">
              <Drawer
                variant="temporary"
                anchor='left'
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: this.props.classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer(this.props.classes, this.handleLogout, this.props.location)}
              </Drawer>
            </Hidden>
            <Hidden mdDown implementation="css">
              <Drawer
                classes={{
                  paper: this.props.classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer(this.props.classes, this.handleLogout, this.props.location, this.props.authUser, this.props.roles, this.state.datas)}
              </Drawer>
            </Hidden>
          </nav>
          <main className={this.props.classes.content}>
            <div className={this.props.classes.toolbar} />
            {this.props.pending && <Spinner />}
            {this.props.pending && (<LargePaper title="Chargement"><Spinner /></LargePaper>)}
            {!this.props.pending && <Component {...this.props} />}
          </main>
        </div>
      )
    }
  }

  function mapStateToProps(state) {
    return {
      pending: state.users.pending || state.infos.pending,
      authUser: state.users.authUser,
      roles: state.roles.content,
      datas: state.infos.datas
    };
  }

  return compose(
    withRouter,
    connect(mapStateToProps, {getNbModerated}),
    withStyles(styles)
  )(withMenu);
};

export default withMenu;
