import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CITIZENSHIP_CENTERS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.CITIZENSHIP_CENTER_SENDING:
      return {
        ...state,
        sending: true,
      };
    case types.CITIZENSHIP_RESET:
      return {
        ...INITIAL_STATE
      };
    case types.CITIZENSHIP_CENTERS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.CITIZENSHIP_CENTER_DOWNLOADED: {
      const centerDownloaded = action.payload;
      let centers = [];
      let centerIndex = -1;
      if (state.content) {
        centers = state.content.slice();
        centerIndex = centers.findIndex(center => center.id === centerDownloaded.id);
      }
      if (centerIndex === -1) {
        centers.push(centerDownloaded);
      } else {
        centers[centerIndex] = centerDownloaded;
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: centers,
      };
    }
    case types.CITIZENSHIP_DOWNLOADED: {
      const centerId = action.payload.centerId;
      const citizenshipDownloaded = action.payload.citizenship;
      let citizenshipCenter;
      let centers = [];
      let centerIndex = -1;
      let citizenships = [];
      let citizenshipIndex = -1;
      if (state.content) {
        centers = state.content.slice();
        centerIndex = centers.findIndex(center => center.id === centerId);
        citizenshipCenter = centers[centerIndex];
        if (!citizenshipCenter.citizenships) {
          citizenshipCenter.citizenships = [];
        }
        citizenships = citizenshipCenter.citizenships;
        citizenshipIndex = citizenships.findIndex(citizenship => citizenship.id === citizenshipDownloaded.id);
      }
      if (citizenshipIndex === -1) {
        citizenshipCenter.citizenships.push(citizenshipDownloaded);
      } else {
        citizenshipCenter.citizenships[citizenshipIndex] = citizenshipDownloaded;
      }

      centers[centerIndex] = citizenshipCenter;
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: centers,
      };
    }
    case types.CITIZENSHIP_CENTER_REMOVED: {
      const center = action.payload;
      let centers = state.content.slice();
      if (state.content !== null) {
        let isCenter = (centerId) => {
          return function (element) {
            return element.id !== centerId;
          }
        };
        centers = state.content.filter(isCenter(center.id));
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: centers,
      };
    }

    case types.CITIZENSHIP_REMOVED: {
      const citizenship = action.payload;
      let centers = state.content.slice();
      let centerIndex = centers.findIndex(center => center.citizenships.includes(citizenship));
      if (state.content !== null && centerIndex !== -1) {
        let isCitizenship = (citizenshipId) => {
          return function (element) {
            return element.id !== citizenshipId;
          }
        };
        let center = centers[centerIndex];
        center.citizenships = center.citizenships.filter(isCitizenship(citizenship.id));
        centers[centerIndex] = center;
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: centers,
      };
    }
    case types.CITIZENSHIP_CENTERS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    default:
      break;
  }
  return state;
}
