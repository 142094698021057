import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

// --------------------------------
// Action creators

export const artistsPendingAction = () => ({
  type: types.ARTISTS_PENDING,
});
export const artistSendingAction = () => ({
  type: types.ARTIST_SENDING,
});
export const resourcesResetAction = () => ({
  type: types.RESOURCES_RESET,
});

export const artistsDownloadedAction = centers => ({
  type: types.ARTISTS_DOWNLOADED,
  payload: centers,
});

export const artistDownloadedAction = (center) => ({
  type: types.ARTIST_DOWNLOADED,
  payload: center
});

export const resourceDownloadedAction = (artistId, resource) => ({
  type: types.RESOURCE_DOWNLOADED,
  payload: {
    artistId: artistId,
    resource: resource
  }
});

export const artistRemovedAction = (center) => ({
  type: types.ARTIST_REMOVED,
  payload: center,
});

export const resourceRemovedAction = (resource) => ({
  type: types.RESOURCE_REMOVED,
  payload: resource,
});

export const artistsFailedAction = error => ({
  type: types.ARTISTS_FAILED,
  payload: error,
});


// --------------------------------
// Actions

export function resetResources() {
  return (dispatch) => {
    dispatch(resourcesResetAction());
  };
}

// Récupèration de la liste des themes
export function getArtists(token) {
  return (dispatch) => {
    dispatch(artistsPendingAction());
    return ApiService
      .getArtists(token)
      .then((data) => {
        dispatch(artistsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(artistsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'un theme
export function postArtist(token, artist) {
  return (dispatch) => {
    dispatch(artistSendingAction());

    return ApiService
      .postArtist(token, artist)
      .then((data) => {
        dispatch(artistDownloadedAction(data));
        return data.id
      })
      .catch((error) => {
        dispatch(artistsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'une image pour un bloc
export function postArtistPicture(
  token,
  artistId,
  picture,
  onUploadProgress = null
) {
  return (dispatch) => {
    dispatch(artistSendingAction());
    return ApiService
      .postPicture(token, {entity_id: artistId, entity_name: "artist", position: 0}, picture, onUploadProgress)
      .then((result) => {
        dispatch(artistDownloadedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(artistsFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

// Ajout d'un bloc
export function postResource(token, artistId, resource) {
  return (dispatch) => {
    dispatch(artistSendingAction());

    return ApiService
      .postResource(token, artistId, resource)
      .then((data) => {
        dispatch(resourceDownloadedAction(artistId, data));
        return data.id
      })
      .catch((error) => {
        dispatch(artistsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'une image pour un bloc
export function postResourcePicture(
  token,
  centerId,
  resourceId,
  picture,
  onUploadProgress = null
) {
  return (dispatch) => {
    dispatch(artistSendingAction());
    return ApiService
      .postPicture(token, {entity_id: resourceId, entity_name: "resources", position: 0}, picture, onUploadProgress)
      .then((result) => {
        dispatch(resourceDownloadedAction(centerId, result));
        return result;
      })
      .catch((error) => {
        dispatch(artistsFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

// Edition d'un theme
export function putArtist(token, centerId, center) {
  return (dispatch) => {
    dispatch(artistSendingAction());

    return ApiService
      .putArtist(token, centerId, center)
      .then((data) => {
        const updatedArtist = {
          ...data,
          id: centerId,
        };
        dispatch(artistDownloadedAction(updatedArtist));
        return data.id
      })
      .catch((error) => {
        dispatch(artistsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Edition d'un theme
export function putResource(token, centerId, resourceId, resource) {
  return (dispatch) => {
    dispatch(artistSendingAction());

    return ApiService
      .putResource(token, centerId, resourceId, resource)
      .then((data) => {
        const updatedResource = {
          ...data,
          id: resourceId,
        };
        dispatch(resourceDownloadedAction(centerId, updatedResource));
        return data.id
      })
      .catch((error) => {
        dispatch(artistsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Suppression d'un theme
export function deleteArtist(token, artist) {
  return (dispatch) => {
    dispatch(artistSendingAction());
    return ApiService
      .deleteArtist(token, artist.id)
      .then(() => {
        dispatch(artistRemovedAction(artist));
      })
      .catch((error) => {
        dispatch(artistsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Suppression d'un themeBloc
export function deleteResource(token, centerId, resource) {
  return (dispatch) => {
    dispatch(artistSendingAction());
    return ApiService
      .deleteResource(token, centerId, resource.id)
      .then(() => {
        dispatch(resourceRemovedAction(resource));
      })
      .catch((error) => {
        dispatch(artistsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
