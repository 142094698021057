import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

// --------------------------------
// Action creators

export const treatCentersPendingAction = () => ({
  type: types.TREAT_CENTERS_PENDING,
});
export const treatCenterSendingAction = () => ({
  type: types.TREAT_CENTER_SENDING,
});
export const treatsResetAction = () => ({
  type: types.TREATS_RESET,
});

export const treatCentersDownloadedAction = centers => ({
  type: types.TREAT_CENTERS_DOWNLOADED,
  payload: centers,
});

export const treatCenterDownloadedAction = (center) => ({
  type: types.TREAT_CENTER_DOWNLOADED,
  payload: center
});

export const treatDownloadedAction = (centerId, treat) => ({
  type: types.TREAT_DOWNLOADED,
  payload: {
    centerId: centerId,
    treat: treat
  }
});

export const treatCenterRemovedAction = (center) => ({
  type: types.TREAT_CENTER_REMOVED,
  payload: center,
});

export const treatRemovedAction = (treat) => ({
  type: types.TREAT_REMOVED,
  payload: treat,
});

export const treatCentersFailedAction = error => ({
  type: types.TREAT_CENTERS_FAILED,
  payload: error,
});


// --------------------------------
// Actions

export function resetTreats() {
  return (dispatch) => {
    dispatch(treatsResetAction());
  }
}

// Récupèration de la liste des centres de soin
export function getTreatCenters(token) {
  return (dispatch) => {
    dispatch(treatCentersPendingAction());
    return ApiService
      .getTreatCenters(token)
      .then((data) => {
        dispatch(treatCentersDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(treatCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'un centre de soin
export function postTreatCenter(token, treatCenter) {
  return (dispatch) => {
    dispatch(treatCenterSendingAction());

    return ApiService
      .postCenter(token, treatCenter)
      .then((data) => {
        dispatch(treatCenterDownloadedAction(data));
        return data.id
      })
      .catch((error) => {
        dispatch(treatCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'un soin
export function postTreat(token, centerId, treat) {
  return (dispatch) => {
    dispatch(treatCenterSendingAction());

    return ApiService
      .postTreat(token, centerId, treat)
      .then((data) => {
        dispatch(treatDownloadedAction(centerId, data));
        return data.id
      })
      .catch((error) => {
        dispatch(treatCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'une image pour un soin
export function postTreatPicture(
  token,
  centerId,
  treatId,
  picture,
  onUploadProgress = null
) {
  return (dispatch) => {
    dispatch(treatCenterSendingAction());
    return ApiService
      .postPicture(token, {entity_id: treatId, entity_name: "treat", position: 0}, picture, onUploadProgress)
      .then((result) => {
        dispatch(treatDownloadedAction(centerId, result));
        return result;
      })
      .catch((error) => {
        dispatch(treatCentersFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

// Edition d'un centre de soin
export function putTreatCenter(token, centerId, center) {
  return (dispatch) => {
    dispatch(treatCenterSendingAction());

    return ApiService
      .putCenter(token, centerId, center)
      .then((data) => {
        const updatedTreatCenter = {
          ...data,
          id: centerId,
        };
        dispatch(treatCenterDownloadedAction(updatedTreatCenter));
        return data.id
      })
      .catch((error) => {
        dispatch(treatCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Edition d'un soin
export function putTreat(token, centerId, treatId, treat) {
  return (dispatch) => {
    dispatch(treatCenterSendingAction());

    return ApiService
      .putTreat(token, centerId, treatId, treat)
      .then((data) => {
        const updatedTreat = {
          ...data,
          id: treatId,
        };
        dispatch(treatDownloadedAction(centerId, updatedTreat));
        return data.id
      })
      .catch((error) => {
        dispatch(treatCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Suppression d'un centre de soin
export function deleteTreatCenter(token, treatCenter) {
  return (dispatch) => {
    dispatch(treatCenterSendingAction());
    return ApiService
      .deleteCenter(token, treatCenter.id)
      .then(() => {
        dispatch(treatCenterRemovedAction(treatCenter));
      })
      .catch((error) => {
        dispatch(treatCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Suppression d'un soin
export function deleteTreat(token, centerId, treat) {
  return (dispatch) => {
    dispatch(treatCenterSendingAction());
    return ApiService
      .deleteTreat(token, centerId, treat.id)
      .then(() => {
        dispatch(treatRemovedAction(treat));
      })
      .catch((error) => {
        dispatch(treatCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
