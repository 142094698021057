import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

// --------------------------------
// Action creators

export const citizenshipCentersPendingAction = () => ({
  type: types.CITIZENSHIP_CENTERS_PENDING,
});
export const citizenshipCenterSendingAction = () => ({
  type: types.CITIZENSHIP_CENTER_SENDING,
});
export const citizenshipResetAction = () => ({
  type: types.CITIZENSHIP_RESET,
});

export const citizenshipCentersDownloadedAction = centers => ({
  type: types.CITIZENSHIP_CENTERS_DOWNLOADED,
  payload: centers,
});

export const citizenshipCenterDownloadedAction = (center) => ({
  type: types.CITIZENSHIP_CENTER_DOWNLOADED,
  payload: center
});

export const citizenshipDownloadedAction = (centerId, citizenship) => ({
  type: types.CITIZENSHIP_DOWNLOADED,
  payload: {
    centerId: centerId,
    citizenship: citizenship
  }
});

export const citizenshipCenterRemovedAction = (center) => ({
  type: types.CITIZENSHIP_CENTER_REMOVED,
  payload: center,
});

export const citizenshipRemovedAction = (citizenship) => ({
  type: types.CITIZENSHIP_REMOVED,
  payload: citizenship,
});

export const citizenshipCentersFailedAction = error => ({
  type: types.CITIZENSHIP_CENTERS_FAILED,
  payload: error,
});


// --------------------------------
// Actions

export function resetCitizenships() {
  return (dispatch) => {
    dispatch(citizenshipResetAction());
  };
}

// Récupèration de la liste des centres de soin
export function getCitizenshipCenters(token) {
  return (dispatch) => {
    dispatch(citizenshipCentersPendingAction());
    return ApiService
      .getCitizenshipCenters(token)
      .then((data) => {
        dispatch(citizenshipCentersDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(citizenshipCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'un centre de soin
export function postCitizenshipCenter(token, citizenshipCenter) {
  return (dispatch) => {
    dispatch(citizenshipCenterSendingAction());

    return ApiService
      .postCenter(token, citizenshipCenter)
      .then((data) => {
        dispatch(citizenshipCenterDownloadedAction(data));
        return data.id
      })
      .catch((error) => {
        dispatch(citizenshipCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'un soin
export function postCitizenship(token, centerId, citizenship) {
  return (dispatch) => {
    dispatch(citizenshipCenterSendingAction());

    return ApiService
      .postCitizenship(token, centerId, citizenship)
      .then((data) => {
        dispatch(citizenshipDownloadedAction(centerId, data));
        return data.id
      })
      .catch((error) => {
        dispatch(citizenshipCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'une image pour un soin
export function postCitizenshipPicture(
  token,
  centerId,
  citizenshipId,
  picture,
  onUploadProgress = null
) {
  return (dispatch) => {
    dispatch(citizenshipCenterSendingAction());
    return ApiService
      .postPicture(token, {entity_id: citizenshipId, entity_name: "citizenship", position: 0}, picture, onUploadProgress)
      .then((result) => {
        dispatch(citizenshipDownloadedAction(centerId, result));
        return result;
      })
      .catch((error) => {
        dispatch(citizenshipCentersFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

// Edition d'un centre de soin
export function putCitizenshipCenter(token, centerId, center) {
  return (dispatch) => {
    dispatch(citizenshipCenterSendingAction());

    return ApiService
      .putCenter(token, centerId, center)
      .then((data) => {
        const updatedCitizenshipCenter = {
          ...data,
          id: centerId,
        };
        dispatch(citizenshipCenterDownloadedAction(updatedCitizenshipCenter));
        return data.id
      })
      .catch((error) => {
        dispatch(citizenshipCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Edition d'un soin
export function putCitizenship(token, centerId, citizenshipId, citizenship) {
  return (dispatch) => {
    dispatch(citizenshipCenterSendingAction());

    return ApiService
      .putCitizenship(token, centerId, citizenshipId, citizenship)
      .then((data) => {
        const updatedCitizenship = {
          ...data,
          id: citizenshipId,
        };
        dispatch(citizenshipDownloadedAction(centerId, updatedCitizenship));
        return data.id
      })
      .catch((error) => {
        dispatch(citizenshipCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Suppression d'un centre de soin
export function deleteCitizenshipCenter(token, citizenshipCenter) {
  return (dispatch) => {
    dispatch(citizenshipCenterSendingAction());
    return ApiService
      .deleteCenter(token, citizenshipCenter.id)
      .then(() => {
        dispatch(citizenshipCenterRemovedAction(citizenshipCenter));
      })
      .catch((error) => {
        dispatch(citizenshipCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Suppression d'un soin
export function deleteCitizenship(token, centerId, citizenship) {
  return (dispatch) => {
    dispatch(citizenshipCenterSendingAction());
    return ApiService
      .deleteCitizenship(token, centerId, citizenship.id)
      .then(() => {
        dispatch(citizenshipRemovedAction(citizenship));
      })
      .catch((error) => {
        dispatch(citizenshipCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
