import React, {Component} from 'react';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";

import withAuthorization from "../hocs/withAuthorization";
import LargePaper from "../components/common/LargePaper";
import DashboardCard from "../components/common/DashboardCard";

const iconStyle = {
  "marginTop": '0.9em',
  "width": '2em',
  "height": '2em'
};

const shortcuts = [
];

const cards = shortcuts.map(function (element) {
  return (
    <Grid item xs={12} md={12} lg={6} xl={4} key={element.label}>
      <DashboardCard
        icon={element.icon}
        label={element.label}
        link={element.link}
      />
    </Grid>
  )
});

export class HomePage extends Component {

  static propTypes = {
    // eslint-disable-next-line
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state={};
  }

  // eslint-disable-next-line
  render() {
    return (
      <LargePaper title="Back-office">
        <Grid container>
          {cards}
        </Grid>
      </LargePaper>
    );
  }
}

export default withAuthorization('Accueil')(HomePage);
