import React, {Component} from 'react';
import {compose} from "recompose";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {handleError} from "../../stores/infos/actions";
import LargePaper from "../../components/common/LargePaper";
import withAuthorization from "../../hocs/withAuthorization";
import Spinner from "../../components/common/Spinner";
import {LOCATION_EDIT, LOCATION_ADD} from "../../constants/routes";
import CustomTable from "../../components/common/table/CustomTable";
import {deleteLocation, getLocations, putLocation} from "../../stores/locations/actions";

const INITIAL_STATE = {
  datas: []
};

export class LocationsPage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    getLocations: PropTypes.func.isRequired,
    putLocation: PropTypes.func.isRequired,
    deleteLocation: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    authUser: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (props.authUser && !props.locations && !props.pending) {
      props.getLocations(props.authUser.ra);
    }
    if (props.locations) {
      if (state.datas === INITIAL_STATE.datas) {
        newState = {...newState, datas: props.locations};
      }
      if (props.locations !== state.datas && state.datas !== INITIAL_STATE.datas) {
        newState = {...newState, datas: props.locations}
      }
    }
    return newState;
  }

  handleAdd = () => {
    this.props.history.push(LOCATION_ADD);
  };

  handleEdit = element => {
    this.props.history.push(LOCATION_EDIT.replace(':locationId', element.id));
  };

  handleDelete = element => {
    this.props.deleteLocation(this.props.authUser.ra, element)
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  render() {

    const columnData = [
      {id: 'title', label: 'Titre', align: "left", disablePadding: false},
      {id: 'category', label: 'Catégorie', align: "left", disablePadding: false},
      {id: 'city', label: 'Ville', align: "left", disablePadding: false},
      {id: 'moderated', label: 'Modéré', boolean: true, align: "center", disablePadding: false}
    ];
    return (
      <LargePaper title="Gestion des POIs Annuaire">
        <div>
          {this.props.pending &&
          <Spinner />
          }
          {!this.props.pending && (
            <CustomTable
              datas={this.state.datas}
              onAdd={this.handleAdd}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
              buttonLabel='Ajouter un POI Annuaire'
              paginationLabel='POI Annuaire par page'
              emptyDataMessage='Aucun POI Annuaire'
              deleteDialogText='Êtes-vous sûr de vouloir supprimer ce POI Annuaire ?'
              columnData={columnData}
              sending={this.props.sending}
            />
          )}
        </div>
      </LargePaper>
    );
  }
}


function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    roles: state.roles.content,
    locations: state.locations.content,
    pending: state.locations.pending,
    sending: state.locations.sending,
    error: state.locations.error,
  };
}

export default compose(
  withAuthorization('Annuaire'),
  connect(mapStateToProps, {getLocations, deleteLocation, putLocation, handleError})
)(LocationsPage);
