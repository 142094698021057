import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import TreatCenterForm from "../../components/forms/TreatCenterForm";
import LargePaper from "../../components/common/LargePaper";

export const TreatCenterEditPage = props => {
  return (
    <LargePaper title="Éditer une thématique">
      <TreatCenterForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

TreatCenterEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withAuthorization('Se soigner')(TreatCenterEditPage);
