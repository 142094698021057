import {
  USERS_DOWNLOADED,
  USERS_FAILED,
  USERS_PENDING,
  USERS_RESET,
  USER_LOGIN,
  USER_SENDING,
  USER_DOWNLOADED,
  USER_REMOVED
} from './types';
import {db} from '../../services/firebase';
import { handleError } from '../infos/actions';
import ApiService from "../../services/ApiService";
// Action creators

export const usersResetAction = () => ({
  type: USERS_RESET,
});
export const usersPendingAction = () => ({
  type: USERS_PENDING,
});
export const userSendingAction = () => ({
  type: USER_SENDING,
});

export const usersDownloadedAction = users => ({
  type: USERS_DOWNLOADED,
  payload: users,
});

export const userDownloadedAction = (authUserId, user) => ({
  type: USER_DOWNLOADED,
  payload: user,
  userId: authUserId,
});

export const userLoginAction = user => ({
  type: USER_LOGIN,
  payload: user,
});

export const usersFailedAction = error => ({
  type: USERS_FAILED,
  payload: error,
});

export const userRemovedAction = (user) => ({
  type: USER_REMOVED,
  payload: user,
});

// Actions

export function resetUsers() {
  return (dispatch) => {
    dispatch(usersResetAction());
  };
}

export function getUsers() {
  return (dispatch) => {
    dispatch(usersPendingAction());
    return db
      .getUsers()
      .then((snapshot) => {
        let users = [];
        snapshot.forEach((user) => {
          let newUser = user.data();
          newUser['uid'] = user.id;
          users.push(newUser);
        });
        dispatch(usersDownloadedAction(users));
      })
      .catch((error) => {
        dispatch(usersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}



export function postUser(token, user) {
  return (dispatch) => {
    dispatch(userSendingAction());

    return ApiService
      .postUser(token, user)
      .then((uid) => {
        console.log(uid);
        dispatch(userDownloadedAction(uid, user));
        return uid
      })
      .catch((error) => {
        dispatch(usersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putUser(authUserId, user) {
  return (dispatch) => {
    dispatch(userSendingAction());
    if(db.doProfileUpdate(authUserId, user)) {
      dispatch(userDownloadedAction(authUserId, user));
    } else {
      dispatch(usersFailedAction('Erreur lors de la mise à jour de l\'utilisateur'));
      dispatch(handleError('Erreur lors de la mise à jour de l\'utilisateur'));
    }
  };
}

export function deleteUser(token, user) {
  return (dispatch) => {
    dispatch(userSendingAction());
    if (db.removeUser(user.uid)) {
      dispatch(userRemovedAction(user));
    } else {
      dispatch(usersFailedAction('Erreur lors de la suppression de l\'utilisateur'));
      dispatch(handleError('Erreur lors de la suppression de l\'utilisateur'));
    }
  };
}
