import React from 'react';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  VERDUN_GREY,
  VERDUN_DARK_BLUE,
  VERDUN_GREY_BLUE, VERDUN_DARK_BLUE_TRANSPARENT,
  VERDUN_GRADIENT_PURPLE,
  VERDUN_BORDER,
  VERDUN_BLUE_LIGHT
} from "../constants/colors";
import {DEFAULT_MARGIN} from "../constants/stylesDefault";
// A theme with custom primary and secondary color.
// It'departments optional.
export const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: VERDUN_GRADIENT_PURPLE,
      main: VERDUN_DARK_BLUE,
      dark: VERDUN_GREY_BLUE,
    },
    secondary: {
      light: VERDUN_BLUE_LIGHT,
      main: VERDUN_GREY,
      dark: VERDUN_BORDER,
    },
    action: {
      hover: VERDUN_GREY
    }
  },
  overrides: {
    MuiInput: {
      root: {
        backgroundColor: VERDUN_GREY,
        display: 'flex',
        width: '220px',
        fontWeight: 'bold',
        marginTop: DEFAULT_MARGIN,
        marginBottom: DEFAULT_MARGIN,
        padding: '4px 4px',
        flexDirection: 'row'
      },
      error: {
        color: VERDUN_GREY_BLUE
      }
    },
    MuiButton: {
      root: {
        display: 'flex',
        width: '220px',
        fontWeight: 'bold',
        marginTop: DEFAULT_MARGIN,
        marginBottom: DEFAULT_MARGIN,
        padding: '4px 16px',
        flexDirection: 'row'
      }
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: VERDUN_DARK_BLUE_TRANSPARENT + '!important'
        }
      }
    },
    MuiTableSortLabel: {
      active: {
        color: VERDUN_BLUE_LIGHT
      },
      root: {
        '&:hover': {
          color: VERDUN_BLUE_LIGHT
        },
        '&:focus': {
          color: VERDUN_BLUE_LIGHT
        }
      }
    }
  }
});

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
