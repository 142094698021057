import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SIGNALS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.SIGNAL_SENDING:
      return {
        ...state,
        sending: true,
      };
    case types.SIGNALS_RESET:
      return {
        ...INITIAL_STATE
      };
    case types.SIGNALS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.SIGNAL_DOWNLOADED: {
      const signalDownloaded = action.payload;
      let signals = [];
      let signalIndex = -1;
      if (state.content) {
        signals = state.content.slice();
        signalIndex = signals.findIndex(signal => signal.id === signalDownloaded.id);
      }
      if (signalIndex === -1) {
        signals.push(signalDownloaded);
      } else {
        signals[signalIndex] = signalDownloaded;
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: signals,
      };
    }
    case types.SIGNAL_REMOVED: {
      const signal = action.payload;
      let RemovedSignalOrder = signal.order;
      let signals = state.content.slice();
      if (state.content !== null) {
        let isSignal = (signalId) => {
          return function (element) {
            return element.id !== signalId;
          }
        };
        signals = state.content.filter(isSignal(signal.id));
      }
      signals.forEach(function (element) {
        if (element.order > RemovedSignalOrder) {
          element.order = element.order - 1;
        }
      });
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: signals,
      };
    }
    case types.SIGNALS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    default:
      break;
  }
  return state;
}
