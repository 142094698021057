import React from 'react';
import {Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Hidden from "@material-ui/core/Hidden/Hidden";
import Paper from "@material-ui/core/Paper/Paper";
import {SIGN_IN} from '../constants/routes';
import PasswordForgetForm from '../components/forms/PasswordForgetForm';
import SmallPaper from "../components/common/SmallPaper";
import logo from '../resources/images/logo_universo.svg';
import stylesDefault from '../constants/stylesDefault';
import background from "../resources/images/launch_background.svg";

const SignInLink = () =>
  (
    <Typography
      style={stylesDefault.center}
      variant="caption"
      gutterBottom
    >
      <Link to={SIGN_IN}>Se connecter</Link>
    </Typography>
  );

export const PasswordForgetPage = () =>
  (
    <div>
      <Hidden smDown>
        <Paper style={stylesDefault.background}>
          <img alt="background" src={background} style={stylesDefault.backgroundImage} />
          <img alt="logo" src={logo} style={stylesDefault.backgroundLogo} />
        </Paper>
      </Hidden>

      <Hidden smDown>
        <Paper style={stylesDefault.smallRightlayout}>
          <Typography
            style={stylesDefault.center}
            variant="h5"
            gutterBottom
          >
            Mot de passe oublié
          </Typography>
          <PasswordForgetForm />
          <SignInLink />
        </Paper>
      </Hidden>

      <Hidden mdUp>
        <div style={stylesDefault.center}>
          <SmallPaper>
            <div style={stylesDefault.smallLogoBlock}>
              <img alt="Verdun" src={logo} style={stylesDefault.smallLogo} />
            </div>
            <Typography
              style={stylesDefault.center}
              variant="h5"
              gutterBottom
            >
              Mot de passe oublié
            </Typography>
            <PasswordForgetForm />
            <SignInLink />
          </SmallPaper>
        </div>
      </Hidden>
    </div>
  );

export default PasswordForgetPage;
