import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import stylesDefault from '../../constants/stylesDefault';
import {dispatchInfo, handleError} from "../../stores/infos/actions";
import apiService from "../../services/ApiService";

const INITIAL_STATE = {
  newPassword1: '',
  newPassword2: '',
  errorRegex: false,
  errorSecondPassword: false
};

export class PasswordChangeForm extends Component {
  static propTypes = {
    handleError: PropTypes.func.isRequired,
    dispatchInfo: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    handleChangePassword: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  onSubmit = (event) => {
    const {newPassword1} = this.state;
    let data = {'password': newPassword1, 'token': this.props.token};
    apiService.postChangePassword(data)
      .then(() => {
        this.props.dispatchInfo("Mot de passe réinitialisé.");
        this.props.handleChangePassword();
      })
      .catch((error) => {
        this.props.handleError(error);
      });
    event.preventDefault();
  };


  handlePassword1Change = (currentPassword) => {
    let isWrongPassword = currentPassword.match(/(?=.*\d)(?=.*[A-Z])/) === null;
    this.setState({errorRegex: isWrongPassword});
    this.setState({newPassword1: currentPassword});
  };

  handlePassword2Change = (currentPassword) => {
    let isWrongPassword = this.state.newPassword1 !== currentPassword;
    this.setState({errorSecondPassword: isWrongPassword});
    this.setState({newPassword2: currentPassword});
  };

  render() {
    const {
      newPassword1,
      newPassword2,
      errorRegex,
      errorSecondPassword
    } = this.state;

    const isInvalid = (
      newPassword1 === '' ||
      newPassword1 !== newPassword2 ||
      newPassword1.match(/(?=.*\d)(?=.*[A-Z])/) === null
    );

    return (
      <form onSubmit={this.onSubmit} style={stylesDefault.center}>
        <TextField
          placeholder="Mot de passe"
          id="changepassword-password"
          type="password"
          onChange={event => this.handlePassword1Change(event.target.value)}
          margin="normal"
          value={newPassword1}
          InputProps={{
            disableUnderline: true,
          }}
          error={errorRegex}
        />
        <TextField
          placeholder="Répétez le mot de passe"
          id="changepassword-confirmpassword"
          type="password"
          onChange={event => this.handlePassword2Change(event.target.value)}
          margin="normal"
          value={newPassword2}
          InputProps={{
            disableUnderline: true,
          }}
          error={errorSecondPassword}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={isInvalid}
          type="submit"
        >
          Envoyer
        </Button>
      </form>
    );
  }
}

export default connect(null, {handleError, dispatchInfo})(PasswordChangeForm);
