import React, {Component} from 'react';
import PropTypes from "prop-types";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import {connect} from 'react-redux';
import * as routes from '../../constants/routes';
import defaultStyles from '../../constants/stylesDefault';
import { auth, db, firebase } from '../../services/firebase';
import {ROLE_CONTRIBUTOR} from '../../constants/roles';
import {handleError} from "../../stores/infos/actions";
import Spinner from "../common/Spinner";
import {getRoles} from "../../stores/roles/actions";

const INITIAL_STATE = {
  email: '',
  password: '',
  showPassword: false,
};

export class SignInForm extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    pending: PropTypes.bool.isRequired,
    handleError: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  firebaseSignOut = (role) => {
    console.log(role);
    const {
      history,
    } = this.props;

    if (role < ROLE_CONTRIBUTOR) {
      firebase.auth.signOut()
        .then(() => {
          this.props.handleError({ error: { message: 'Access forbidden' } });
        })
        .catch((error) => {
          this.props.handleError(error);
        });
    } else {
      this.setState(() => ({ ...INITIAL_STATE }));
      history.push(routes.HOMEPAGE);
    }
  };

  onSubmit = (event) => {
    const {
      email,
      password,
    } = this.state;
    auth.doSignInWithEmailAndPassword(email, password)
      .then((authUser) => {
        db.getRole(authUser.user.uid)
          .then((doc) => {
            this.firebaseSignOut(doc.data().role);
          })
          .catch((err) => {
            console.log('Error getting documents', err);
          })
      })
      .catch((error) => {
        this.props.handleError(error);
      });

    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState({showPassword: !this.state.showPassword});
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const {pending} = this.props;
    const {
      email,
      password
    } = this.state;

    const isInvalid =
      password === '' ||
      email === '' ||
      pending;

    const buttonLabel = pending ?
      (<Spinner variant="nomargin" />)
      : 'Connexion';

    return (
      <form onSubmit={this.onSubmit} style={defaultStyles.center}>

        <TextField
          placeholder="Email"
          id="signin-email"
          type="text"
          onChange={event => this.setState({email: event.target.value})}
          margin="normal"
          value={email}
          InputProps={{
            disableUnderline: true,
          }}
        />

        <Input
          placeholder="Mot de passe"
          id="signin-password"
          type={this.state.showPassword ? 'text' : 'password'}
          value={password}
          onChange={event => this.setState({password: event.target.value})}
          disableUnderline
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={this.handleClickShowPassword}
                onMouseDown={this.handleMouseDownPassword}
              >
                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={isInvalid}
          type="submit"
        >
          {buttonLabel}
        </Button>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.users.authUser,
  roles: state.roles.content,
  pending: state.roles.pending,
});

export default connect(mapStateToProps, {getRoles, handleError})(SignInForm);
