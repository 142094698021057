import axios from 'axios';
import * as api from "../constants/apiRoutes";

const ApiService = {

  /**
   * Génére le header pour les flux admin
   *
   * @param token
   * @returns Object
   */
  headers(token = null) {
    const header = {};
    if (token) {
      header.Authorization = `Bearer ${token}`;
    }
    return header;
  },

  /**
   * Login
   */

  login(data) {
    return axios
      .post(api.API_LOGIN, data)
      .then(response => response.data);
  },

  refresh(data) {
    return axios
      .post(api.API_REFRESH, data)
      .then(response => response.data);
  },

  /**
   * Static Datas
   */

  getStaticDatas() {
    return axios
      .get(api.API_STATIC_DATAS, null)
      .then(response => response.data);
  },

  /**
   * Nb Moderated
   */

  getNbModerated(token) {
    return axios
      .get(api.API_NB_MODERATED, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Push
   */

  postFreePush(token, data) {
    return axios
      .post(api.API_FREE_PUSH, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Users
   */

  postUser(token, data) {
    return axios
      .post(api.API_USERS, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Admins
   */

  getAdmins(token) {
    return axios
      .get(api.API_ADMINS, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postAdmin(token, data) {
    return axios
      .post(api.API_ADMINS, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putMe(token, data) {
    return axios
      .put(api.API_ADMINS_ME, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  deleteMe(token) {
    return axios
      .delete(api.API_ADMINS_ME, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putAdmin(token, id, data) {
    return axios
      .put(api.API_ADMINS_ID.replace(':adminId', id), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  deleteAdmin(token, id) {
    return axios
      .delete(api.API_ADMINS_ID.replace(':adminId', id), {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },


  /**
   * Password
   */

  postLostPassword(data) {
    return axios
      .post(api.API_PASSWORD_LOST, data)
      .then(response => response.data);
  },

  postChangePassword(data) {
    return axios
      .post(api.API_PASSWORD_CHANGE, data)
      .then(response => response.data);
  },

  /**
   * Medias
   */

  postPicture(token, content, picture, onUploadProgress = null) {
    // eslint-disable-next-line no-undef
    let data = new FormData();
    data.append('image', picture);
    data.append('content', JSON.stringify(content));

    return axios
      .post(api.API_MEDIA, data, {
        headers: this.headers(token),
        onUploadProgress: onUploadProgress
      })
      .then(response => {
        return response.data;
      });
  },

  /**
   * Surveys
   */

  getSurveys(token) {
    return axios
      .get(api.API_SURVEYS, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postSurvey(token, data) {
    return axios
      .post(api.API_SURVEYS, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putSurvey(token, id, data) {
    return axios
      .put(api.API_SURVEYS_ID.replace(':surveyId', id), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  deleteSurvey(token, id) {
    return axios
      .delete(api.API_SURVEYS_ID.replace(':surveyId', id), {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Transports
   */

  getTransports(token) {
    return axios
      .get(api.API_TRANSPORTS, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postTransport(token, data) {
    return axios
      .post(api.API_TRANSPORTS, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putTransport(token, id, data) {
    return axios
      .put(api.API_TRANSPORTS_ID.replace(':transportId', id), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  deleteTransport(token, id) {
    return axios
      .delete(api.API_TRANSPORTS_ID.replace(':transportId', id), {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Locations
   */

  getLocations(token) {
    return axios
      .get(api.API_LOCATIONS, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postLocation(token, data) {
    return axios
      .post(api.API_LOCATIONS, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putLocation(token, id, data) {
    return axios
      .put(api.API_LOCATIONS_ID.replace(':locationId', id), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  deleteLocation(token, id) {
    return axios
      .delete(api.API_LOCATIONS_ID.replace(':locationId', id), {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Housings
   */

  getHousings(token) {
    return axios
      .get(api.API_HOUSINGS, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postHousing(token, data) {
    return axios
      .post(api.API_HOUSINGS, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putHousing(token, id, data) {
    return axios
      .put(api.API_HOUSINGS_ID.replace(':housingId', id), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  deleteHousing(token, id) {
    return axios
      .delete(api.API_HOUSINGS_ID.replace(':housingId', id), {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Center and Training
   */

  getTrainingCenters(token) {
    return axios
      .get(api.API_ADMIN_TRAININGS, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postCenter(token, center) {
    return axios
      .post(api.API_CENTERS, center, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putCenter(token, centerId, center) {
    return axios
      .put(api.API_CENTERS_ID.replace(':centerId', centerId), center, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  deleteCenter(token, centerId) {
    return axios
      .delete(api.API_CENTERS_ID.replace(':centerId', centerId), {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postTraining(token, centerId, training) {
    return axios
      .post(api.API_TRAININGS.replace(':centerId', centerId), training, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putTraining(token, centerId, trainingId, training) {
    return axios
      .put(api.API_TRAININGS_ID.replace(':centerId', centerId).replace(':trainingId', trainingId)
        , training, {
        headers: this.headers(token),
      })
      .then(response => response.data);

  },

  deleteTraining(token, centerId, trainingId) {
    return axios
      .delete(api.API_TRAININGS_ID.replace(':centerId', centerId).replace(':trainingId', trainingId)
        , {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Treat
   */

  getTreatCenters(token) {
    return axios
      .get(api.API_ADMIN_TREATS, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postTreat(token, centerId, training) {
    return axios
      .post(api.API_TREATS.replace(':centerId', centerId), training, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putTreat(token, centerId, treatId, training) {
    return axios
      .put(api.API_TREATS_ID.replace(':centerId', centerId).replace(':treatId', treatId)
        , training, {
          headers: this.headers(token),
        })
      .then(response => response.data);

  },

  deleteTreat(token, centerId, treatId) {
    return axios
      .delete(api.API_TREATS_ID.replace(':centerId', centerId).replace(':treatId', treatId)
        , {
          headers: this.headers(token),
        })
      .then(response => response.data);
  },

  /**
   * Citizenship
   */

  getCitizenshipCenters(token) {
    return axios
      .get(api.API_ADMIN_CITIZENSHIPS, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postCitizenship(token, centerId, training) {
    return axios
      .post(api.API_CITIZENSHIPS.replace(':centerId', centerId), training, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putCitizenship(token, centerId, treatId, training) {
    return axios
      .put(api.API_CITIZENSHIPS_ID.replace(':centerId', centerId).replace(':citizenshipId', treatId)
        , training, {
          headers: this.headers(token),
        })
      .then(response => response.data);

  },

  deleteCitizenship(token, centerId, treatId) {
    return axios
      .delete(api.API_CITIZENSHIPS_ID.replace(':centerId', centerId).replace(':citizenshipId', treatId)
        , {
          headers: this.headers(token),
        })
      .then(response => response.data);
  },


  /**
   * Jobs
   */

  getJobs(token) {
    return axios
      .get(api.API_JOBS, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postJob(token, data) {
    return axios
      .post(api.API_JOBS, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putJob(token, id, data) {
    return axios
      .put(api.API_JOBS_ID.replace(':jobId', id), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  deleteJob(token, id) {
    return axios
      .delete(api.API_JOBS_ID.replace(':jobId', id), {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Artist and Resources
   */

  getArtists(token) {
    return axios
      .get(api.API_ARTISTS, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postArtist(token, artist) {
    return axios
      .post(api.API_ARTISTS, artist, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putArtist(token, artistId, artist) {
    return axios
      .put(api.API_ARTISTS_ID.replace(':artistId', artistId), artist, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  deleteArtist(token, artistId) {
    return axios
      .delete(api.API_ARTISTS_ID.replace(':artistId', artistId), {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postResource(token, artistId, resources) {
    return axios
      .post(api.API_RESOURCES.replace(':artistId', artistId), resources, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putResource(token, artistId, resourcesId, resources) {
    return axios
      .put(api.API_RESOURCES_ID.replace(':artistId', artistId).replace(':resourcesId', resourcesId)
        , resources, {
          headers: this.headers(token),
        })
      .then(response => response.data);

  },

  deleteResource(token, artistId, resourcesId) {
    return axios
      .delete(api.API_RESOURCES_ID.replace(':artistId', artistId).replace(':resourcesId', resourcesId)
        , {
          headers: this.headers(token),
        })
      .then(response => response.data);
  },


  /**
   * Signals
   */

  getSignals(token) {
    return axios
      .get(api.API_SIGNALS, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putSignal(token, id, data) {
    return axios
      .put(api.API_SIGNALS_ID.replace(':signalId', id), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  deleteSignal(token, id) {
    return axios
      .delete(api.API_SIGNALS_ID.replace(':signalId', id), {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

};


export default ApiService;
