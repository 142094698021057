import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import LocationForm from "../../components/forms/LocationForm";
import LargePaper from "../../components/common/LargePaper";

export const LocationAddPage = props => {
  return (
    <LargePaper title="Ajouter un POI Annuaire">
      <LocationForm history={props.history} />
    </LargePaper>
  );
};

LocationAddPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default withAuthorization('Annuaire')(LocationAddPage);
