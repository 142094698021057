import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

// --------------------------------
// Action creators

export const transportsPendingAction = () => ({
  type: types.TRANSPORTS_PENDING,
});
export const transportSendingAction = () => ({
  type: types.TRANSPORT_SENDING,
});
export const transportsResetAction = () => ({
  type: types.TRANSPORTS_RESET,
});

export const transportsDownloadedAction = transports => ({
  type: types.TRANSPORTS_DOWNLOADED,
  payload: transports,
});

export const transportDownloadedAction = (transport) => ({
  type: types.TRANSPORT_DOWNLOADED,
  payload: transport
});

export const transportRemovedAction = (transport) => ({
  type: types.TRANSPORT_REMOVED,
  payload: transport,
});

export const transportsFailedAction = error => ({
  type: types.TRANSPORTS_FAILED,
  payload: error,
});


// --------------------------------
// Actions

export function resetTransports() {
  return (dispatch) => {
    dispatch(transportsResetAction());
  };
}

export function getTransports(token) {
  return (dispatch) => {
    dispatch(transportsPendingAction());
    return ApiService
      .getTransports(token)
      .then((data) => {
        dispatch(transportsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(transportsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postTransport(token, transport) {
  return (dispatch) => {
    dispatch(transportSendingAction());

    return ApiService
      .postTransport(token, transport)
      .then((data) => {
        dispatch(transportDownloadedAction(data));
        return data.id
      })
      .catch((error) => {
        dispatch(transportsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postTransportPicture(token, transportId, picture, onUploadProgress = null) {
  return (dispatch) => {
    dispatch(transportSendingAction());
    return ApiService
      .postPicture(token, {entity_id: transportId, entity_name: "transport", position: 0}, picture, onUploadProgress)
      .then((result) => {
        dispatch(transportDownloadedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(transportsFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

export function putTransport(token, transportId, transport) {
  return (dispatch) => {
    dispatch(transportSendingAction());
    return ApiService
      .putTransport(token, transportId, transport)
      .then((data) => {
        const updatedTransport = {
          ...data,
          id: transportId,
        };
        dispatch(transportDownloadedAction(updatedTransport));
        return data.id
      })
      .catch((error) => {
        dispatch(transportsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function deleteTransport(token, transport) {
  return (dispatch) => {
    dispatch(transportSendingAction());
    return ApiService
      .deleteTransport(token, transport.id)
      .then(() => {
        dispatch(transportRemovedAction(transport));
      })
      .catch((error) => {
        dispatch(transportsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
