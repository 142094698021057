import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "recompose";

import {postSurvey, putSurvey, getSurveys, postSurveyPicture} from "../../stores/surveys/actions";
import {getNbModerated, handleError} from "../../stores/infos/actions";
import {SURVEYS} from "../../constants/routes";
import Spinner from "../common/Spinner";

import CustomForm from "../common/form/CustomForm";
import StringService from "../../services/StringService";
import {ROLE_MODERATOR} from "../../constants/roles";
import AuthService from "../../services/AuthService";

// Intial State of the Component
const INITIAL_STATE = {
  id:'',
  question:'',
  answer1:'',
  answer2:'',
  end_date: StringService.dateTimestampToString(new Date() / 1000),
  imagePath1: '',
  imagePath2: '',
  pictureToUpload1: null,
  pictureToUpload2: null,
  sending: false
};

const stateWithSurvey = (survey, media1, media2) => ({
  id: survey.id,
  question: survey.question,
  answer1: survey.answer1,
  answer2: survey.answer2,
  end_date: StringService.dateTimestampToString(survey.end_date || new Date() / 1000),
  imagePath1: media1 ? media1.image_path : '',
  imagePath2: media2 ? media2.image_path : ''
});

export class SurveyForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    pending: PropTypes.bool.isRequired,
    sending: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    surveys: PropTypes.array,
    postSurvey: PropTypes.func.isRequired,
    putSurvey: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired
  };

  static defaultProps = {
    surveys: null
  };

  static getDerivedStateFromProps(props, state) {
    let newState = {...state};

    if (!props.roles && !props.pending) {
      props.getRoles();
    }
    if (!props.surveys && !props.pending && props.token && props.match && props.match.params.surveyId) {
      props.getSurveys(props.token);
    }
    if (props.surveys && props.match && props.match.params.surveyId && state.id === INITIAL_STATE.id) {
      const surveys = props.surveys;
      const currentSurveyId = props.match.params.surveyId;
      const foundSurvey = surveys.find(survey => survey.id === currentSurveyId);

      if (foundSurvey) {
        const media1 = foundSurvey.medias ?
          foundSurvey.medias.find(media => media.position === 0) : null;
        const media2 = foundSurvey.medias ?
          foundSurvey.medias.find(media => media.position === 1) : null;
        newState = {...newState, ...stateWithSurvey(foundSurvey, media1, media2)};
      }
    }

    if (props.sending && !state.sending) { // Start sending
      return {sending: true};
    }
    if (!props.sending && state.sending) { // End sending
      if ((state.pictureToUpload1 || state.pictureToUpload2) && state.id) { // picture To Upload after deal sent
        if (state.pictureToUpload1) {
          props.postSurveyPicture(props.token, state.id, state.pictureToUpload1, 0, (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(state.pictureToUpload1.name + ' -> '
              + percentCompleted + '% (' + progressEvent.loaded + ' / ' + progressEvent.total + ')');
          });
        }
        if (state.pictureToUpload2) {
          props.postSurveyPicture(props.token, state.id, state.pictureToUpload2, 1, (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(state.pictureToUpload2.name + ' -> '
              + percentCompleted + '% (' + progressEvent.loaded + ' / ' + progressEvent.total + ')');
          });
        }
        return {
          pictureToUpload1: null,
          pictureToUpload2: null,
          sending: false
        };
      } else if (!state.pictureToUpload1 && !state.pictureToUpload2) {
        props.history.push(SURVEYS);
      }
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  onSubmit = (event, formState) => {

    // Retrieve ImageToUpload if available
    if (formState.pictureToUpload1) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        pictureToUpload1: formState.pictureToUpload1
      });
    }
    // Retrieve ImageToUpload if available
    if (formState.pictureToUpload2) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        pictureToUpload2: formState.pictureToUpload2
      });
    }
    // HandleDate
    formState = {
      ...formState,
      end_date: StringService.dateStringToTimestamp(formState.end_date)
    };

    if (this.state.id === INITIAL_STATE.id) {
      // Set order when posting
      formState = {...formState, order: this.props.surveys.length};
      this.props.postSurvey(
        this.props.token,
        formState
      )
        .then((surveyId => {
          if (this.state.pictureToUpload1 || this.state.pictureToUpload2) {
            this.setState({id: surveyId});
          }
        }))
        .catch((error) => {
          this.props.handleError(error);
        });
    } else {
      this.props.putSurvey(
        this.props.token,
        this.state.id,
        formState
      )
        .then((surveyId => {
          this.props.getNbModerated(this.props.token);
          if (this.state.pictureToUpload1 || this.state.pictureToUpload2) {
            this.setState({id: surveyId});
          }
        }))
        .catch((error) => {
          this.props.handleError(error);
        });
    }
    event.preventDefault();
  };

  render() {
    console.log(this.state);

    const {pending, sending} = this.props;

    const {id} = this.state;

    const buttonLabel = sending ?
      (<Spinner variant="nomargin" />)
      : id === ''  ? 'Créer' : 'Éditer';

    const load = (<Spinner />);

    let formFields = [
      {id: 'question', type:'textField', label:'Question', isMandatory:true},
      {id: 'answer1', type:'textField', gridMD:6, label: 'Réponse 1', isMandatory:true},
      {
        id: 'imagePath1', picture: 'pictureToUpload1',
        type: 'pictureDropzone', gridMD: 6, isMandatory: false
      },
      {id: 'answer2', type:'textField', gridMD:6, label: 'Réponse 2', isMandatory:true},
      {
        id: 'imagePath2', picture: 'pictureToUpload2',
        type: 'pictureDropzone', gridMD: 6, isMandatory: false
      },
      {id: 'end_date', type:'textField', subtype: 'date', label: 'Date de fin', isMandatory:true},
    ];

    if (AuthService.isGranted(ROLE_MODERATOR, this.props.authUser, this.props.roles)) {
      formFields.push({id: 'push', type:'checkboxField', label: 'Notifier les utilisateurs', isMandatory:true});
    }

    const customForm = (
      <CustomForm
        data={this.state}
        formFields={formFields}
        buttonLabel={buttonLabel}
        onSubmit={this.onSubmit}
        sending={sending}
      />
    );

    if (pending) {
      return (load);
    } else {
      return (customForm);
    }
  }
}

function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    roles: state.roles.content,
    token: state.users.authUser.ra,
    surveys: state.surveys.content,
    pending: state.surveys.pending || state.users.pending,
    sending: state.surveys.sending
  };
}

export default compose(
  connect(mapStateToProps, {getSurveys, postSurvey, postSurveyPicture, putSurvey, handleError, getNbModerated}),
)(SurveyForm);
