import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.JOBS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.JOB_SENDING:
      return {
        ...state,
        sending: true,
      };
    case types.JOBS_RESET:
      return {
        ...INITIAL_STATE
      };
    case types.JOBS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.JOB_DOWNLOADED: {
      const jobDownloaded = action.payload;
      let jobs = [];
      let jobIndex = -1;
      if (state.content) {
        jobs = state.content.slice();
        jobIndex = jobs.findIndex(job => job.id === jobDownloaded.id);
      }
      if (jobIndex === -1) {
        jobs.push(jobDownloaded);
      } else {
        jobs[jobIndex] = jobDownloaded;
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: jobs,
      };
    }
    case types.JOB_REMOVED: {
      const job = action.payload;
      let RemovedJobOrder = job.order;
      let jobs = state.content.slice();
      if (state.content !== null) {
        let isJob = (jobId) => {
          return function (element) {
            return element.id !== jobId;
          }
        };
        jobs = state.content.filter(isJob(job.id));
      }
      jobs.forEach(function (element) {
        if (element.order > RemovedJobOrder) {
          element.order = element.order - 1;
        }
      });
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: jobs,
      };
    }
    case types.JOBS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    default:
      break;
  }
  return state;
}
