import React from 'react';
import PropTypes from "prop-types";
import {Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Hidden from "@material-ui/core/Hidden/Hidden";
import Paper from "@material-ui/core/Paper/Paper";
import {SIGN_IN} from '../constants/routes';
import PasswordChangeForm from '../components/forms/PasswordChangeForm';
import SmallPaper from "../components/common/SmallPaper";
import logo from '../resources/images/logo_universo.svg';
import stylesDefault from '../constants/stylesDefault';
import background from "../resources/images/launch_background.svg";

const SignInLink = () =>
  (
    <Typography
      style={stylesDefault.center}
      variant="caption"
      gutterBottom
    >
      <Link to={SIGN_IN}>Se connecter</Link>
    </Typography>
  );

export const PasswordChangePage = (props) => {

  const handleChangePassword = () => {
    props.history.push(SIGN_IN);
  };

  return (
    <div>
      <Hidden smDown>
        <Paper style={stylesDefault.background}>
          <img alt="background" src={background} style={stylesDefault.backgroundImage} />
          <img alt="logo" src={logo} style={stylesDefault.backgroundLogo} />
        </Paper>
      </Hidden>

      <Hidden smDown>
        <Paper style={stylesDefault.smallRightlayout}>
          <Typography
            style={stylesDefault.center}
            variant="h5"
            gutterBottom
          >
            Réinitialisation du mot de passe
          </Typography>
          <Typography
            style={stylesDefault.center}
          >
            Le mot de passe doit faire au minimum 8 caractères
          </Typography>
          <Typography
            style={stylesDefault.center}
          >
            et avoir au moins une majuscule et un chiffre
          </Typography>
          <PasswordChangeForm
            token={props.match.params.token}
            handleChangePassword={handleChangePassword}
          />
          <SignInLink />
        </Paper>
      </Hidden>

      <Hidden mdUp>
        <div style={stylesDefault.center}>
          <SmallPaper>
            <div style={stylesDefault.smallLogoBlock}>
              <img alt="Verdun" src={logo} style={stylesDefault.smallLogo} />
            </div>
            <Typography
              style={stylesDefault.center}
              variant="h5"
              gutterBottom
            >
              Réinitialisation du mot de passe
            </Typography>
            <Typography
              style={stylesDefault.center}
            >
              Le mot de passe doit faire au minimum 8 caractères
            </Typography>
            <Typography
              style={stylesDefault.center}
            >
              et avoir au moins une majuscule et un chiffre
            </Typography>
            <PasswordChangeForm
              token={props.match.params.token}
              handleChangePassword={handleChangePassword}
            />
            <SignInLink />
          </SmallPaper>
        </div>
      </Hidden>
    </div>
  )
};

PasswordChangePage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default PasswordChangePage;
