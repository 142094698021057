import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SURVEYS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.SURVEY_SENDING:
      return {
        ...state,
        sending: true,
      };
    case types.SURVEYS_RESET:
      return {
        ...INITIAL_STATE
      };
    case types.SURVEYS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.SURVEY_DOWNLOADED: {
      const surveyDownloaded = action.payload;
      let surveys = [];
      let surveyIndex = -1;
      if (state.content) {
        surveys = state.content.slice();
        surveyIndex = surveys.findIndex(survey => survey.id === surveyDownloaded.id);
      }
      if (surveyIndex === -1) {
        surveys.push(surveyDownloaded);
      } else {
        surveys[surveyIndex] = surveyDownloaded;
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: surveys,
      };
    }
    case types.SURVEY_REMOVED: {
      const survey = action.payload;
      let RemovedSurveyOrder = survey.order;
      let surveys = state.content.slice();
      if (state.content !== null) {
        let isSurvey = (surveyId) => {
          return function (element) {
            return element.id !== surveyId;
          }
        };
        surveys = state.content.filter(isSurvey(survey.id));
      }
      surveys.forEach(function (element) {
        if (element.order > RemovedSurveyOrder) {
          element.order = element.order - 1;
        }
      });
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: surveys,
      };
    }
    case types.SURVEYS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    default:
      break;
  }
  return state;
}
