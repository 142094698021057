import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import TreatForm from "../../components/forms/TreatForm";
import LargePaper from "../../components/common/LargePaper";

export const TreatAddPage = props => {
  return (
    <LargePaper title="Ajouter un article">
      <TreatForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

TreatAddPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withAuthorization('Se soigner')(TreatAddPage);
