import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";
import {
  transportDownloadedAction,
  transportSendingAction,
  transportsFailedAction
} from "../transports/actions";

// --------------------------------
// Action creators

export const signalsPendingAction = () => ({
  type: types.SIGNALS_PENDING,
});
export const signalSendingAction = () => ({
  type: types.SIGNAL_SENDING,
});
export const signalsResetAction = () => ({
  type: types.SIGNALS_RESET,
});

export const signalsDownloadedAction = signals => ({
  type: types.SIGNALS_DOWNLOADED,
  payload: signals,
});

export const signalDownloadedAction = (signal) => ({
  type: types.SIGNAL_DOWNLOADED,
  payload: signal
});

export const signalRemovedAction = (signal) => ({
  type: types.SIGNAL_REMOVED,
  payload: signal,
});

export const signalsFailedAction = error => ({
  type: types.SIGNALS_FAILED,
  payload: error,
});


// --------------------------------
// Actions

export function resetSignals() {
  return (dispatch) => {
    dispatch(signalsResetAction());
  }
}

export function getSignals(token) {
  return (dispatch) => {
    dispatch(signalsPendingAction());
    return ApiService
      .getSignals(token)
      .then((data) => {
        dispatch(signalsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(signalsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postSignalPicture(token, signalId, picture, onUploadProgress = null) {
  return (dispatch) => {
    dispatch(signalSendingAction());
    return ApiService
      .postPicture(token, {entity_id: signalId, entity_name: "suggestion", position: 0}, picture, onUploadProgress)
      .then((result) => {
        dispatch(signalDownloadedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(signalsFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

export function putSignal(token, signalId, signal) {
  return (dispatch) => {
    dispatch(signalSendingAction());
    return ApiService
      .putSignal(token, signalId, signal)
      .then((data) => {
        const updatedSignal = {
          ...data,
          id: signalId,
        };
        dispatch(signalDownloadedAction(updatedSignal));
        return data.id
      })
      .catch((error) => {
        dispatch(signalsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function deleteSignal(token, signal) {
  return (dispatch) => {
    dispatch(signalSendingAction());
    return ApiService
      .deleteSignal(token, signal.id)
      .then(() => {
        dispatch(signalRemovedAction(signal));
      })
      .catch((error) => {
        dispatch(signalsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
