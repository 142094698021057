import React, {Component} from 'react';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from "@material-ui/core/TextField/TextField";

/**
 * Style of Custom Password Field elements
 *
 * @returns json
 */
const styles = () => ({
  textField: {
    width: '100%'
  }
});

/**
 * Class CustomPasswordField
 *
 * @Component : <CustomPasswordField />
 * @Extend : React.Component
 */
export class CustomPasswordField extends Component {

  // ----------------
  // Type of each Props

  static propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    isMandatory: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    gridMD: PropTypes.number.isRequired,
    noLabel: PropTypes.bool,
    error: PropTypes.bool,
  };

  // ----------------
  // Default Values

  static defaultProps = {
    value: '',
    label: '',
    isMandatory: false,
    noLabel: false,
    error: false
  };


  // ----------------
  // Constructor

  constructor(props) {
    super(props);
    this.state = { showPassword: false };
  }


  // ----------------
  // Handler

  handleClickShowPassword = () => {
    this.setState({showPassword: !this.state.showPassword});
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  // ----------------
  // Rendering

  render() {
    // Contants
    const {
      id,
      label,
      onChange,
      value,
      isMandatory,
      classes,
      gridMD,
      noLabel,
      error
    } = this.props;
    const {showPassword} = this.state;

    // Element
    const textField = (
      <TextField
        className={classes.textField}
        id={id}
        type={showPassword ? 'text' : 'password'}
        onChange={onChange}

        value={value ? value : ''}
        label={noLabel ? null : `${label}` + (isMandatory ? '*' : '')}
        error={error}

        helperText="Le mot de passe doit faire au minimum 8 caractères
        et avoir au moins une majuscule et un chiffre"

        margin="normal"
        variant="filled"
        InputProps={{
          classes: {
            underline: classes.cssUnderline,
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={this.handleClickShowPassword}
                onMouseDown={this.handleMouseDownPassword}
              >
                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    );

    return (
      <Grid item xs={12} sm={12} md={gridMD}>
        {textField}
      </Grid>
    );
  }
}

export default withStyles(styles)(CustomPasswordField);
