import {
  ROLES_DOWNLOADED, ROLES_FAILED, ROLES_PENDING, ROLES_RESET, ROLE_SENDING, ROLE_DOWNLOADED,
} from './types';
import { db } from '../../services/firebase';
import { handleError } from '../infos/actions';
// Action creators

export const rolesResetAction = () => ({
  type: ROLES_RESET,
});
export const rolesPendingAction = () => ({
  type: ROLES_PENDING,
});
export const roleSendingAction = () => ({
  type: ROLE_SENDING,
});

export const rolesDownloadedAction = roles => ({
  type: ROLES_DOWNLOADED,
  payload: roles,
});
export const roleDownloadedAction = (authUserId, role) => ({
  type: ROLE_DOWNLOADED,
  payload: role,
  roleId: authUserId,
});

export const rolesFailedAction = error => ({
  type: ROLES_FAILED,
  payload: error,
});

// Actions

export function resetRoles() {
  return (dispatch) => {
    dispatch(rolesResetAction());
  };
}

export function getRoles() {
  return (dispatch) => {
    dispatch(rolesPendingAction());
    return db
      .getRoles()
      .then((snapshot) => {
        let roles = {};
        snapshot.forEach((role) => {
          roles[role.id] = role.data().role;
        });
        console.log(roles);
        dispatch(rolesDownloadedAction(roles));
      })
      .catch((error) => {
        dispatch(rolesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putRole(authUserId, role) {
  return (dispatch) => {
    dispatch(roleSendingAction());
    return db
      .doRoleUpdate(authUserId, role)
      .then(() => {
        dispatch(roleDownloadedAction(authUserId, role));
      })
      .catch((error) => {
        dispatch(rolesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
