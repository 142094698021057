import React, {Component} from 'react';
import Typography from "@material-ui/core/Typography/Typography";
import logo from '../resources/images/logo_universo.svg';
import header from '../resources/images/launch_background.svg';
import {VERDUN_BLUE_LIGHT} from "../constants/colors";
import {withStyles} from "@material-ui/core/index";
import AppBar from "@material-ui/core/AppBar";

const headerHeight = 100;

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  appBar: {
    height: headerHeight,
    justifyContent: 'center',
    marginLeft: 0,
    color: VERDUN_BLUE_LIGHT,
    [theme.breakpoints.up('lg')]: {
      width: `100%`,
    },
    backgroundImage: `url(${header})`,
    backgroundSize: 'cover'
  },
  content: {
    flexGrow: 1,
    paddingTop: 2*headerHeight,
    maxWidth: '100%'
  },
});

export class About extends Component {

  render() {
    return (
      <div className={this.props.classes.root}>
        <AppBar position="fixed" className={this.props.classes.appBar}>
          <img alt="logo" src={logo} style={{height: '80px'}}/>
        </AppBar>
        <div className={this.props.classes.content}>
          <Typography
            variant="h2"
            gutterBottom
          >
            A propos
          </Typography>
          <br/>
          <Typography
            variant="subtitle"
            gutterBottom
          >
            De quoi ?
          </Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(About);
