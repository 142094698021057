import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { firebase } from '../services/firebase';
import * as routes from '../constants/routes';
import { getRoles } from '../stores/roles/actions';
import { ROLE_CONTRIBUTOR } from '../constants/roles';
import { handleError } from '../stores/infos/actions';
import withMenu from "./withMenu";
import {resetJobs} from "../stores/jobs/actions";
import {resetLocations} from "../stores/locations/actions";
import {resetTreats} from "../stores/treats/actions";
import {resetCitizenships} from "../stores/citizenships/actions";
import {resetHousings} from "../stores/housings/actions";
import {resetSurveys} from "../stores/surveys/actions";
import {resetTrainings} from "../stores/trainings/actions";
import {resetTransports} from "../stores/transports/actions";
import {resetResources} from "../stores/resources/actions";

const withAuthorization = title => (Component) => {
  class WithAuthorization extends React.Component {
    state = {};

    static getDerivedStateFromProps(props) {
      let newState = null;
      if (!props.roles) {
        props.getRoles();
      }

      firebase.auth.onAuthStateChanged((authUser) => {
        console.log("onAuthStateChanged");
        console.log(authUser);
        console.log(props.authUser);
        if (authUser && !props.authUser) {
          newState = { ...newState, authUser };
        } else if (!authUser || authUser !== props.authUser) {
          console.log("pushSignIn");
          props.resetLocations();
          props.resetJobs();
          props.resetTreats();
          props.resetCitizenships();
          props.resetHousings();
          props.resetSurveys();
          props.resetTrainings();
          props.resetTransports();
          props.resetResources();
          props.history.push(routes.SIGN_IN);
        }
      });

      if (props.roles && props.authUser) {
        const role = props.roles[props.authUser.uid]
          ? props.roles[props.authUser.uid] : 0;
        if (role < ROLE_CONTRIBUTOR) {
          firebase.auth.signOut()
            .then(() => {
              props.resetLocations();
              props.resetJobs();
              props.resetTreats();
              props.resetCitizenships();
              props.resetHousings();
              props.resetSurveys();
              props.resetTrainings();
              props.resetTransports();
              props.history.push(routes.SIGN_IN);
            })
            .catch((error) => {
              props.handleError(error);
            });
        }
      }
      return newState;
    }

    render() {
      if (this.props.authUser && !this.props.pending && this.props.roles) {
        return (
          <Component {...this.props}/>
        );
      }
      return null;
    }
  }

  const mapStateToProps = state => ({
    authUser: state.users.authUser,
    roles: state.roles.content,
    pending: state.roles.pending,
  });

  return compose(
    withRouter,
    withMenu(title),
    connect(mapStateToProps, { getRoles, handleError,
      resetJobs, resetLocations, resetTreats, resetCitizenships, resetHousings,
      resetSurveys, resetTrainings, resetTransports, resetResources}),
  )(WithAuthorization);
};

export default withAuthorization;
