import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import UserForm from "../../components/forms/UserForm";
import LargePaper from "../../components/common/LargePaper";

export const UserAddPage = props => {
  return (
    <LargePaper title="Ajouter un utilisateur">
      <UserForm history={props.history} />
    </LargePaper>
  );
};

UserAddPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default withAuthorization('Utilisateur')(UserAddPage);
