import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ARTISTS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.ARTIST_SENDING:
      return {
        ...state,
        sending: true,
      };
    case types.RESOURCES_RESET:
      return {
        ...INITIAL_STATE
      };
    case types.ARTISTS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.ARTIST_DOWNLOADED: {
      const artistDownloaded = action.payload;
      let artists = [];
      let artistIndex = -1;
      if (state.content) {
        artists = state.content.slice();
        artistIndex = artists.findIndex(artist => artist.id === artistDownloaded.id);
      }
      if (artistIndex === -1) {
        artists.push(artistDownloaded);
      } else {
        artists[artistIndex] = artistDownloaded;
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: artists,
      };
    }
    case types.RESOURCE_DOWNLOADED: {
      const artistId = action.payload.artistId;
      const resourceDownloaded = action.payload.resource;
      let artist;
      let artists = [];
      let artistIndex = -1;
      let resources = [];
      let resourceIndex = -1;
      if (state.content) {
        artists = state.content.slice();
        artistIndex = artists.findIndex(artist => artist.id === artistId);
        artist = artists[artistIndex];
        if (!artist.resources) {
          artist.resources = [];
        }
        resources = artist.resources;
        resourceIndex = resources.findIndex(resource => resource.id === resourceDownloaded.id);
      }
      if (resourceIndex === -1) {
        artist.resources.push(resourceDownloaded);
      } else {
        artist.resources[resourceIndex] = resourceDownloaded;
      }

      artists[artistIndex] = artist;
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: artists,
      };
    }
    case types.ARTIST_REMOVED: {
      const artist = action.payload;
      let artists = state.content.slice();
      if (state.content !== null) {
        let isArtist = (artistId) => {
          return function (element) {
            return element.id !== artistId;
          }
        };
        artists = state.content.filter(isArtist(artist.id));
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: artists,
      };
    }

    case types.RESOURCE_REMOVED: {
      const resource = action.payload;
      let artists = state.content.slice();
      let artistIndex = artists.findIndex(artist => artist.resources.includes(resource));
      if (state.content !== null && artistIndex !== -1) {
        let isResource = (resourceId) => {
          return function (element) {
            return element.id !== resourceId;
          }
        };
        let artist = artists[artistIndex];
        artist.resources = artist.resources.filter(isResource(resource.id));
        artists[artistIndex] = artist;
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: artists,
      };
    }
    case types.ARTISTS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    default:
      break;
  }
  return state;
}
