import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "recompose";

import {dispatchInfo, handleError} from "../../stores/infos/actions";
import Spinner from "../common/Spinner";

import CustomForm from "../common/form/CustomForm";
import ApiService from "../../services/ApiService";

export class PushForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    pending: PropTypes.bool.isRequired,
    sending: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    handleError: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    let newState = {...state};

    if (!props.roles && !props.pending) {
      props.getRoles();
    }
    return newState;
  }

  onSubmit = (event, formState) => {
    this.setState({sending:true});
      ApiService.postFreePush(
        this.props.token,
        formState
      )
      .then(() => {
        this.props.dispatchInfo("Push envoyé");
        this.setState({sending:false});
      })
      .catch((error) => {
        this.props.handleError(error);
      });
    event.preventDefault();
  };

  render() {

    const {pending} = this.props;

    const {sending} = this.state;

    const buttonLabel = sending ?
      (<Spinner variant="nomargin" />)
      : 'Envoyer';

    const load = (<Spinner />);

    const formFields = [
      {id: 'title', type:'textField', label: 'Titre', isMandatory:true}
    ];

    const customForm = (
      <CustomForm
        data={this.state}
        formFields={formFields}
        buttonLabel={buttonLabel}
        onSubmit={this.onSubmit}
        sending={sending}
      />
    );

    if (pending) {
      return (load);
    } else {
      return (customForm);
    }
  }
}

function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    roles: state.roles.content,
    token: state.users.authUser.ra,
    pending: state.users.pending
  };
}

export default compose(
  connect(mapStateToProps, {handleError, dispatchInfo}),
)(PushForm);
