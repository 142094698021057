export const HOMEPAGE = '/';
export const PASSWORD_FORGET = '/pw-forget';
export const PASSWORD_CHANGE = '/pw-change/:token';
export const SIGN_IN = '/signin';
export const NOT_FOUND = '/notfound';
export const SURVEYS = '/surveys';
export const SURVEY_ADD = '/surveys/add';
export const SURVEY_EDIT = '/surveys/:surveyId';
export const TRANSPORTS = '/transports';
export const TRANSPORT_ADD = '/transports/add';
export const TRANSPORT_EDIT = '/transports/:transportId';
export const LOCATIONS = '/locations';
export const LOCATION_ADD = '/locations/add';
export const LOCATION_EDIT = '/locations/:locationId';
export const HOUSINGS = '/housings';
export const HOUSING_ADD = '/housings/add';
export const HOUSING_EDIT = '/housings/:housingId';

export const CENTERS = '/centers';
export const CENTER_ADD = '/centers/add';
export const CENTER_EDIT = '/centers/:centerId';
export const TRAINING_ADD = '/centers/:centerId/trainings/add';
export const TRAINING_EDIT = '/centers/:centerId/trainings/:trainingId';

export const TREAT_CENTERS = '/treat-centers';
export const TREAT_CENTER_ADD = '/treat-centers/add';
export const TREAT_CENTER_EDIT = '/treat-centers/:centerId';
export const TREAT_ADD = '/treat-centers/:centerId/treats/add';
export const TREAT_EDIT = '/treat-centers/:centerId/treats/:treatId';

export const CITIZENSHIP_CENTERS = '/citizenship-centers';
export const CITIZENSHIP_CENTER_ADD = '/citizenship-centers/add';
export const CITIZENSHIP_CENTER_EDIT = '/citizenship-centers/:centerId';
export const CITIZENSHIP_ADD = '/citizenship-centers/:centerId/citizenships/add';
export const CITIZENSHIP_EDIT = '/citizenship-centers/:centerId/citizenships/:citizenshipId';

export const JOBS = '/jobs';
export const JOB_ADD = '/jobs/add';
export const JOB_EDIT = '/jobs/:jobId';

export const ARTISTS = '/artists';
export const ARTIST_ADD = '/artists/add';
export const ARTIST_EDIT = '/artists/:artistId';
export const RESOURCES_ADD = '/artists/:artistId/resources/add';
export const RESOURCES_EDIT = '/artists/:artistId/resources/:resourcesId';

export const ADMINS = '/admins';
export const USER_ADD = '/admins/add';
export const USER_EDIT = '/admins/:userId';

export const SIGNALS = '/signals';
export const SIGNAL_EDIT = '/signals/:signalId';

export const FREE_PUSH = '/free-push';

export const CGU = '/cgu';
export const ABOUT = '/about';
