import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

// --------------------------------
// Action creators

export const jobsPendingAction = () => ({
  type: types.JOBS_PENDING,
});
export const jobSendingAction = () => ({
  type: types.JOB_SENDING,
});
export const jobsResetAction = () => ({
  type: types.JOBS_RESET,
});

export const jobsDownloadedAction = jobs => ({
  type: types.JOBS_DOWNLOADED,
  payload: jobs,
});

export const jobDownloadedAction = (job) => ({
  type: types.JOB_DOWNLOADED,
  payload: job
});

export const jobRemovedAction = (job) => ({
  type: types.JOB_REMOVED,
  payload: job,
});

export const jobsFailedAction = error => ({
  type: types.JOBS_FAILED,
  payload: error,
});


// --------------------------------
// Actions

export function resetJobs() {
  return (dispatch) => {
    dispatch(jobsResetAction());
  }
}

export function getJobs(token) {
  return (dispatch) => {
    dispatch(jobsPendingAction());
    return ApiService
      .getJobs(token)
      .then((data) => {
        dispatch(jobsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(jobsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postJob(token, job) {
  return (dispatch) => {
    dispatch(jobSendingAction());

    return ApiService
      .postJob(token, job)
      .then((data) => {
        dispatch(jobDownloadedAction(data));
        return data.id
      })
      .catch((error) => {
        dispatch(jobsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postJobPicture(token, jobId, picture, onUploadProgress = null) {
  return (dispatch) => {
    dispatch(jobSendingAction());
    return ApiService
      .postPicture(token, {entity_id: jobId, entity_name: "job", position: 0}, picture, onUploadProgress)
      .then((result) => {
        dispatch(jobDownloadedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(jobsFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

export function putJob(token, jobId, job) {
  return (dispatch) => {
    dispatch(jobSendingAction());
    return ApiService
      .putJob(token, jobId, job)
      .then((data) => {
        const updatedJob = {
          ...data,
          id: jobId,
        };
        dispatch(jobDownloadedAction(updatedJob));
        return data.id
      })
      .catch((error) => {
        dispatch(jobsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function deleteJob(token, job) {
  return (dispatch) => {
    dispatch(jobSendingAction());
    return ApiService
      .deleteJob(token, job.id)
      .then(() => {
        dispatch(jobRemovedAction(job));
      })
      .catch((error) => {
        dispatch(jobsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
