import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../hocs/withAuthorization";
import PushForm from "../components/forms/PushForm";
import LargePaper from "../components/common/LargePaper";

export const FreePushPage = props => {
  return (
    <LargePaper title="Notifier les utilisateur">
      <PushForm history={props.history} />
    </LargePaper>
  );
};

FreePushPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default withAuthorization('Notification')(FreePushPage);
