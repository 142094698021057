import React from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {VERDUN_DARK_BLUE, VERDUN_BLUE_LIGHT} from "../../constants/colors";

const styles = () => ({
  button: {
    margin: 'auto'
  },
  parent: {
    display: 'inline-block',
    width: 'auto',
    minWidth: '130px',
    height: 'auto'
  },
  card: {
    display: 'flex',
    margin: '5px',
    width: 'auto'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  cover: {
    backgroundColor: VERDUN_DARK_BLUE,
    padding: 0
  },
  coverIcon: {
    color: VERDUN_BLUE_LIGHT,
    width: '10vw',
    height: '10vw',
    maxWidth: '130px',
    maxHeight: '130px',
    paddingTop: '1vw'
  },
});

export const DashboardCard = props => (
  <div className={props.classes.parent}>
    <Card className={props.classes.card}>
      <Hidden smDown>
        <CardContent className={props.classes.cover}>
          <Typography variant="h2" component="h2" className={props.classes.coverIcon}>
            {props.icon}
          </Typography>
        </CardContent>
      </Hidden>
      <div className={props.classes.details}>
        <CardContent>
          <Typography variant="h4" component="h2">
            {props.label}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            size="small"
            color="primary"
            href={props.link}
            className={props.classes.button}
          >
            {/* eslint-disable-next-line */}
            Gestion des {props.label.toLowerCase()}
          </Button>
        </CardActions>
      </div>
    </Card>
  </div>
);

DashboardCard.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
};

export default withStyles(styles)(DashboardCard);
