import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.TRANSPORTS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.TRANSPORT_SENDING:
      return {
        ...state,
        sending: true,
      };
    case types.TRANSPORTS_RESET:
      return {
        ...INITIAL_STATE
      };
    case types.TRANSPORTS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.TRANSPORT_DOWNLOADED: {
      const transportDownloaded = action.payload;
      let transports = [];
      let transportIndex = -1;
      if (state.content) {
        transports = state.content.slice();
        transportIndex = transports.findIndex(transport => transport.id === transportDownloaded.id);
      }
      if (transportIndex === -1) {
        transports.push(transportDownloaded);
      } else {
        transports[transportIndex] = transportDownloaded;
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: transports,
      };
    }
    case types.TRANSPORT_REMOVED: {
      const transport = action.payload;
      let RemovedTransportOrder = transport.order;
      let transports = state.content.slice();
      if (state.content !== null) {
        let isTransport = (transportId) => {
          return function (element) {
            return element.id !== transportId;
          }
        };
        transports = state.content.filter(isTransport(transport.id));
      }
      transports.forEach(function (element) {
        if (element.order > RemovedTransportOrder) {
          element.order = element.order - 1;
        }
      });
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: transports,
      };
    }
    case types.TRANSPORTS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    default:
      break;
  }
  return state;
}
