import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import CitizenshipForm from "../../components/forms/CitizenshipForm";
import LargePaper from "../../components/common/LargePaper";

export const CitizenshipEditPage = props => {
  return (
    <LargePaper title="Éditer un article">
      <CitizenshipForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

CitizenshipEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withAuthorization('Citoyennenté')(CitizenshipEditPage);
