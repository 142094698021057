import {
  USER_LOGIN,
  USERS_DOWNLOADED,
  USER_DOWNLOADED,
  USERS_FAILED,
  USERS_PENDING,
  USERS_RESET,
  USER_SENDING,
  USER_REMOVED
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false,
  authUser: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case USERS_RESET:
      return INITIAL_STATE;
    case USERS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case USERS_DOWNLOADED:
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    case USER_DOWNLOADED: {
      let userDownload = action.payload;
      userDownload['uid'] = action.userId;
      let users = [];
      let userIndex = -1;
      if (state.content) {
        users = state.content.slice();
        userIndex = users.findIndex(user => user.uid === action.userId);
      }
      if (userIndex === -1) {
        users.push(userDownload);
      } else {
        users[userIndex] = userDownload;
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: users,
      };
    }
    case USER_REMOVED: {
      const user = action.payload;
      let users = state.content.slice();
      if (state.content !== null) {
        let isUser = (userId) => {
          return function (element) {
            return element.uid !== userId;
          }
        };
        users = state.content.filter(isUser(user.uid));
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: users,
      };
    }
    case USERS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case USER_LOGIN:
      return {
        ...state,
        authUser: action.payload,
      };
    case USER_SENDING:
      return {
        ...state,
        sending: true,
      };
    default:
      break;
  }
  return state;
}
