import React from 'react';
import PropTypes from "prop-types";
import {withRouter, Link} from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import Hidden from '@material-ui/core/Hidden';
import Paper from "@material-ui/core/Paper/Paper";
import SignInForm from '../components/forms/SignInForm';
import {PASSWORD_FORGET} from '../constants/routes';
import stylesDefault from '../constants/stylesDefault';
import logo from '../resources/images/logo_universo.svg';
import background from '../resources/images/launch_background.svg';
import SmallPaper from "../components/common/SmallPaper";

const PasswordForgetLink = () =>
  (
    <Typography
      style={stylesDefault.center}
      variant="caption"
      gutterBottom
    >
      <Link to={PASSWORD_FORGET}>Mot de passe oublié ?</Link>
    </Typography>
  );

export const SignInPage = (props) =>
  (
    <div>
      <Hidden smDown>
        <Paper style={stylesDefault.background}>
          <img alt="background" src={background} style={stylesDefault.backgroundImage} />
          <img alt="logo" src={logo} style={stylesDefault.backgroundLogo} />
        </Paper>
      </Hidden>

      <Hidden smDown>
        <Paper style={stylesDefault.smallRightlayout}>
          <Typography
            style={stylesDefault.center}
            variant="h5"
            gutterBottom
          >
            Connexion
          </Typography>
          <SignInForm history={props.history} />
          <PasswordForgetLink />
        </Paper>
      </Hidden>

      <Hidden mdUp>
        <div style={stylesDefault.center}>
          <SmallPaper>
            <div style={stylesDefault.smallLogoBlock}>
              <img alt="Verdun" src={logo} style={stylesDefault.smallLogo} />
            </div>
            <Typography
              style={stylesDefault.center}
              variant="h5"
              gutterBottom
            >
              Connexion
            </Typography>
            <SignInForm history={props.history} />
            <PasswordForgetLink />
          </SmallPaper>
        </div>
      </Hidden>
    </div>
  );

SignInPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired
};

export default withRouter(SignInPage);
