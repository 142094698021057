import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import TransportForm from "../../components/forms/TransportForm";
import LargePaper from "../../components/common/LargePaper";

export const TransportAddPage = props => {
  return (
    <LargePaper title="Ajouter un POI Se bouger">
      <TransportForm history={props.history} />
    </LargePaper>
  );
};

TransportAddPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default withAuthorization('Se bouger')(TransportAddPage);
