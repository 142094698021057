import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DOMPurify from "dompurify";

import {withStyles} from "@material-ui/core/styles/index";
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";

import CustomTableHead from "./CustomTableHead";
import CustomTableRow from "./CustomTableRow";
import CustomTablePagination from "./CustomTablePagination";
import ArrayService from "../../../services/ArrayService";
import * as Roles from "../../../constants/roles";

/**
 * Style of Table elements
 *
 * @returns json
 */
const styles = () => ({
  root: {
    width: '100%',
    marginTop: 30,
    overflowX: 'scroll'
  },
  table: {
    minWidth: 200
  },
  EmptyData: {
    margin: '50px 0',
  },
  addButton: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    bottom: '3vh',
    right: '3vh',
    zIndex: '5'
  },
  tableCell: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 10,
    paddingLeft: 10
  },
  mobilePadding: {
    height: '60px'
  }
});

/**
 * Class CustomTable
 *
 * @Component : <CustomTable />
 * @Extend : React.Component
 */
class CustomTable extends Component {

  // ----------------
  // Type of each Props

  static propTypes = {
    classes: PropTypes.object.isRequired,

    // eslint-disable-next-line react/no-unused-prop-types
    datas: PropTypes.array,

    onAdd: PropTypes.func,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,

    buttonLabel: PropTypes.string,
    paginationLabel: PropTypes.string.isRequired,
    emptyDataMessage: PropTypes.string.isRequired,
    deleteDialogText: PropTypes.string,

    columnData: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        align: PropTypes.string, //["inherit","left","center","right","justify"]
        disablePadding: PropTypes.bool,
      })
    ).isRequired,
    sending: PropTypes.bool.isRequired,
    canReorder: PropTypes.bool,
    onUpOrder: PropTypes.func,
    onDownOrder: PropTypes.func,
    deleteExceptId: PropTypes.string,
    showDelete: PropTypes.bool,
    showAdd: PropTypes.bool,
    defaultOrderBy: PropTypes.string,
    defaultOrder: PropTypes.string
  };


  // ----------------
  // Default Values

  static defaultProps = {
    datas: [],
    deleteDialogText: 'Êtes-vous sur de vouloir supprimer cet élément ?',
    canReorder: false,
    onAdd: null,
    onDelete: null,
    onUpOrder: null,
    onDownOrder: null,
    buttonLabel: '',
    deleteExceptId: '',
    showDelete: true,
    showAdd: true,
    defaultOrderBy: 'title',
    defaultOrder: 'desc'
  };


  // ----------------
  // Life Cycle

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (props.datas
      && (props.datas.length !== state.data.length
        || props.datas.map(data => state.data.includes(data)))
    ) {
      if (!props.canReorder) {
        props.columnData.forEach(element => {
          if (element.specialOrderBy !== undefined) {
            props.datas.forEach(data => {
              if (data[element.specialOrderBy] === undefined) {
                data[element.specialOrderBy] = CustomTable.displayAttrOfElement(data, element);
              }
            });
          }
        });
      }
      newState = {
        ...newState,
        data: props.datas.sort((a, b) => ArrayService.sort(a, b, state.orderBy, state.order))

      }
    }

    return newState;
  }


  // ----------------
  // Computing

  static displayAttrOfElement = (element, column) => {
    if (typeof column.id === 'undefined') {
      return;
    }
    const columnIdArray = column.id.split('.');
    columnIdArray.forEach((value, index) => {
      if (value === 'role') {
        let roleName = element[value];
        element = Roles[roleName];
      } else if (value.includes('[')) {
        let openBracketIndex = value.indexOf('[');
        let closeBracketIndex = value.indexOf(']');
        let arrayToFilter = value.slice(0, openBracketIndex);
        let filterCondition = 'elem => elem.'
          .concat(value.slice(openBracketIndex + 1, closeBracketIndex));
        // eslint-disable-next-line no-eval
        let temp = element[arrayToFilter].find(eval(filterCondition));
        if (temp) {
          element = temp;
        }
      } else if (typeof column.data !== 'undefined' && column.data) {
        let isElement = function (e) {
          return e.id === element[value];
        };
        if (column.data[index]) {
          let temp = column.data[index].find((e) => {
            return isElement(e)
          });
          if (temp) {
            element = temp;
          }
        } else {
          element = element[value];
        }
      } else {
        element = element[value];
      }
    });

    if (typeof column.date !== 'undefined' && column.date) {
      const date = new Date(element * 1000);
      return ('0'
        + date.getDate()).slice(-2)
        + '/' + ('0' + (date.getMonth() + 1)).slice(-2)
        + '/' + date.getFullYear();
    } else if (typeof column.dateTime !== 'undefined' && column.dateTime) {
      const date = new Date(element * 1000);
      const hh = date.getHours();
      const mm = date.getMinutes();

      return ('0'
        + date.getDate()).slice(-2)
        + '/' + ('0' + (date.getMonth() + 1)).slice(-2)
        + '/' + date.getFullYear()
        + ' ' + (hh > 9 ? '' : '0') + hh
        + ':' + (mm > 9 ? '' : '0') + mm;
    } else if (typeof column.image !== 'undefined' && column.image) {
      if (element) {
        return (
          <img
            style={{maxHeight: '100px', maxWidth: '140px'}}
            src={element}
            alt=""
            height="auto"
            width="auto"
          />
        );
      } else {
        return (
          <img
            src='/images/no_picture.svg'
            align="middle"
            alt=""
            height="50px"
            width="auto"
          />
        );
      }
    } else if (typeof column.boolean !== 'undefined' && column.boolean) {
      return (
        <FormControlLabel disabled control={<Checkbox checked={element} />} />
      );
    } else if (typeof column.description !== 'undefined' && column.description) {
      return (
        <div
          style={styles.text}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(element)}}
        />
      );
    } else {
      return element;
    }
  };

  // ----------------
  // Constructor

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: props.defaultOrder,
      orderBy: props.canReorder ? 'order' : props.defaultOrderBy,
      data: [],
      // eslint-disable-next-line react/no-unused-state
      page: 0,
      // eslint-disable-next-line react/no-unused-state
      rowsPerPage: 25,
    };
  }

  // ----------------
  // Handler

  handleAdd = event => {
    event.stopPropagation();
    this.props.onAdd();
  };

  handleRequestSort = (event, property, doNotChangeTheOrder) => {
    const orderBy = property;
    let order = 'desc';
    if (doNotChangeTheOrder) {
      order = this.state.order;
    } else {
      if (this.state.orderBy === orderBy && this.state.order === order) {
        order = 'asc';
      }
    }
    const data = this.state.data.sort((a, b) => ArrayService.sort(a, b, orderBy, order));
    this.setState({data, order, orderBy});
  };

  handleChangePage = (event, page) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({page});
  };

  handleChangeRowsPerPage = (event) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({rowsPerPage: event.target.value});
    this.handleRequestSort(event, this.state.orderBy, true);
  };

  // ----------------
  // Rendering

  render() {
    // Constants
    const {
      classes,
      onEdit,
      onDelete,
      buttonLabel,
      paginationLabel,
      emptyDataMessage,
      deleteDialogText,
      columnData,
      sending,
      showAdd
    } = this.props;

    const {
      rowsPerPage, page, data, order, orderBy,
    } = this.state;

    const start = page * rowsPerPage;
    const end = (page * rowsPerPage) + rowsPerPage;
    const show = data && data.length;

    // Elements

    const addButton = (
      <div>
        <Hidden mdDown>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleAdd}
          >
            {buttonLabel}
          </Button>
        </Hidden>
        <Hidden lgUp>
          <Button
            className={classes.addButton}
            color="primary"
            variant="fab"
            onClick={this.handleAdd}
            aria-label="add"
          >
            <AddIcon />
          </Button>
        </Hidden>
      </div>
    );

    const tableHeader = (
      <CustomTableHead
        onRequestSort={this.props.canReorder ? null : this.handleRequestSort}
        order={order}
        orderBy={orderBy}
        rowCount={data.length}
        columnData={columnData}
      />
    );

    const tableBody = (
      <TableBody>
        {data.slice(start, end).map(element => (
          <CustomTableRow
            key={element.id}
            onEdit={onEdit}
            onDelete={onDelete}
            element={element}
            deleteDialogText={deleteDialogText}
            sending={sending}
            withDeleteButton={this.props.showDelete && this.props.deleteExceptId !== element.id}
            onUpOrder={this.props.canReorder ? this.props.onUpOrder : null}
            onDownOrder={this.props.canReorder ? this.props.onDownOrder : null}
            dataLength={data.length}
          >
            {columnData.map(column => (
              <TableCell
                className={classes.tableCell}
                align={column.align}
                key={columnData.indexOf(column)}
              >
                {CustomTable.displayAttrOfElement(element, column)}
              </TableCell>
            ))}
          </CustomTableRow>
        ))}
      </TableBody>
    );

    const tablePagination = (
      <CustomTablePagination
        numberOfItems={data.length}
        rowsPerPage={rowsPerPage}
        label={paginationLabel}
        page={page}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );


    const table = (
      <div>
        {onDelete && showAdd && addButton}
        <Paper className={classes.root}>
          <Table className={classes.table}>
            {tableHeader}
            {tableBody}
          </Table>
        </Paper>
        {data.length > 10 && tablePagination}
        <Hidden mdUp>
          <div className={classes.mobilePadding} />
        </Hidden>
      </div>
    );

    const emptyData = (
      <div>
        {showAdd && addButton}
        <Typography
          variant="h5"
          color="primary"
          className={classes.EmptyData}
        >
          {emptyDataMessage}
        </Typography>
      </div>
    );

    // Render
    return (<div>{show ? table : emptyData}</div>);
  }
}

export default withStyles(styles)(CustomTable);
