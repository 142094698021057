import React from 'react';
import PropTypes from "prop-types";
import {compose} from "recompose";
import {
  BrowserRouter as Router,
  Route, Switch
} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import withRoot from "./hocs/withRoot";
import Error404 from "./containers/Error404";
import SnackBar from "./components/common/SnackBar";
import * as routes from "./constants/routes";
import SignInPage from "./containers/SignInPage";
import HomePage from "./containers/HomePage";
import PasswordChangePage from "./containers/PasswordChangePage";
import PasswordForgetPage from "./containers/PasswordForgetPage";
import withAuthentication from "./hocs/withAuthentication";
import SurveysPage from "./containers/surveys/SurveysPage";
import SurveyAddPage from "./containers/surveys/SurveyAddPage";
import SurveyEditPage from "./containers/surveys/SurveyEditPage";
import TransportsPage from "./containers/transports/TransportsPage";
import TransportAddPage from "./containers/transports/TransportAddPage";
import TransportEditPage from "./containers/transports/TransportEditPage";
import LocationsPage from "./containers/locations/LocationsPage";
import LocationAddPage from "./containers/locations/LocationAddPage";
import LocationEditPage from "./containers/locations/LocationEditPage";
import HousingsPage from "./containers/housings/HousingsPage";
import HousingAddPage from "./containers/housings/HousingAddPage";
import HousingEditPage from "./containers/housings/HousingEditPage";
import TrainingCentersPage from "./containers/trainings/TrainingCentersPage";
import TrainingCenterAddPage from "./containers/trainings/TrainingCenterAddPage";
import TrainingCenterEditPage from "./containers/trainings/TrainingCenterEditPage";
import TrainingAddPage from "./containers/trainings/TrainingAddPage";
import TrainingEditPage from "./containers/trainings/TrainingEditPage";
import TreatCentersPage from "./containers/treats/TreatCentersPage";
import TreatCenterAddPage from "./containers/treats/TreatCenterAddPage";
import TreatCenterEditPage from "./containers/treats/TreatCenterEditPage";
import TreatAddPage from "./containers/treats/TreatAddPage";
import TreatEditPage from "./containers/treats/TreatEditPage";
import CitizenshipCentersPage from "./containers/citizenship/CitizenshipCentersPage";
import CitizenshipCenterAddPage from "./containers/citizenship/CitizenshipCenterAddPage";
import CitizenshipCenterEditPage from "./containers/citizenship/CitizenshipCenterEditPage";
import CitizenshipAddPage from "./containers/citizenship/CitizenshipAddPage";
import CitizenshipEditPage from "./containers/citizenship/CitizenshipEditPage";
import JobsPage from "./containers/jobs/JobsPage";
import JobAddPage from "./containers/jobs/JobAddPage";
import JobEditPage from "./containers/jobs/JobEditPage";
import UsersPage from "./containers/users/UsersPage";
import UserAddPage from "./containers/users/UserAddPage";
import UserEditPage from "./containers/users/UserEditPage";
import CGU from "./containers/CGU";
import About from "./containers/About";
import SignalsPage from "./containers/signals/SignalsPage";
import SignalEditPage from "./containers/signals/SignalEditPage";
import ArtistsPage from "./containers/artists/ArtistsPage";
import ArtistAddPage from "./containers/artists/ArtistAddPage";
import ArtistEditPage from "./containers/artists/ArtistEditPage";
import ResourcesAddPage from "./containers/artists/ResourcesAddPage";
import ResourcesEditPage from "./containers/artists/ResourcesEditPage";
import FreePushPage from "./containers/FreePushPage";

const styles = () => ({
  root: {
    display: 'flex',
    position: 'relative',

    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden'
  },
  content: {
    flexGrow: 1
  }
});

const App = (props) => {
  const { classes } = props;
  return (
    <Router>
      <div className={classes.content}>
        <SnackBar />
        <Switch>
          <Route exact path={routes.SIGN_IN} component={SignInPage} />
          <Route exact path={routes.PASSWORD_FORGET} component={PasswordForgetPage} />
          <Route exact path={routes.PASSWORD_CHANGE} component={PasswordChangePage} />
          <Route exact path={routes.HOMEPAGE} component={HomePage} />
          <Route exact path={routes.NOT_FOUND} component={Error404} />
          <Route exact path={routes.SURVEYS} component={SurveysPage} />
          <Route exact path={routes.SURVEY_ADD} component={SurveyAddPage} />
          <Route exact path={routes.SURVEY_EDIT} component={SurveyEditPage} />
          <Route exact path={routes.TRANSPORTS} component={TransportsPage} />
          <Route exact path={routes.TRANSPORT_ADD} component={TransportAddPage} />
          <Route exact path={routes.TRANSPORT_EDIT} component={TransportEditPage} />
          <Route exact path={routes.LOCATIONS} component={LocationsPage} />
          <Route exact path={routes.LOCATION_ADD} component={LocationAddPage} />
          <Route exact path={routes.LOCATION_EDIT} component={LocationEditPage} />
          <Route exact path={routes.HOUSINGS} component={HousingsPage} />
          <Route exact path={routes.HOUSING_ADD} component={HousingAddPage} />
          <Route exact path={routes.HOUSING_EDIT} component={HousingEditPage} />

          <Route exact path={routes.CENTERS} component={TrainingCentersPage} />
          <Route exact path={routes.CENTER_ADD} component={TrainingCenterAddPage} />
          <Route exact path={routes.CENTER_EDIT} component={TrainingCenterEditPage} />
          <Route exact path={routes.TRAINING_ADD} component={TrainingAddPage} />
          <Route exact path={routes.TRAINING_EDIT} component={TrainingEditPage} />


          <Route exact path={routes.TREAT_CENTERS} component={TreatCentersPage} />
          <Route exact path={routes.TREAT_CENTER_ADD} component={TreatCenterAddPage} />
          <Route exact path={routes.TREAT_CENTER_EDIT} component={TreatCenterEditPage} />
          <Route exact path={routes.TREAT_ADD} component={TreatAddPage} />
          <Route exact path={routes.TREAT_EDIT} component={TreatEditPage} />


          <Route exact path={routes.CITIZENSHIP_CENTERS} component={CitizenshipCentersPage} />
          <Route exact path={routes.CITIZENSHIP_CENTER_ADD} component={CitizenshipCenterAddPage} />
          <Route exact path={routes.CITIZENSHIP_CENTER_EDIT} component={CitizenshipCenterEditPage} />
          <Route exact path={routes.CITIZENSHIP_ADD} component={CitizenshipAddPage} />
          <Route exact path={routes.CITIZENSHIP_EDIT} component={CitizenshipEditPage} />

          <Route exact path={routes.JOBS} component={JobsPage} />
          <Route exact path={routes.JOB_ADD} component={JobAddPage} />
          <Route exact path={routes.JOB_EDIT} component={JobEditPage} />

          <Route exact path={routes.ARTISTS} component={ArtistsPage} />
          <Route exact path={routes.ARTIST_ADD} component={ArtistAddPage} />
          <Route exact path={routes.ARTIST_EDIT} component={ArtistEditPage} />
          <Route exact path={routes.RESOURCES_ADD} component={ResourcesAddPage} />
          <Route exact path={routes.RESOURCES_EDIT} component={ResourcesEditPage} />

          <Route exact path={routes.SIGNALS} component={SignalsPage} />
          <Route exact path={routes.SIGNAL_EDIT} component={SignalEditPage} />

          <Route exact path={routes.FREE_PUSH} component={FreePushPage} />

          <Route exact path={routes.ADMINS} component={UsersPage} />
          <Route exact path={routes.USER_ADD} component={UserAddPage} />
          <Route exact path={routes.USER_EDIT} component={UserEditPage} />
          <Route exact path={routes.CGU} component={CGU} />
          <Route exact path={routes.ABOUT} component={About} />
          <Route component={Error404} />
        </Switch>
      </div>
    </Router>
  );
};

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withAuthentication,
  withStyles(styles),
  withRoot
)(App);
