import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import TreatCenterForm from "../../components/forms/TreatCenterForm";
import LargePaper from "../../components/common/LargePaper";

export const TreatCenterAddPage = props => {
  return (
    <LargePaper title="Ajouter une thématique">
      <TreatCenterForm history={props.history} />
    </LargePaper>
  );
};

TreatCenterAddPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default withAuthorization('Se soigner')(TreatCenterAddPage);
