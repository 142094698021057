import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "recompose";

import {postResource, putResource, getArtists, postResourcePicture} from "../../stores/resources/actions";
import {getNbModerated, handleError} from "../../stores/infos/actions";
import {ARTIST_EDIT} from "../../constants/routes";
import Spinner from "../common/Spinner";

import CustomForm from "../common/form/CustomForm";
import {ROLE_MODERATOR} from "../../constants/roles";
import AuthService from "../../services/AuthService";

// Intial State of the Component
const INITIAL_STATE = {
  artistId:'',
  id:'',
  title:'',
  description:'',
  category:'',
  link:'',
  year:null,
  imagePath: '',
  pictureToUpload: null,
  sending: false
};

const stateWithResources = (resources, media) => ({
  id: resources.id,
  title: resources.title,
  description: resources.description,
  category: resources.category,
  address: resources.address,
  link: resources.link,
  year: resources.year,
  imagePath: media ? media.image_path : ''
});

export class ResourcesForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    pending: PropTypes.bool.isRequired,
    sending: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    resources: PropTypes.array,
    postResource: PropTypes.func.isRequired,
    putResource: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired
  };

  static defaultProps = {
    housings: null
  };

  static getDerivedStateFromProps(props, state) {
    let newState = {...state};

    if (!props.roles && !props.pending) {
      props.getRoles();
    }
    if (!props.resources && !props.pending && props.token
      && props.match && props.match.params.artistId) {
      newState = {...newState, artistId: props.match.params.artistId};
      props.getArtists(props.token);
    }
    if (props.resources && props.match && props.match.params.artistId
      && state.id === INITIAL_STATE.id) {

      const resources = props.resources;
      const currentArtistId = props.match.params.artistId;
      const foundArtist = resources.find(artist => artist.id === currentArtistId);

      newState = {...newState, resources: foundArtist.resources, artistId: currentArtistId};

      if (props.match.params.resourcesId) {
        const foundResources = foundArtist.resources
          .find(resources => resources.id === props.match.params.resourcesId);
        if (foundResources) {
          const media = foundResources.medias ?
            foundResources.medias.find(media => media.position === 0) : null;
          newState = {...newState, ...stateWithResources(foundResources, media)};
        }
      }
    }

    if (props.sending && !state.sending) { // Start sending
      return {sending: true};
    }
    if (!props.sending && state.sending) { // End sending
      if (state.pictureToUpload && state.id) { // picture To Upload after deal sent
        props.postResourcePicture(props.token, state.artistId, state.id, state.pictureToUpload, (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(state.pictureToUpload.name + ' -> '
            + percentCompleted + '% (' + progressEvent.loaded + ' / ' + progressEvent.total + ')');
        });
        return {
          pictureToUpload: null,
          sending: false
        };
      } else if (!state.pictureToUpload) {
        props.history.push(ARTIST_EDIT.replace(':artistId', state.artistId));
      }
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  onSubmit = (event, formState) => {

    // Retrieve ImageToUpload if available
    if (formState.pictureToUpload) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        pictureToUpload: formState.pictureToUpload
      });
    }

    if (this.state.id === INITIAL_STATE.id) {
      this.props.postResource(
        this.props.token,
        this.state.artistId,
        formState
      )
        .then((resourcesId => {
          if (this.state.pictureToUpload) {
            this.setState({id: resourcesId});
          }
        }))
        .catch((error) => {
          this.props.handleError(error);
        });
    } else {
      this.props.putResource(
        this.props.token,
        this.state.artistId,
        this.state.id,
        formState
      )
        .then(resourcesId => {
          this.props.getNbModerated(this.props.token);
          if (this.state.pictureToUpload) {
            this.setState({id: resourcesId});
          }
        })
        .catch((error) => {
          this.props.handleError(error);
        });
    }
    event.preventDefault();
  };

  render() {

    const {pending, sending} = this.props;

    const {id} = this.state;

    const buttonLabel = sending ?
      (<Spinner variant="nomargin" />)
      : id === ''  ? 'Créer' : 'Éditer';

    const load = (<Spinner />);

    let formFields = [
      {id: 'title', type:'textField', label: 'Titre', isMandatory:true},
      {id: 'imagePath', picture: 'pictureToUpload', type: 'pictureDropzone', isMandatory: false},
      {id: 'description', type:'richText', label: 'Description', isMandatory:true},
      {id: 'year', type:'textField', subtype:'number', gridMD:6, label: 'Année', isMandatory:true},
      {id: 'link', type:'textField', gridMD:6, label: 'Lien', isMandatory:true},
    ];

    if (AuthService.isGranted(ROLE_MODERATOR, this.props.authUser, this.props.roles)) {
      formFields.push({id: 'push', type:'checkboxField', label: 'Notifier les utilisateurs', isMandatory:true});
    }

    const customForm = (
      <CustomForm
        data={this.state}
        formFields={formFields}
        buttonLabel={buttonLabel}
        onSubmit={this.onSubmit}
        sending={sending}
      />
    );

    if (pending) {
      return (load);
    } else {
      return (customForm);
    }
  }
}

function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    roles: state.roles.content,
    token: state.users.authUser.ra,
    resources: state.resources.content,
    pending: state.resources.pending || state.users.pending,
    sending: state.resources.sending
  };
}

export default compose(
  connect(mapStateToProps, {getArtists, postResource, postResourcePicture, putResource, handleError
    , getNbModerated}),
)(ResourcesForm);
