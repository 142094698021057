import * as firebase from 'firebase';
import { ENV_DEV, ENV_LOCAL } from '../../constants/env';

//TODO
const prodConfig = {
  apiKey: "AIzaSyB7GCbNwqgoxIhsJq3cFiGvqr5kiIDTtdk",
  authDomain: "kobooprod.firebaseapp.com",
  databaseURL: "https://kobooprod.firebaseio.com",
  projectId: "kobooprod",
  storageBucket: "kobooprod.appspot.com",
  messagingSenderId: "390620808375"
};

const devConfig = {
  apiKey: "AIzaSyC05IDDwInypBBsSrh5lwiC5Nc_P0GfAjo",
  authDomain: "verdundev-1320c.firebaseapp.com",
  databaseURL: "https://verdundev-1320c.firebaseio.com",
  projectId: "verdundev-1320c",
  storageBucket: "verdundev-1320c.appspot.com",
  messagingSenderId: "???"
};

// REACT_APP_ENV Must be defined in order to use devConfig else it will automatically select prodConfig EVEN if it is not in prod
const config = (process.env.REACT_APP_ENV === ENV_DEV || process.env.REACT_APP_ENV === ENV_LOCAL)
  ? devConfig
  : prodConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.firestore();
const auth = firebase.auth();

export {
  auth,
  db
};
