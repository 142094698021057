import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import HousingForm from "../../components/forms/HousingForm";
import LargePaper from "../../components/common/LargePaper";

export const HousingAddPage = props => {
  return (
    <LargePaper title="Ajouter un POI Se loger">
      <HousingForm history={props.history} />
    </LargePaper>
  );
};

HousingAddPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default withAuthorization('Se loger')(HousingAddPage);
