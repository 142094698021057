import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import TrainingCenterForm from "../../components/forms/TrainingCenterForm";
import LargePaper from "../../components/common/LargePaper";

export const TrainingCenterEditPage = props => {
  return (
    <LargePaper title="Éditer un centre de formation">
      <TrainingCenterForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

TrainingCenterEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withAuthorization('Centre de formation')(TrainingCenterEditPage);
