import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import TrainingCenterForm from "../../components/forms/TrainingCenterForm";
import LargePaper from "../../components/common/LargePaper";

export const TrainingCenterAddPage = props => {
  return (
    <LargePaper title="Ajouter un centre de formation">
      <TrainingCenterForm history={props.history} />
    </LargePaper>
  );
};

TrainingCenterAddPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default withAuthorization('Centre de formation')(TrainingCenterAddPage);
