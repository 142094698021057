import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import TrainingForm from "../../components/forms/TrainingForm";
import LargePaper from "../../components/common/LargePaper";

export const TrainingAddPage = props => {
  return (
    <LargePaper title="Ajouter une formation">
      <TrainingForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

TrainingAddPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withAuthorization('Formation')(TrainingAddPage);
