import React, {Component} from 'react';
import {compose} from "recompose";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {handleError} from "../../stores/infos/actions";
import LargePaper from "../../components/common/LargePaper";
import withAuthorization from "../../hocs/withAuthorization";
import Spinner from "../../components/common/Spinner";
import {TRANSPORT_EDIT, TRANSPORT_ADD} from "../../constants/routes";
import CustomTable from "../../components/common/table/CustomTable";
import AuthService from "../../services/AuthService";
import {ROLE_ADMIN} from "../../constants/roles";
import {deleteTransport, getTransports, putTransport} from "../../stores/transports/actions";

const INITIAL_STATE = {
  datas: []
};

export class TransportsPage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    getTransports: PropTypes.func.isRequired,
    putTransport: PropTypes.func.isRequired,
    deleteTransport: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    authUser: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (props.authUser && !props.transports && !props.pending) {
      props.getTransports(props.authUser.ra);
    }
    if (props.transports) {
      if (state.datas === INITIAL_STATE.datas) {
        newState = {...newState, datas: props.transports};
      }
      if (props.transports !== state.datas && state.datas !== INITIAL_STATE.datas) {
        newState = {...newState, datas: props.transports}
      }
    }
    return newState;
  }

  handleAdd = () => {
    this.props.history.push(TRANSPORT_ADD);
  };

  handleEdit = element => {
    this.props.history.push(TRANSPORT_EDIT.replace(':transportId', element.id));
  };

  handleDelete = element => {
    this.props.deleteTransport(this.props.authUser.ra, element)
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  render() {

    const columnData = [
      {id: 'title', label: 'Titre', align: "left", disablePadding: false},
      {id: 'category', label: 'Catégorie', align: "left", disablePadding: false},
      {id: 'city', label: 'Ville', align: "left", disablePadding: false},
      {id: 'moderated', label: 'Modéré', boolean: true, align: "center", disablePadding: false}
    ];
    return (
      <LargePaper title="Gestion des POIs Se bouger">
        <div>
          {this.props.pending &&
          <Spinner />
          }
          {!this.props.pending && (
            <CustomTable
              datas={this.state.datas}
              onAdd={this.handleAdd}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
              buttonLabel='Ajouter un POI Se bouger'
              paginationLabel='POI Se bouger par page'
              emptyDataMessage='Aucun POI Se bouger'
              deleteDialogText='Êtes-vous sûr de vouloir supprimer ce POI Se bouger ?'
              columnData={columnData}
              sending={this.props.sending}
            />
          )}
        </div>
      </LargePaper>
    );
  }
}


function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    roles: state.roles.content,
    transports: state.transports.content,
    pending: state.transports.pending,
    sending: state.transports.sending,
    error: state.transports.error,
  };
}

export default compose(
  withAuthorization('Se bouger'),
  connect(mapStateToProps, {getTransports, deleteTransport, putTransport, handleError})
)(TransportsPage);
