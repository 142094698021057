import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import ResourcesForm from "../../components/forms/ResourcesForm";
import LargePaper from "../../components/common/LargePaper";

export const ResourcesAddPage = props => {
  return (
    <LargePaper title="Ajouter une resource">
      <ResourcesForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

ResourcesAddPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withAuthorization('Resource')(ResourcesAddPage);
