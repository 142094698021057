import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import ResourcesForm from "../../components/forms/ResourcesForm";
import LargePaper from "../../components/common/LargePaper";

export const ResourcesEditPage = props => {
  return (
    <LargePaper title="Éditer une resource">
      <ResourcesForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

ResourcesEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withAuthorization('Resources')(ResourcesEditPage);
