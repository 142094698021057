import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import JobForm from "../../components/forms/JobForm";
import LargePaper from "../../components/common/LargePaper";

export const JobAddPage = props => {
  return (
    <LargePaper title="Ajouter un emploi">
      <JobForm history={props.history} />
    </LargePaper>
  );
};

JobAddPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default withAuthorization('Emploi')(JobAddPage);
