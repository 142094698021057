import React, {Component} from 'react';
import {compose} from "recompose";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {handleError} from "../../stores/infos/actions";
import LargePaper from "../../components/common/LargePaper";
import withAuthorization from "../../hocs/withAuthorization";
import Spinner from "../../components/common/Spinner";
import {USER_EDIT, USER_ADD} from "../../constants/routes";
import CustomTable from "../../components/common/table/CustomTable";
import AuthService from "../../services/AuthService";
import {ROLE_ADMIN} from "../../constants/roles";
import {deleteUser, getUsers, putUser} from "../../stores/users/actions";
import {getRoles} from "../../stores/roles/actions";

const INITIAL_STATE = {
  datas: []
};

export class UsersPage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    getUsers: PropTypes.func.isRequired,
    putUser: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    authUser: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (!props.users && !props.pending) {
      props.getUsers();
    }
    if (!props.roles && !props.pending) {
      props.getRoles();
    }
    if (props.users && props.roles) {
      let users = props.users;
      let roles = props.roles;
      users.forEach(user => {
        let role = roles[user.uid];
        user['role'] = role;
        switch (role) {
          case 10:
            user['roleStr'] = 'Administrateur';
            break;
          case 5:
            user['roleStr'] = 'Contributeur sans modération';
            break;
          case 4:
            user['roleStr'] = 'Contributeur avec modération';
            break;
          default:
            user['roleStr'] = "Utilisateur"
        }
      });
      users = users.filter(user => user.role > 0);
      if (state.datas === INITIAL_STATE.datas) {
        newState = {...newState, datas: users};
      }
      if (props.users !== state.datas && state.datas !== INITIAL_STATE.datas) {
        newState = {...newState, datas: users}
      }
    }
    return newState;
  }

  handleAdd = () => {
    this.props.history.push(USER_ADD);
  };

  handleEdit = element => {
    this.props.history.push(USER_EDIT.replace(':userId', element.uid));
  };

  handleDelete = element => {
    this.props.deleteUser(this.props.authUser.ra, element);
  };

  render() {

    console.log(this.state.datas);
    const columnData = [
      {id: 'mail', label: 'Mail', align: "left", disablePadding: false},
      {id: 'firstname', label: 'Prénom', align: "left", disablePadding: false},
      {id: 'lastname', label: 'Nom', align: "left", disablePadding: false},
      {id: 'roleStr', label: 'Role', align: "left", disablePadding: false}
    ];
    return (
      <LargePaper title="Gestion des utilisateurs">
        <div>
          {this.props.pending &&
          <Spinner />
          }
          {!this.props.pending && (
            <CustomTable
              datas={this.state.datas}
              onAdd={this.handleAdd}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
              buttonLabel='Ajouter un utilisateur'
              paginationLabel='Utilisateur par page'
              emptyDataMessage='Aucun utilisateur'
              deleteDialogText='Êtes-vous sûr de vouloir supprimer cet utilisateur ?'
              columnData={columnData}
              sending={this.props.sending}
              showDelete={AuthService.isGranted(ROLE_ADMIN, this.props.authUser, this.props.roles)}
            />
          )}
        </div>
      </LargePaper>
    );
  }
}


function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    roles: state.roles.content,
    users: state.users.content,
    pending: state.users.pending,
    sending: state.users.sending,
    error: state.users.error,
  };
}

export default compose(
  withAuthorization('Utilisateurs'),
  connect(mapStateToProps, {getUsers, deleteUser, putUser, getRoles, handleError})
)(UsersPage);
