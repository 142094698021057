import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  info: null,
  communes: null,
  locations: [],
  trainings: [],
  treats: [],
  citizenships: [],
  housings: [],
  transports: [],
  artists: [],
  datas: null,
  pending: false
};

export const arrayToMap = (array, currentValue) => {
  array[currentValue] = currentValue;
  return array;
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.INFO_STATIC_DATAS_DOWNLOADED:
      let communes = action.payload.communes.reduce(arrayToMap, {});
      let housings = [];
      housings["types"] = action.payload.housing.types.reduce(arrayToMap, {});
      housings["options"] = action.payload.housing.options.reduce(arrayToMap, {});
      housings["categories"] = action.payload.housing.categories.reduce(arrayToMap, {});
      let locations = [];
      locations["categories"] = action.payload.directories.categories.reduce(arrayToMap, {});
      let trainings = [];
      trainings["categories"] = action.payload.training.categories.reduce(arrayToMap, {});
      let treats = [];
      treats["categories"] = action.payload.treat.categories.reduce(arrayToMap, {});
      let citizenships = [];
      citizenships["categories"] = action.payload.citizenship.categories.reduce(arrayToMap, {});
      let transports = [];
      transports["categories"] = action.payload.transport.categories.reduce(arrayToMap, {});
      let artists = [];
      artists["categories"] = action.payload.artists.categories.reduce(arrayToMap, {});

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        communes: communes,
        locations: locations,
        trainings: trainings,
        treats: treats,
        citizenships: citizenships,
        housings: housings,
        transports: transports,
        artists: artists
      };
    case types.INFO_STATIC_DATAS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.NB_MODERATED_DOWNLOADED:
      return {
        ...state,
        error: null,
        pending: false,
        datas: action.payload
      };
    case types.NB_MODERATED_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.INFO_STATIC_DATAS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case types.ERROR_RESET:
      return {
        ...state,
        error: null
      };
    case types.ERROR:
      return {
        ...state,
        error: action.payload
      };
    case types.INFO_RESET:
      return {
        ...state,
        info: null
      };
    case types.INFO:
      return {
        ...state,
        info: action.payload
      };
    default:
      break;
  }

  return state;
}
