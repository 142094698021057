import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import TransportForm from "../../components/forms/TransportForm";
import LargePaper from "../../components/common/LargePaper";

export const TransportEditPage = props => {
  return (
    <LargePaper title="Éditer un POI Se bouger">
      <TransportForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

TransportEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withAuthorization('Se bouger')(TransportEditPage);
