import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.TREAT_CENTERS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.TREAT_CENTER_SENDING:
      return {
        ...state,
        sending: true,
      };
    case types.TREATS_RESET:
      return {
        ...INITIAL_STATE
      };
    case types.TREAT_CENTERS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.TREAT_CENTER_DOWNLOADED: {
      const centerDownloaded = action.payload;
      let centers = [];
      let centerIndex = -1;
      if (state.content) {
        centers = state.content.slice();
        centerIndex = centers.findIndex(center => center.id === centerDownloaded.id);
      }
      if (centerIndex === -1) {
        centers.push(centerDownloaded);
      } else {
        centers[centerIndex] = centerDownloaded;
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: centers,
      };
    }
    case types.TREAT_DOWNLOADED: {
      const centerId = action.payload.centerId;
      const treatDownloaded = action.payload.treat;
      let treatCenter;
      let centers = [];
      let centerIndex = -1;
      let treats = [];
      let treatIndex = -1;
      if (state.content) {
        centers = state.content.slice();
        centerIndex = centers.findIndex(center => center.id === centerId);
        treatCenter = centers[centerIndex];
        if (!treatCenter.treats) {
          treatCenter.treats = [];
        }
        treats = treatCenter.treats;
        treatIndex = treats.findIndex(treat => treat.id === treatDownloaded.id);
      }
      if (treatIndex === -1) {
        treatCenter.treats.push(treatDownloaded);
      } else {
        treatCenter.treats[treatIndex] = treatDownloaded;
      }

      centers[centerIndex] = treatCenter;
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: centers,
      };
    }
    case types.TREAT_CENTER_REMOVED: {
      const center = action.payload;
      let centers = state.content.slice();
      if (state.content !== null) {
        let isCenter = (centerId) => {
          return function (element) {
            return element.id !== centerId;
          }
        };
        centers = state.content.filter(isCenter(center.id));
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: centers,
      };
    }

    case types.TREAT_REMOVED: {
      const treat = action.payload;
      let centers = state.content.slice();
      let centerIndex = centers.findIndex(center => center.treats.includes(treat));
      if (state.content !== null && centerIndex !== -1) {
        let isTreat = (treatId) => {
          return function (element) {
            return element.id !== treatId;
          }
        };
        let center = centers[centerIndex];
        center.treats = center.treats.filter(isTreat(treat.id));
        centers[centerIndex] = center;
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: centers,
      };
    }
    case types.TREAT_CENTERS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    default:
      break;
  }
  return state;
}
