import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import HousingForm from "../../components/forms/HousingForm";
import LargePaper from "../../components/common/LargePaper";

export const HousingEditPage = props => {
  return (
    <LargePaper title="Éditer un POI Se loger">
      <HousingForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

HousingEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withAuthorization('Se loger')(HousingEditPage);
