import React from 'react';
import {Link} from "react-router-dom";
import Hidden from "@material-ui/core/Hidden/Hidden";
import Button from "@material-ui/core/Button/Button";
import Paper from "@material-ui/core/Paper/Paper";
import Typography from "@material-ui/core/Typography/Typography";
import background from "../resources/images/launch_background.svg";
import stylesDefault from "../constants/stylesDefault";
import SmallPaper from "../components/common/SmallPaper";
import logo from "../resources/images/logo_universo.svg";
import {HOMEPAGE} from "../constants/routes";

const HomeLink = () =>
  (
    <Button
      component={Link}
      to={HOMEPAGE}
      variant="contained"
      color="primary"
      type="button"
    >
      Revenir à l'accueil
    </Button>
  );

const Error404 = () => {
  const text = "Erreur 404";
  return (
    <div>
      <Hidden smDown>
        <Paper style={stylesDefault.background}>
          <img alt="background" src={background} style={stylesDefault.backgroundImage} />
          <img alt="logo" src={logo} style={stylesDefault.backgroundLogo} />
        </Paper>
      </Hidden>

      <Hidden smDown>
        <Paper style={stylesDefault.smallRightlayout}>
          <Typography
            style={stylesDefault.center}
            variant="h5"
            gutterBottom
          >
            {text}
          </Typography>
          <HomeLink />
        </Paper>
      </Hidden>

      <Hidden mdUp>
        <div style={stylesDefault.center}>
          <SmallPaper>
            <div>
              <div style={stylesDefault.smallLogoBlock}>
                <img alt="Universo" src={logo} style={stylesDefault.smallLogo} />
              </div>
              <Typography
                variant="h5"
                gutterBottom
              >
                {text}
              </Typography>
              <div style={stylesDefault.center}>
                <HomeLink />
              </div>
            </div>
          </SmallPaper>
        </div>
      </Hidden>
    </div>
  )
};

export default Error404;
