import API_ROOT from './api';

export const API_STATIC_DATAS         = API_ROOT + '/public/static_datas';

export const API_LOGIN                = API_ROOT + '/admin/login';
export const API_REFRESH              = API_ROOT + '/admin/refresh';
export const API_ADMINS               = API_ROOT + '/admin/admins';
export const API_ADMINS_ME            = API_ROOT + '/admin/admins/me';
export const API_ADMINS_ID            = API_ROOT + '/admin/admins/:adminId';
export const API_PASSWORD_LOST        = API_ROOT + '/public/password/lost';
export const API_PASSWORD_CHANGE      = API_ROOT + '/public/password/change';

export const API_MEDIA      = API_ROOT + '/app/medias';

export const API_SURVEYS                   = API_ROOT + '/admin/surveys';
export const API_SURVEYS_ID                = API_ROOT + '/admin/surveys/:surveyId';

export const API_TRANSPORTS                = API_ROOT + '/admin/transports';
export const API_TRANSPORTS_ID             = API_ROOT + '/admin/transports/:transportId';

export const API_LOCATIONS                 = API_ROOT + '/admin/locations';
export const API_LOCATIONS_ID              = API_ROOT + '/admin/locations/:locationId';

export const API_HOUSINGS                  = API_ROOT + '/admin/housings';
export const API_HOUSINGS_ID               = API_ROOT + '/admin/housings/:housingId';

export const API_CENTERS                   = API_ROOT + '/admin/centers';
export const API_CENTERS_ID                = API_ROOT + '/admin/centers/:centerId';

export const API_ADMIN_TRAININGS             = API_ROOT + '/admin/trainings';
export const API_TRAININGS                 = API_ROOT + '/admin/centers/:centerId/trainings';
export const API_TRAININGS_ID              = API_ROOT + '/admin/centers/:centerId/trainings/:trainingId';

export const API_ADMIN_TREATS                = API_ROOT + '/admin/treats';
export const API_TREATS                    = API_ROOT + '/admin/centers/:centerId/treats';
export const API_TREATS_ID                 = API_ROOT + '/admin/centers/:centerId/treats/:treatId';

export const API_ADMIN_CITIZENSHIPS                = API_ROOT + '/admin/citizenships';
export const API_CITIZENSHIPS                    = API_ROOT + '/admin/centers/:centerId/citizenships';
export const API_CITIZENSHIPS_ID                 = API_ROOT + '/admin/centers/:centerId/citizenships/:citizenshipId';

export const API_JOBS                = API_ROOT + '/admin/jobs';
export const API_JOBS_ID             = API_ROOT + '/admin/jobs/:jobId';

export const API_ARTISTS                   = API_ROOT + '/admin/artists';
export const API_ARTISTS_ID                = API_ROOT + '/admin/artists/:artistId';
export const API_RESOURCES               = API_ROOT + '/admin/artists/:artistId/resources';
export const API_RESOURCES_ID              = API_ROOT + '/admin/artists/:artistId/resources/:resourcesId';

export const API_SIGNALS                = API_ROOT + '/admin/suggestions';
export const API_SIGNALS_ID             = API_ROOT + '/admin/suggestions/:signalId';

export const API_USERS                = API_ROOT + '/admin/users';

export const API_FREE_PUSH               = API_ROOT + '/admin/freepush';

export const API_NB_MODERATED         = API_ROOT + '/admin/nb_moderated';
