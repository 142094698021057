import React from 'react';
import PropTypes from "prop-types";
import {VERDUN_DARK_BLUE} from '../../constants/colors';

const roundSize = '18px';

const spinnerStyle = {
  parent: {
    margin: '2em auto',
    width: 'auto',
    textAlign: 'center',
  },
  parent_no_margin: {
    margin: 'auto',
    width: 'auto',
    height: '100%',
    textAlign: 'center',
  },
  child: {
    width: roundSize,
    height: roundSize,
    backgroundColor: VERDUN_DARK_BLUE,
    margin: '0.5em 0.5em 0',
    borderRadius: '100%',
    display: 'inline-block',
  },
};

const Spinner = (props) => {
  return (
    <div
      className="spinner"
      style={
        props.variant === 'nomargin' ? spinnerStyle.parent_no_margin : spinnerStyle.parent
      }
    >
      <div className="bounce1" style={spinnerStyle.child} />
      <div className="bounce2" style={spinnerStyle.child} />
      <div className="bounce3" style={spinnerStyle.child} />
    </div>);
};


Spinner.propTypes = {
  variant: PropTypes.string,
};


Spinner.defaultProps = {
  variant: 'normal'
};

export default Spinner;
