import { db } from './firebase';

export const getUsers = () =>
  db.collection('users').get();

export const removeUser = userId => {
  db.collection('users').doc(userId).delete();
  db.collection('roles').doc(userId).delete();
  return true;
};

export const getRoles = () =>
  db.collection('roles').get();

export const getRole = userId =>
  db.collection('roles').doc(userId).get();

export const doRoleUpdate = (userId, role) =>
  db.ref('roles').child(userId).set(role);

export const doProfileUpdate = (userId, user) => {
  db.collection('users').doc(userId).set({
    mail: user.mail,
    firstname: user.firstname,
    lastname: user.lastname
  });
  db.collection('roles').doc(userId).set({
    role: parseInt(user.role)
  });
  return true;
};
