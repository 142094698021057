export const ROLES = {
  user: [
    "ROLE_USER", "ROLE_MODERATOR", "ROLE_ADMIN", "ROLE_SUPER_ADMIN"
  ],
  moderator: [
    "ROLE_MODERATOR", "ROLE_ADMIN", "ROLE_SUPER_ADMIN"
  ],
  admin: [
    "ROLE_ADMIN", "ROLE_SUPER_ADMIN"
  ],
  super_admin: [
    "ROLE_SUPER_ADMIN"
  ]
};

export const ROLE_USER = 0;
export const ROLE_CONTRIBUTOR = 4;
export const ROLE_MODERATOR = 5;
export const ROLE_ADMIN = 10;
export const ROLE_SUPER_ADMIN = 15;
