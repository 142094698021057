import React, {Component} from 'react';
import {compose} from "recompose";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {handleError} from "../../stores/infos/actions";
import LargePaper from "../../components/common/LargePaper";
import withAuthorization from "../../hocs/withAuthorization";
import Spinner from "../../components/common/Spinner";
import {TREAT_CENTER_EDIT, TREAT_CENTER_ADD} from "../../constants/routes";
import CustomTable from "../../components/common/table/CustomTable";
import AuthService from "../../services/AuthService";
import {ROLE_ADMIN} from "../../constants/roles";
import {deleteTreatCenter, getTreatCenters} from "../../stores/treats/actions";

const INITIAL_STATE = {
  datas: []
};

export class TreatCentersPage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    getTreatCenters: PropTypes.func.isRequired,
    deleteTreatCenter: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    authAdmin: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (props.authUser && !props.treats && !props.pending) {
      props.getTreatCenters(props.authUser.ra);
    }
    if (props.treats) {
      if (state.datas === INITIAL_STATE.datas) {
        newState = {...newState, datas: props.treats};
      }
      if (props.treats !== state.datas && state.datas !== INITIAL_STATE.datas) {
        newState = {...newState, datas: props.treats}
      }
    }
    return newState;
  }

  handleAdd = () => {
    this.props.history.push(TREAT_CENTER_ADD);
  };

  handleEdit = element => {
    this.props.history.push(TREAT_CENTER_EDIT.replace(':centerId', element.id));
  };

  handleDelete = element => {
    this.props.deleteTreatCenter(this.props.authUser.ra, element)
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  render() {

    const columnData = [
      {id: 'name', label: 'Nom', align: "left", disablePadding: false},
      {id: 'category', label: 'Catégorie', align: "left", disablePadding: false},
      {id: 'city', label: 'Ville', align: "left", disablePadding: false},
      {id: 'moderated', label: 'Modéré', boolean: true, align: "center", disablePadding: false}
    ];
    return (
      <LargePaper title="Gestion des thématiques">
        <div>
          {this.props.pending &&
          <Spinner />
          }
          {!this.props.pending && (
            <CustomTable
              datas={this.state.datas}
              onAdd={this.handleAdd}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
              buttonLabel='Ajouter une thématique'
              paginationLabel='thématique par page'
              emptyDataMessage='Aucune thématique'
              deleteDialogText='Êtes-vous sûr de vouloir supprimer cette thématique ?'
              columnData={columnData}
              sending={this.props.sending}
            />
          )}
        </div>
      </LargePaper>
    );
  }
}


function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    treats: state.treats.content,
    pending: state.treats.pending,
    sending: state.treats.sending,
    error: state.treats.error,
  };
}

export default compose(
  withAuthorization('Se soigner'),
  connect(mapStateToProps, {getTreatCenters, deleteTreatCenter, handleError})
)(TreatCentersPage);
