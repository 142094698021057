import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Image from "@material-ui/icons/Image";
import Dropzone from "react-dropzone";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import defaultStyles from "../../../constants/stylesDefault";

const styles = () => ({
  dropzone: {
    maxHeight: '6.5em',
    width: 'auto',
    textAlign: 'center',
    padding: 'auto'

  },
  cardMedia: {
    maxHeight: '5em',
    width: 'auto',
    margin: 'auto'
  }
});

export class CustomPictureDropzone extends Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    gridMD: PropTypes.number.isRequired,
    labelTitle: PropTypes.string,
    labelAddImage: PropTypes.string,
    labelDropImage: PropTypes.string,
    imagePath: PropTypes.string,
    onDrop: PropTypes.func.isRequired
  };

  static defaultProps = {
    imagePath: null,
    labelTitle: "Image",
    labelAddImage: "Ajouter une image",
    labelDropImage: "Glissez votre image dans cette zone."
  };

  static supportedDropFiles = [
    'image/bmp',
    'image/x-ms-bmp',
    'image/png',
    'image/x-icon',
    'image/vnd.microsoft.icon',
    'image/jpeg',
    'image/gif',
    'image/svg+xml',
  ].toString();

  // Handler

  handleAddMedia = () => {
    this.dropzoneRef.open();
  };

  render() {

    const {gridMD, labelTitle, labelAddImage, labelDropImage,
      imagePath, onDrop, classes} = this.props;

    const pictureDropzone = (
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {labelTitle}
          </Typography>
        </CardContent>
        <Dropzone
          style={defaultStyles.media}
          className={classes.dropzone}
          ref={(node) => {
            this.dropzoneRef = node
          }}
          accept={CustomPictureDropzone.supportedDropFiles}
          onDrop={onDrop}
        >
          {({getRootProps, getInputProps}) => (
            imagePath ?
            (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <CardMedia
                  component="img"
                  image={imagePath}
                  title="Image"
                  className={classes.cardMedia}
                  />
              </div>
            ) : (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Typography variant="subtitle1">
                    {labelDropImage}
                  </Typography>
                  <br />
                  <Image />
              </div>
            )
          )}
        </Dropzone>
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={this.handleAddMedia}
          >
            {labelAddImage}
          </Button>
        </CardActions>
      </Card>
    );
    return (
      <Grid item xs={12} sm={12} md={gridMD}>
        {pictureDropzone}
      </Grid>
    );
  }
}

export default withStyles(styles)(CustomPictureDropzone);
