import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "recompose";

import {postHousing, putHousing, getHousings, postHousingPicture} from "../../stores/housings/actions";
import {getNbModerated, getStaticDatas, handleError} from "../../stores/infos/actions";
import {HOUSINGS} from "../../constants/routes";
import Spinner from "../common/Spinner";

import CustomForm from "../common/form/CustomForm";
import StringService from "../../services/StringService";
import {ROLE_MODERATOR} from "../../constants/roles";
import AuthService from "../../services/AuthService";

// Intial State of the Component
const INITIAL_STATE = {
  id:'',
  title:'',
  content:'',
  category:'',
  address:'',
  latitude: 49.160361,
  longitude: 5.384081,
  city: '',
  mail: '',
  price: 0,
  contact: '',
  phone: '',
  type: '',
  availability: StringService.dateTimestampToString(new Date() / 1000),
  area: 0,
  options: '',
  furnished: false,
  room: 1,
  link:'',
  imagePath: '',
  pictureToUpload: null,
  sending: false,
  cities: {},
  categories: {},
  types: {}
};

const stateWithHousing = (housing, media) => ({
  id: housing.id,
  title: housing.title,
  content: housing.content,
  category: housing.category,
  address: housing.address,
  latitude: housing.latitude,
  longitude: housing.longitude,
  city: housing.city,
  mail: housing.mail,
  price: housing.price,
  contact: housing.contact,
  phone: housing.phone,
  type: housing.type,
  availability:  StringService.dateTimestampToString(housing.availablility || new Date() / 1000),
  area: housing.area,
  options: housing.options,
  furnished: housing.furnished,
  room: housing.room,
  link: housing.link,
  imagePath: media ? media.image_path : ''
});

export class HousingForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    pending: PropTypes.bool.isRequired,
    sending: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    housings: PropTypes.array,
    getStaticDatas: PropTypes.func.isRequired,
    postHousing: PropTypes.func.isRequired,
    putHousing: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired
  };

  static defaultProps = {
    housings: null
  };

  static getDerivedStateFromProps(props, state) {
    let newState = {...state};

    if (!props.roles && !props.pending) {
      props.getRoles();
    }
    if (!props.housings && !props.pending && props.token && props.match && props.match.params.housingId) {
      props.getHousings(props.token);
    }
    if (!props.cities || !props.types || !props.categories) {
      props.getStaticDatas();
    }
    if (props.cities) {
      newState = {...newState, cities: props.cities};
    }
    if (props.types) {
      newState = {...newState, types: props.types};
    }
    if (props.categories) {
      newState = {...newState, categories: props.categories};
    }

    if (props.housings && props.match && props.match.params.housingId && state.id === INITIAL_STATE.id) {
      const housings = props.housings;
      const currentHousingId = props.match.params.housingId;
      const foundHousing = housings.find(housing => housing.id === currentHousingId);

      if (foundHousing) {
        const media = foundHousing.medias ?
          foundHousing.medias.find(media => media.position === 0) : null;
        newState = {...newState, ...stateWithHousing(foundHousing, media)};
      }
    }

    if (props.sending && !state.sending) { // Start sending
      return {sending: true};
    }
    if (!props.sending && state.sending) { // End sending
      if (state.pictureToUpload && state.id) { // picture To Upload after deal sent
        props.postHousingPicture(props.token, state.id, state.pictureToUpload, (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(state.pictureToUpload.name + ' -> '
            + percentCompleted + '% (' + progressEvent.loaded + ' / ' + progressEvent.total + ')');
        });
        return {
          pictureToUpload: null,
          sending: false
        };
      } else if (!state.pictureToUpload) {
        props.history.push(HOUSINGS);
      }
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  onSubmit = (event, formState) => {

    // Retrieve ImageToUpload if available
    if (formState.pictureToUpload) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        pictureToUpload: formState.pictureToUpload
      });
    }
    // HandleDate
    formState = {
      ...formState,
      availability: StringService.dateStringToTimestamp(formState.availability)
    };

    if (this.state.id === INITIAL_STATE.id) {
      // Set order when posting
      formState = {...formState, order: this.props.housings.length};
      this.props.postHousing(
        this.props.token,
        formState
      )
        .then((housingId => {
          if (this.state.pictureToUpload) {
            this.setState({id: housingId});
          }
        }))
        .catch((error) => {
          this.props.handleError(error);
        });
    } else {
      this.props.putHousing(
        this.props.token,
        this.state.id,
        formState
      )
        .then(housingId => {
          this.props.getNbModerated(this.props.token);
          if (this.state.pictureToUpload) {
            this.setState({id: housingId});
          }
        })
        .catch((error) => {
          this.props.handleError(error);
        });
    }
    event.preventDefault();
  };

  render() {

    const {pending, sending} = this.props;

    const {id, cities, categories, types} = this.state;

    const buttonLabel = sending ?
      (<Spinner variant="nomargin" />)
      : id === ''  ? 'Créer' : 'Éditer';

    const load = (<Spinner />);

    let formFields = [
      {id: 'title', type:'textField', label: 'Titre', isMandatory:true},
      {id: 'imagePath', picture: 'pictureToUpload', type: 'pictureDropzone', isMandatory: false},
      {id: 'content', type:'richText', label: 'Description', isMandatory:true},
      {id: 'category', type:'selectField', gridMD:6, options:categories, label: 'Catégorie', isMandatory:true},
      {id: 'city', type:'selectField', gridMD:6, label: 'Ville', options:cities, isMandatory:true},
      {id: 'address', type:'textField', label: 'Adresse', isMandatory:true},
      {id: 'latitude', type:'textField', subtype:'number', gridMD:6, label: 'Latitude', isMandatory:true},
      {id: 'longitude', type:'textField', subtype:'number', gridMD:6, label: 'Longitude', isMandatory:true},
      {id: 'mail', type:'textField', gridMD:6, label: 'Mail', isMandatory:true},
      {id: 'contact', type:'textField', gridMD:6, label: 'Contact', isMandatory:true},
      {id: 'phone', type:'textField', gridMD:6, label: 'Téléphone', isMandatory:true},
      {id: 'price', type:'textField', subtype:'number', gridMD:6, label: 'Prix (en euros par mois)', isMandatory:true},
      {id: 'type', type:'selectField', gridMD:6, options:types, label: 'Type de bien', isMandatory:true},
      {id: 'availability', type:'textField', subtype: 'date', gridMD:6, label: 'Date de disponibilité', isMandatory:true},
      {id: 'area', type:'textField', subtype:'number', gridMD:6, label: 'Superficie (en m2)', isMandatory:true},
      {id: 'options', type:'textField', label: 'Options', isMandatory:false},
      {id: 'furnished', type:'checkboxField', gridMD:3, label: 'Meublé', isMandatory:true},
      {id: 'room', type:'textField', subtype:'number', gridMD:3, label: 'Nombre de pièces', isMandatory:true},
      {id: 'link', type:'textField', gridMD:6, label: 'Lien', isMandatory:true},
    ];

    if (AuthService.isGranted(ROLE_MODERATOR, this.props.authUser, this.props.roles)) {
      formFields.push({id: 'push', type:'checkboxField', label: 'Notifier les utilisateurs', isMandatory:true});
    }

    const customForm = (
      <CustomForm
        data={this.state}
        formFields={formFields}
        buttonLabel={buttonLabel}
        onSubmit={this.onSubmit}
        sending={sending}
      />
    );

    if (pending) {
      return (load);
    } else {
      return (customForm);
    }
  }
}

function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    roles: state.roles.content,
    token: state.users.authUser.ra,
    cities: state.infos.communes,
    types: state.infos.housings.types,
    categories: state.infos.housings.categories,
    housings: state.housings.content,
    pending: state.housings.pending || state.users.pending,
    sending: state.housings.sending
  };
}

export default compose(
  connect(mapStateToProps, {
    getHousings, postHousing, postHousingPicture, putHousing, handleError, getStaticDatas, getNbModerated
  }),
)(HousingForm);
