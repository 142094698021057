import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import SignalForm from "../../components/forms/SignalForm";
import LargePaper from "../../components/common/LargePaper";

export const JobEditPage = props => {
  return (
    <LargePaper title="Éditer un signalement">
      <SignalForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

JobEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withAuthorization('Signalements')(JobEditPage);
