import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

// --------------------------------
// Action creators

export const housingsPendingAction = () => ({
  type: types.HOUSINGS_PENDING,
});
export const housingSendingAction = () => ({
  type: types.HOUSING_SENDING,
});
export const housingsResetAction = () => ({
  type: types.HOUSINGS_RESET,
});

export const housingsDownloadedAction = housings => ({
  type: types.HOUSINGS_DOWNLOADED,
  payload: housings,
});

export const housingDownloadedAction = (housing) => ({
  type: types.HOUSING_DOWNLOADED,
  payload: housing
});

export const housingRemovedAction = (housing) => ({
  type: types.HOUSING_REMOVED,
  payload: housing,
});

export const housingsFailedAction = error => ({
  type: types.HOUSINGS_FAILED,
  payload: error,
});


// --------------------------------
// Actions

export function resetHousings() {
  return (dispatch) => {
    dispatch(housingsResetAction());
  };
}

export function getHousings(token) {
  return (dispatch) => {
    dispatch(housingsPendingAction());
    return ApiService
      .getHousings(token)
      .then((data) => {
        dispatch(housingsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(housingsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postHousing(token, housing) {
  return (dispatch) => {
    dispatch(housingSendingAction());

    return ApiService
      .postHousing(token, housing)
      .then((data) => {
        dispatch(housingDownloadedAction(data));
        return data.id
      })
      .catch((error) => {
        dispatch(housingsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postHousingPicture(token, housingId, picture, onUploadProgress = null) {
  return (dispatch) => {
    dispatch(housingSendingAction());
    return ApiService
      .postPicture(token, {entity_id: housingId, entity_name: "housing", position: 0}, picture, onUploadProgress)
      .then((result) => {
        dispatch(housingDownloadedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(housingsFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

export function putHousing(token, housingId, housing) {
  return (dispatch) => {
    dispatch(housingSendingAction());
    return ApiService
      .putHousing(token, housingId, housing)
      .then((data) => {
        const updatedHousing = {
          ...data,
          id: housingId,
        };
        dispatch(housingDownloadedAction(updatedHousing));
        return data.id
      })
      .catch((error) => {
        dispatch(housingsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function deleteHousing(token, housing) {
  return (dispatch) => {
    dispatch(housingSendingAction());
    return ApiService
      .deleteHousing(token, housing.id)
      .then(() => {
        dispatch(housingRemovedAction(housing));
      })
      .catch((error) => {
        dispatch(housingsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
