import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import SurveyForm from "../../components/forms/SurveyForm";
import LargePaper from "../../components/common/LargePaper";

export const SurveyEditPage = props => {
  return (
    <LargePaper title="Éditer un sondage">
      <SurveyForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

SurveyEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withAuthorization('Sondage')(SurveyEditPage);
