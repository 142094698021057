import * as types from './types';
import ApiService from "../../services/ApiService";

// Actions creator

export const staticDatasPendingAction = () => ({
  type: types.INFO_STATIC_DATAS_PENDING,
});

export const staticDatasFailedAction = error => ({
  type: types.INFO_STATIC_DATAS_FAILED,
  payload: error,
});

export const staticDatasDownloadedAction = datas => ({
  type: types.INFO_STATIC_DATAS_DOWNLOADED,
  payload: datas,
});

export const nbModeratedPendingAction = () => ({
  type: types.NB_MODERATED_PENDING,
});

export const nbModeratedDownloadedAction = datas => ({
  type: types.NB_MODERATED_DOWNLOADED,
  payload: datas,
});

export function resetInfoAction() {
  return {type: types.INFO_RESET};
}

export function genericInfoAction(info) {
  return {
    type: types.INFO,
    payload: info
  };
}

export function resetErrorAction() {
  return {type: types.ERROR_RESET};
}

export function genericErrorAction(error) {
  return {
    type: types.ERROR,
    payload: error
  };
}

// Actions

export function getStaticDatas() {
  return (dispatch) => {
    dispatch(staticDatasPendingAction());
    return ApiService
      .getStaticDatas()
      .then((data) => {
        dispatch(staticDatasDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(staticDatasFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getNbModerated(token) {
  return (dispatch) => {
    dispatch(nbModeratedPendingAction());
    return ApiService
      .getNbModerated(token)
      .then((data) => {
        dispatch(nbModeratedDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(handleError(error));
      });
  };
}

export function resetError() {
  return (dispatch) => {
    dispatch(resetErrorAction());
  }
}

export function resetInfo() {
  return (dispatch) => {
    dispatch(resetInfoAction());
  }
}

export function dispatchInfo(info) {
  return (dispatch) => {
    dispatch(genericInfoAction(info));
  }
}

export function handleError(error, type = null) {
  return (dispatch) => {
    let event = {
      type: type,
      payload: 'Impossible de réaliser cette action, veuillez réessayer ultérieurement.'
    };
    if (error) {
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          event.payload = 'Vous n\'êtes pas autorisé à effectuer cette action.';
        } else if (error.response.status === 404) {
          event.payload = 'Contenu introuvable.';
        } else if (error.response.statusText) {
          event.payload = error.response.statusText;
        } else {
          if (error.response.data && error.response.data.error) {
            event.payload = error.response.data.error;
          } else if (error.response.data) {
            event.payload = error.response.data;
          }
        }
      } else if (error.message) {
        event.payload = error.message;
      }
    }

    // Dispatch real event error
    if (type) {
      dispatch(event);
    }

    // Dispatch generic error
    dispatch(genericErrorAction(event.payload));
  }
}
