export default class c {
  static dateTimestampToString = (timestamp) => {
    const newDate = new Date(timestamp * 1000);
    const mm = newDate.getMonth() + 1; // getMonth() is zero-based
    const dd = newDate.getDate();
    return newDate.getFullYear()+'-'+(mm>9 ? '' : '0') + mm+'-'+(dd>9 ? '' : '0') + dd;
  };

  static timeTimestampToString = (timestamp) => {
    const newDate = new Date(timestamp * 1000);
    const hh = newDate.getHours();
    const mm = newDate.getMinutes();
    return (hh>9 ? '' : '0')+hh+':'+(mm>9 ? '' : '0')+mm;
  };

  static dateStringToTimestamp = (date) => {
    const splitDate=date.split("-");
    return Math.floor(new Date(splitDate).getTime() / 1000)
  };

  static dateTimeStringToTimestamp = (date) => {
    const split=date.split("T");
    const splitDate=split[0].split("-");
    splitDate[1] --;
    const splitTime=split[1].split(":");
    return Math.floor(new Date(...splitDate.concat(splitTime)).getTime() / 1000)
  };
}
