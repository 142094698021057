import {
  ROLES_DOWNLOADED,
  ROLE_DOWNLOADED,
  ROLES_FAILED,
  ROLES_PENDING,
  ROLES_RESET,
  ROLE_SENDING } from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ROLES_RESET:
      return INITIAL_STATE;
    case ROLES_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case ROLES_DOWNLOADED:
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    case ROLE_DOWNLOADED: {
      const role = action.payload;
      const roles = state.content;
      roles[action.userId] = role;
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: roles,
      };
    }
    case ROLES_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case ROLE_SENDING:
      return {
        ...state,
        sending: true,
      };
    default:
      break;
  }
  return state;
}
