import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

// --------------------------------
// Action creators

export const locationsPendingAction = () => ({
  type: types.LOCATIONS_PENDING,
});
export const locationSendingAction = () => ({
  type: types.LOCATION_SENDING,
});
export const locationsResetAction = () => ({
  type: types.LOCATIONS_RESET,
});

export const locationsDownloadedAction = locations => ({
  type: types.LOCATIONS_DOWNLOADED,
  payload: locations,
});

export const locationDownloadedAction = (location) => ({
  type: types.LOCATION_DOWNLOADED,
  payload: location
});

export const locationRemovedAction = (location) => ({
  type: types.LOCATION_REMOVED,
  payload: location,
});

export const locationsFailedAction = error => ({
  type: types.LOCATIONS_FAILED,
  payload: error,
});


// --------------------------------
// Actions

export function resetLocations() {
  return (dispatch) => {
    dispatch(locationsResetAction());
  };
}

export function getLocations(token) {
  return (dispatch) => {
    dispatch(locationsPendingAction());
    return ApiService
      .getLocations(token)
      .then((data) => {
        dispatch(locationsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(locationsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postLocation(token, location) {
  return (dispatch) => {
    dispatch(locationSendingAction());

    return ApiService
      .postLocation(token, location)
      .then((data) => {
        dispatch(locationDownloadedAction(data));
        return data.id
      })
      .catch((error) => {
        dispatch(locationsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postLocationPicture(token, locationId, picture, onUploadProgress = null) {
  return (dispatch) => {
    dispatch(locationSendingAction());
    return ApiService
      .postPicture(token, {entity_id: locationId, entity_name: "location", position: 0}, picture, onUploadProgress)
      .then((result) => {
        dispatch(locationDownloadedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(locationsFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

export function putLocation(token, locationId, location) {
  return (dispatch) => {
    dispatch(locationSendingAction());
    return ApiService
      .putLocation(token, locationId, location)
      .then((data) => {
        const updatedLocation = {
          ...data,
          id: locationId,
        };
        dispatch(locationDownloadedAction(updatedLocation));
        return data.id
      })
      .catch((error) => {
        dispatch(locationsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function deleteLocation(token, location) {
  return (dispatch) => {
    dispatch(locationSendingAction());
    return ApiService
      .deleteLocation(token, location.id)
      .then(() => {
        dispatch(locationRemovedAction(location));
      })
      .catch((error) => {
        dispatch(locationsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
