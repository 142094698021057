import React, {Component} from 'react';
import {compose} from "recompose";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {handleError} from "../../stores/infos/actions";
import LargePaper from "../../components/common/LargePaper";
import withAuthorization from "../../hocs/withAuthorization";
import Spinner from "../../components/common/Spinner";
import {SIGNAL_EDIT} from "../../constants/routes";
import CustomTable from "../../components/common/table/CustomTable";
import AuthService from "../../services/AuthService";
import {ROLE_ADMIN} from "../../constants/roles";
import {deleteSignal, getSignals, putSignal} from "../../stores/signals/actions";

const INITIAL_STATE = {
  datas: []
};

export class SignalsPage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    getSignals: PropTypes.func.isRequired,
    putSignal: PropTypes.func.isRequired,
    deleteSignal: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    authUser: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (props.authUser && !props.signals && !props.pending) {
      props.getSignals(props.authUser.ra);
    }
    if (props.signals) {
      if (state.datas === INITIAL_STATE.datas) {
        newState = {...newState, datas: props.signals};
      }
      if (props.signals !== state.datas && state.datas !== INITIAL_STATE.datas) {
        newState = {...newState, datas: props.signals}
      }
    }
    return newState;
  }

  handleEdit = element => {
    this.props.history.push(SIGNAL_EDIT.replace(':signalId', element.id));
  };

  handleDelete = element => {
    this.props.deleteSignal(this.props.authUser.ra, element)
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  render() {

    const columnData = [
      {id: 'title', label: 'Titre', align: "left", disablePadding: false},
      {id: 'content', label: 'Message', align: "left", disablePadding: false},
      {id: 'city', label: 'Ville', align: "left", disablePadding: false},
      {id: 'moderated', label: 'Modéré', boolean: true, align: "center", disablePadding: false}
    ];
    return (
      <LargePaper title="Gestion des signalements">
        <div>
          {this.props.pending &&
          <Spinner />
          }
          {!this.props.pending && (
            <CustomTable
              datas={this.state.datas}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
              paginationLabel='Signalement par page'
              emptyDataMessage='Aucun signalement'
              deleteDialogText='Êtes-vous sûr de vouloir supprimer ce signalement ?'
              columnData={columnData}
              sending={this.props.sending}
              showAdd={false}
            />
          )}
        </div>
      </LargePaper>
    );
  }
}


function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    roles: state.roles.content,
    signals: state.signals.content,
    pending: state.signals.pending,
    sending: state.signals.sending,
    error: state.signals.error,
  };
}

export default compose(
  withAuthorization('Signalements'),
  connect(mapStateToProps, {getSignals, deleteSignal, putSignal, handleError})
)(SignalsPage);
