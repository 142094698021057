import React from 'react';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {withStyles} from "@material-ui/core/styles/index";

const styles = {
  grid: {
    padding: '1.5em!important'
  }
};

/**
 * Class CustomCheckboxField
 *
 * @Component : <CustomCheckboxField />
 */
const CustomCheckboxField = props => {
  // Constant
  const {
    onChange,
    value,
    gridMD,
    label,
    disabled
  } = props;

  // Element
  const checkbox = (
    <FormControlLabel
      control={
        (
          <Checkbox
            checked={value}
            onChange={onChange}
            color="primary"
            disabled={disabled}
          />
        )
      }
      label={label}
    />
  );

  return (
    <Grid item xs={12} sm={12} md={gridMD} className={props.classes.grid}>
      {checkbox}
    </Grid>
  );
};

CustomCheckboxField.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  gridMD: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

CustomCheckboxField.defaultProps = {
  value: false,
  disabled: false
};

export default withStyles(styles) (CustomCheckboxField);
