import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import CitizenshipForm from "../../components/forms/CitizenshipForm";
import LargePaper from "../../components/common/LargePaper";

export const CitizenAddPage = props => {
  return (
    <LargePaper title="Ajouter un article">
      <CitizenshipForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

CitizenAddPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withAuthorization('Citoyenneté')(CitizenAddPage);
