import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "recompose";

import {postLocation, putLocation, getLocations, postLocationPicture} from "../../stores/locations/actions";
import {getNbModerated, getStaticDatas, handleError} from "../../stores/infos/actions";
import {LOCATIONS} from "../../constants/routes";
import Spinner from "../common/Spinner";

import CustomForm from "../common/form/CustomForm";
import {ROLE_MODERATOR} from "../../constants/roles";
import AuthService from "../../services/AuthService";

// Intial State of the Component
const INITIAL_STATE = {
  id:'',
  title:'',
  content:'',
  category:'',
  address:'',
  latitude: 49.160361,
  longitude: 5.384081,
  city:'',
  schedule: '',
  link: '',
  phone: '',
  imagePath: '',
  pictureToUpload: null,
  sending: false,
  cities: {},
  categories: {}
};

const stateWithLocation = (location, media) => ({
  id: location.id,
  title: location.title,
  content: location.content,
  category: location.category,
  address: location.address,
  latitude: location.latitude,
  longitude: location.longitude,
  city: location.city,
  schedule: location.schedule,
  link: location.link,
  phone: location.phone,
  imagePath: media ? media.image_path : ''
});

export class LocationForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    pending: PropTypes.bool.isRequired,
    sending: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    locations: PropTypes.array,
    getStaticDatas: PropTypes.func.isRequired,
    postLocation: PropTypes.func.isRequired,
    putLocation: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired
  };

  static defaultProps = {
    locations: null
  };

  static getDerivedStateFromProps(props, state) {
    let newState = {...state};

    if (!props.roles && !props.pending) {
      props.getRoles();
    }
    if (!props.locations && !props.pending && props.token && props.match && props.match.params.locationId) {
      props.getLocations(props.token);
    }
    if (props.locations && props.match && props.match.params.locationId && state.id === INITIAL_STATE.id) {
      const locations = props.locations;
      const currentLocationId = props.match.params.locationId;
      const foundLocation = locations.find(location => location.id === currentLocationId);

      if (foundLocation) {
        const media = foundLocation.medias ?
          foundLocation.medias.find(media => media.position === 0) : null;
        newState = {...newState, ...stateWithLocation(foundLocation, media)};
      }
    }

    if (!props.cities || !props.categories) {
      props.getStaticDatas();
    }
    if (props.cities) {
      newState = {...newState, cities: props.cities};
    }
    if (props.categories) {
      newState = {...newState, categories: props.categories};
    }

    if (props.sending && !state.sending) { // Start sending
      return {sending: true};
    }
    if (!props.sending && state.sending) { // End sending
      if (state.pictureToUpload && state.id) { // picture To Upload after deal sent
        props.postLocationPicture(props.token, state.id, state.pictureToUpload, (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(state.pictureToUpload.name + ' -> '
            + percentCompleted + '% (' + progressEvent.loaded + ' / ' + progressEvent.total + ')');
        });
        return {
          pictureToUpload: null,
          sending: false
        };
      } else if (!state.pictureToUpload) {
        props.history.push(LOCATIONS);
      }
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  onSubmit = (event, formState) => {

    // Retrieve ImageToUpload if available
    if (formState.pictureToUpload) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        pictureToUpload: formState.pictureToUpload
      });
    }

    if (this.state.id === INITIAL_STATE.id) {
      // Set order when posting
      formState = {...formState, order: this.props.locations.length};
      this.props.postLocation(
        this.props.token,
        formState
      )
        .then((locationId => {
          if (this.state.pictureToUpload) {
            this.setState({id: locationId});
          }
        }))
        .catch((error) => {
          this.props.handleError(error);
        });
    } else {
      this.props.putLocation(
        this.props.token,
        this.state.id,
        formState
      )
        .then(locationId => {
          this.props.getNbModerated(this.props.token);
          if (this.state.pictureToUpload) {
            this.setState({id: locationId});
          }
        })
        .catch((error) => {
          this.props.handleError(error);
        });
    }
    event.preventDefault();
  };

  render() {

    const {pending, sending} = this.props;

    const {id, cities, categories} = this.state;

    const buttonLabel = sending ?
      (<Spinner variant="nomargin" />)
      : id === ''  ? 'Créer' : 'Éditer';

    const load = (<Spinner />);

    let formFields = [
      {id: 'title', type:'textField', label: 'Titre', isMandatory:true},
      {id: 'imagePath', picture: 'pictureToUpload', type: 'pictureDropzone', isMandatory: false},
      {id: 'content', type:'richText', gridMD:12, label: 'Description', isMandatory:true},
      {id: 'schedule', type:'textField', gridMD:12, label: 'Horaires', isMandatory:false},
      {id: 'category', type:'selectField', gridMD:6, options: categories, label: 'Catégorie', isMandatory:true},
      {id: 'city', type:'selectField', gridMD:6, options: cities, label: 'Ville', isMandatory:true},
      {id: 'address', type:'textField', label: 'Adresse', isMandatory:true},
      {id: 'phone', type:'textField', gridMD:6, label: 'Téléphone', isMandatory:true},
      {id: 'link', type:'textField', gridMD:6, label: 'Lien', isMandatory:true},
      {id: 'latitude', type:'textField', subtype:'number', gridMD:6, label: 'Latitude', isMandatory:true},
      {id: 'longitude', type:'textField', subtype:'number',gridMD:6, label: 'Longitude', isMandatory:true},
    ];

    if (AuthService.isGranted(ROLE_MODERATOR, this.props.authUser, this.props.roles)) {
      formFields.push({id: 'push', type:'checkboxField', label: 'Notifier les utilisateurs', isMandatory:true});
    }

    const customForm = (
      <CustomForm
        data={this.state}
        formFields={formFields}
        buttonLabel={buttonLabel}
        onSubmit={this.onSubmit}
        sending={sending}
      />
    );

    if (pending) {
      return (load);
    } else {
      return (customForm);
    }
  }
}

function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    roles: state.roles.content,
    token: state.users.authUser.ra,
    cities: state.infos.communes,
    categories: state.infos.locations.categories,
    locations: state.locations.content,
    pending: state.locations.pending || state.users.pending,
    sending: state.locations.sending
  };
}

export default compose(
  connect(mapStateToProps, {
    getLocations, postLocation, postLocationPicture, putLocation, handleError, getStaticDatas,
    getNbModerated
  }),
)(LocationForm);
