import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.HOUSINGS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.HOUSING_SENDING:
      return {
        ...state,
        sending: true,
      };
    case types.HOUSINGS_RESET:
      return {
        ...INITIAL_STATE
      };
    case types.HOUSINGS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.HOUSING_DOWNLOADED: {
      const housingDownloaded = action.payload;
      let housings = [];
      let housingIndex = -1;
      if (state.content) {
        housings = state.content.slice();
        housingIndex = housings.findIndex(housing => housing.id === housingDownloaded.id);
      }
      if (housingIndex === -1) {
        housings.push(housingDownloaded);
      } else {
        housings[housingIndex] = housingDownloaded;
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: housings,
      };
    }
    case types.HOUSING_REMOVED: {
      const housing = action.payload;
      let RemovedHousingOrder = housing.order;
      let housings = state.content.slice();
      if (state.content !== null) {
        let isHousing = (housingId) => {
          return function (element) {
            return element.id !== housingId;
          }
        };
        housings = state.content.filter(isHousing(housing.id));
      }
      housings.forEach(function (element) {
        if (element.order > RemovedHousingOrder) {
          element.order = element.order - 1;
        }
      });
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: housings,
      };
    }
    case types.HOUSINGS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    default:
      break;
  }
  return state;
}
