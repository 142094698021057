import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from "prop-types";

export const CustomTablePagination = ({
                                        numberOfItems,
                                        rowsPerPage,
                                        label, page,
                                        onChangePage,
                                        onChangeRowsPerPage,
                                      }) =>
  (
    <div>
      <TablePagination
        component="div"
        count={numberOfItems}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
        labelRowsPerPage={label}
        page={page}
        backIconButtonProps={{
          'aria-label': `Page précédente`,
        }}
        nextIconButtonProps={{
          'aria-label': `Page suivante`,
        }}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </div>
  );

CustomTablePagination.propTypes = {
  numberOfItems: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired
};

export default CustomTablePagination;
