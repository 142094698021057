import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import SurveyForm from "../../components/forms/SurveyForm";
import LargePaper from "../../components/common/LargePaper";

export const SurveyAddPage = props => {
  return (
    <LargePaper title="Ajouter un sondage">
      <SurveyForm history={props.history} />
    </LargePaper>
  );
};

SurveyAddPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default withAuthorization('Sondage')(SurveyAddPage);
