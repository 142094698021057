import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import UserForm from "../../components/forms/UserForm";
import LargePaper from "../../components/common/LargePaper";

export const UserEditPage = props => {
  return (
    <LargePaper title="Éditer un utilisateur">
      <UserForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

UserEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withAuthorization('Utilisateur')(UserEditPage);
