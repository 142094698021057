import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.LOCATIONS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.LOCATION_SENDING:
      return {
        ...state,
        sending: true,
      };
    case types.LOCATIONS_RESET:
      return {
        ...INITIAL_STATE
      };
    case types.LOCATIONS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.LOCATION_DOWNLOADED: {
      const locationDownloaded = action.payload;
      let locations = [];
      let locationIndex = -1;
      if (state.content) {
        locations = state.content.slice();
        locationIndex = locations.findIndex(location => location.id === locationDownloaded.id);
      }
      if (locationIndex === -1) {
        locations.push(locationDownloaded);
      } else {
        locations[locationIndex] = locationDownloaded;
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: locations,
      };
    }
    case types.LOCATION_REMOVED: {
      const location = action.payload;
      let RemovedLocationOrder = location.order;
      let locations = state.content.slice();
      if (state.content !== null) {
        let isLocation = (locationId) => {
          return function (element) {
            return element.id !== locationId;
          }
        };
        locations = state.content.filter(isLocation(location.id));
      }
      locations.forEach(function (element) {
        if (element.order > RemovedLocationOrder) {
          element.order = element.order - 1;
        }
      });
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: locations,
      };
    }
    case types.LOCATIONS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    default:
      break;
  }
  return state;
}
