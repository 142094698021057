import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Typography from "@material-ui/core/Typography/Typography";

import stylesDefault from '../../constants/stylesDefault';

const styles = () => ({
  largeLayout: {
    overflow: 'hidden',
    padding: '1em',
    zIndex: 1
  },
  smallLayout: {
    maxWidth: '100%',
    overflowX: 'scroll'
  },
});

export const LargePaper = (props) => {

  return (props.children) ?
    (
      (props.width === 'xs' || props.width === 'sm') ?
        (
          <div>
            <Typography variant="h4" gutterBottom>
              {props.title}
            </Typography>
            <div style={stylesDefault.center}>
              <div className={props.classes.smallLayout}>
                {props.children}
              </div>
            </div>
          </div>
        )
        : (
          <Paper className={props.classes.largeLayout}>
            <Typography variant="h4" gutterBottom>
              {props.title}
            </Typography>
            <div style={stylesDefault.center}>
              {props.children}
            </div>
          </Paper>
        )
    )
    :
    null;
};

LargePaper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default compose(
  withStyles(styles),
  withWidth()
)(LargePaper);
