import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "recompose";

import {postTraining, putTraining, getTrainingCenters, postTrainingPicture} from "../../stores/trainings/actions";
import {getNbModerated, handleError} from "../../stores/infos/actions";
import {CENTER_EDIT} from "../../constants/routes";
import Spinner from "../common/Spinner";

import CustomForm from "../common/form/CustomForm";
import {ROLE_MODERATOR} from "../../constants/roles";
import AuthService from "../../services/AuthService";

// Intial State of the Component
const INITIAL_STATE = {
  centerId:'',
  id:'',
  title:'',
  description:'',
  category:'',
  link:'',
  imagePath: '',
  pictureToUpload: null,
  sending: false
};

const stateWithTraining = (training, media) => ({
  id: training.id,
  title: training.title,
  description: training.description,
  category: training.category,
  address: training.address,
  link: training.link,
  imagePath: media ? media.image_path : ''
});

export class TrainingForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    pending: PropTypes.bool.isRequired,
    sending: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    housings: PropTypes.array,
    postTraining: PropTypes.func.isRequired,
    putTraining: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired
  };

  static defaultProps = {
    housings: null
  };

  static getDerivedStateFromProps(props, state) {
    let newState = {...state};

    if (!props.roles && !props.pending) {
      props.getRoles();
    }
    if (!props.trainings && !props.pending && props.token
      && props.match && props.match.params.centerId) {
      props.getTrainingCenters(props.token);
    }
    if (props.trainings && props.match && props.match.params.centerId
      && state.id === INITIAL_STATE.id) {

      const trainings = props.trainings;
      const currentCenterId = props.match.params.centerId;
      const foundCenter = trainings.find(center => center.id === currentCenterId);

      newState = {...newState, trainings: foundCenter.trainings, centerId: currentCenterId};

      if (props.match.params.trainingId) {
        const foundTraining = foundCenter.trainings
          .find(training => training.id === props.match.params.trainingId);
        if (foundTraining) {
          const media = foundTraining.medias ?
            foundTraining.medias.find(media => media.position === 0) : null;
          newState = {...newState, ...stateWithTraining(foundTraining, media)};
        }
      }
    }

    if (props.sending && !state.sending) { // Start sending
      return {sending: true};
    }
    if (!props.sending && state.sending) { // End sending
      if (state.pictureToUpload && state.id) { // picture To Upload after deal sent
        props.postTrainingPicture(props.token, state.centerId, state.id, state.pictureToUpload, (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(state.pictureToUpload.name + ' -> '
            + percentCompleted + '% (' + progressEvent.loaded + ' / ' + progressEvent.total + ')');
        });
        return {
          pictureToUpload: null,
          sending: false
        };
      } else if (!state.pictureToUpload) {
        props.history.push(CENTER_EDIT.replace(':centerId', state.centerId));
      }
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  onSubmit = (event, formState) => {

    // Retrieve ImageToUpload if available
    if (formState.pictureToUpload) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        pictureToUpload: formState.pictureToUpload
      });
    }

    if (this.state.id === INITIAL_STATE.id) {
      this.props.postTraining(
        this.props.token,
        this.state.centerId,
        formState
      )
        .then((trainingId => {
          if (this.state.pictureToUpload) {
            this.setState({id: trainingId});
          }
        }))
        .catch((error) => {
          this.props.handleError(error);
        });
    } else {
      this.props.putTraining(
        this.props.token,
        this.state.centerId,
        this.state.id,
        formState
      )
        .then(trainingId => {
          this.props.getNbModerated(this.props.token);
          if (this.state.pictureToUpload) {
            this.setState({id: trainingId});
          }
        })
        .catch((error) => {
          this.props.handleError(error);
        });
    }
    event.preventDefault();
  };

  render() {

    const {pending, sending} = this.props;

    const {id} = this.state;

    const buttonLabel = sending ?
      (<Spinner variant="nomargin" />)
      : id === ''  ? 'Créer' : 'Éditer';

    const load = (<Spinner />);

    let formFields = [
      {id: 'title', type:'textField', label: 'Titre', isMandatory:true},
      {id: 'imagePath', picture: 'pictureToUpload', type: 'pictureDropzone', isMandatory: false},
      {id: 'description', type:'richText', label: 'Description', isMandatory:true},
      {id: 'category', type:'textField', gridMD:6, label: 'Catégorie', isMandatory:true},
      {id: 'link', type:'textField', gridMD:6, label: 'Lien', isMandatory:true},
    ];

    if (AuthService.isGranted(ROLE_MODERATOR, this.props.authUser, this.props.roles)) {
      formFields.push({id: 'push', type:'checkboxField', label: 'Notifier les utilisateurs', isMandatory:true});
    }

    const customForm = (
      <CustomForm
        data={this.state}
        formFields={formFields}
        buttonLabel={buttonLabel}
        onSubmit={this.onSubmit}
        sending={sending}
      />
    );

    if (pending) {
      return (load);
    } else {
      return (customForm);
    }
  }
}

function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    roles: state.roles.content,
    token: state.users.authUser.ra,
    trainings: state.trainings.content,
    pending: state.trainings.pending || state.users.pending,
    sending: state.trainings.sending
  };
}

export default compose(
  connect(mapStateToProps, {getTrainingCenters, postTraining, postTrainingPicture, putTraining, handleError,
    getNbModerated}),
)(TrainingForm);
