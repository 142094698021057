import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "recompose";

import Grid from "@material-ui/core/Grid";
import 'react-quill/dist/quill.snow.css';
import Typography from "@material-ui/core/Typography";

import {getNbModerated, getStaticDatas, handleError} from "../../stores/infos/actions";
import {RESOURCES_ADD, RESOURCES_EDIT, ARTIST_EDIT, ARTISTS} from "../../constants/routes";
import Spinner from "../common/Spinner";

import CustomTable from "../common/table/CustomTable";
import CustomForm from "../common/form/CustomForm";
import {
  deleteResource,
  getArtists,
  postArtist, putResource,
  putArtist, postArtistPicture
} from "../../stores/resources/actions";

// Intial State of the Component
const INITIAL_STATE = {
  id: '',
  lastname: '',
  firstname: '',
  category: '',
  city: '',
  description: '',
  link: '',
  resources: [],
  cities: {},
  categories: {},
  imagePath: '',
  pictureToUpload: null,
  sending: false
};

const stateWithArtist = (artist, media) => ({
  id: artist.id,
  lastname: artist.lastname,
  firstname: artist.firstname,
  category: artist.category,
  city: artist.city,
  description: artist.description,
  link: artist.link,
  resources: artist.resources,
  imagePath: media ? media.image_path : ''
});

export class ArtistForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    getStaticDatas: PropTypes.func.isRequired,
    postArtist: PropTypes.func.isRequired,
    putResource: PropTypes.func.isRequired,
    putArtist: PropTypes.func.isRequired,
    deleteResource: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    let newState = {...state};

    if (!props.cities || !props.categories) {
      props.getStaticDatas();
    }
    if (props.cities) {
      newState = {...newState, cities: props.cities};
    }
    if (props.categories) {
      newState = {...newState, categories: props.categories};
    }
    if (!props.resources && !props.pending
      && props.token && props.match && props.match.params.artistId) {
      props.getArtists(props.token);
    }
    if (props.resources && props.match && props.match.params.artistId) {
      const resources = props.resources;
      const currentArtistId = props.match.params.artistId;
      const foundArtist = resources.find(artist => artist.id === currentArtistId);
      if (state.id === INITIAL_STATE.id) {
        if (foundArtist) {
          const media = foundArtist.medias ?
            foundArtist.medias.find(media => media.position === 0) : null;
          newState = {...newState, ...stateWithArtist(foundArtist, media)};
        }
      }
      if (foundArtist.resources !== state.resources
        && state.resources !== INITIAL_STATE.resources) {
        newState = {...newState, resources: foundArtist.resources}
      }
    }

    if (props.sending && !state.sending) { // Start sending
      return {sending: true};
    }
    if (!props.sending && state.sending) { // End sending
      if (state.pictureToUpload && state.id) { // picture To Upload after deal sent
        props.postArtistPicture(props.token, state.id, state.pictureToUpload, (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(state.pictureToUpload.name + ' -> '
            + percentCompleted + '% (' + progressEvent.loaded + ' / ' + progressEvent.total + ')');
        });
        return {
          pictureToUpload: null,
          sending: false
        };
      } else if (!state.pictureToUpload) {
        props.history.push(ARTISTS);
      }
    }

    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  onSubmit = (event, formState) => {

    // Retrieve ImageToUpload if available
    if (formState.pictureToUpload) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        pictureToUpload: formState.pictureToUpload
      });
    }

    if (this.state.id === INITIAL_STATE.id) {
      this.props.postArtist(
        this.props.token,
        { ...formState, type: 0}
      )
        .then((artistId => {
          if (this.state.pictureToUpload) {
            this.setState({id: artistId});
          } else {
            this.props.history.push(ARTIST_EDIT.replace(':artistId', artistId));
          }
        }))
        .catch((error) => {
          this.props.handleError(error);
        });
    } else {
      this.props.putArtist(
        this.props.token,
        this.state.id,
        formState
      )
        .then((artistId => {
          this.props.getNbModerated(this.props.token);
          if (this.state.pictureToUpload) {
            this.setState({id: artistId});
          }
        }))
        .catch((error) => {
          this.props.handleError(error);
        });
    }
    event.preventDefault();
  };

  handleAdd = () => {
    this.props.history.push(RESOURCES_ADD.replace(':artistId', this.state.id));
  };

  handleEdit = element => {
    this.props.history.push(RESOURCES_EDIT
      .replace(':artistId', this.state.id)
      .replace(':resourcesId', element.id));
  };

  handleDelete = element => {
    this.props.deleteResource(this.props.token, this.state.id, element)
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  render() {

    const {pending, sending} = this.props;

    const {id, cities, categories} = this.state;

    const columnData = [
      {id: 'title', label: 'Titre', align: "left", disablePadding: false},
      {id: 'year', label: 'Année', align: "left", disablePadding: false},
      {id: 'moderated', label: 'Modéré', boolean: true, align: "center", disablePadding: false}
    ];

    const buttonLabel = sending ?
      (<Spinner variant="nomargin" />)
      : id === '' ? 'Créer' : 'Éditer';

    const load = (<Spinner />);

    const formFields = [
      {id: 'lastname', type:'textField', label: 'Nom', isMandatory:true},
      {id: 'firstname', type:'textField', label: 'Prénom', isMandatory:true},
      {id: 'imagePath', picture: 'pictureToUpload', type: 'pictureDropzone', isMandatory: false},
      {id: 'category', type:'selectField', gridMD:6, options: categories, label: 'Catégorie', isMandatory:true},
      {id: 'city', type:'selectField', gridMD:6, options: cities, label: 'Ville', isMandatory:true},
      {id: 'description', type:'richText', gridMD:12, label: 'Description', isMandatory:true},
      {id: 'link', type:'textField', gridMD:6, label: 'Lien', isMandatory:true},
    ];

    const customFrom = (
      <div>
        <CustomForm
          data={this.state}
          formFields={formFields}
          buttonLabel={buttonLabel}
          onSubmit={this.onSubmit}
          sending={sending}
        />
        {id &&
        (
          <Grid container>
            <Typography align="left" variant="h6" gutterBottom>
              Resources
            </Typography>
            <Grid item xs={12} sm={12} md={12}>
              {this.props.pending &&
              <Spinner />
              }
              {!this.props.pending && (
                <CustomTable
                  datas={this.state.resources}
                  onAdd={this.handleAdd}
                  onEdit={this.handleEdit}
                  onDelete={this.handleDelete}
                  buttonLabel='Ajouter une resource'
                  paginationLabel='resource par page'
                  emptyDataMessage='Aucune resource'
                  deleteDialogText='Êtes-vous sûr de vouloir supprimer cette resource ?'
                  columnData={columnData}
                  sending={this.props.sending}
                />
              )}
            </Grid>
          </Grid>
        )}
      </div>
    );

    if (pending) {
      return (load);
    } else {
      return (customFrom);
    }
  }
}

function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    roles: state.roles.content,
    token: state.users.authUser.ra,
    cities: state.infos.communes,
    categories: state.infos.artists.categories,
    resources: state.resources.content,
    pending: state.resources.pending,
    sending: state.resources.sending
  };
}

export default compose(
  connect(mapStateToProps,
    {
      getArtists, postArtist, putArtist, putResource, deleteResource,
      handleError, getStaticDatas, postArtistPicture, getNbModerated
    }),
)(ArtistForm);
