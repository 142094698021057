import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

// --------------------------------
// Action creators

export const surveysPendingAction = () => ({
  type: types.SURVEYS_PENDING,
});
export const surveySendingAction = () => ({
  type: types.SURVEY_SENDING,
});
export const surveysResetAction = () => ({
  type: types.SURVEYS_RESET,
});

export const surveysDownloadedAction = surveys => ({
  type: types.SURVEYS_DOWNLOADED,
  payload: surveys,
});

export const surveyDownloadedAction = (survey) => ({
  type: types.SURVEY_DOWNLOADED,
  payload: survey
});

export const surveyRemovedAction = (survey) => ({
  type: types.SURVEY_REMOVED,
  payload: survey,
});

export const surveysFailedAction = error => ({
  type: types.SURVEYS_FAILED,
  payload: error,
});


// --------------------------------
// Actions

export function resetSurveys() {
  return (dispatch) => {
    dispatch(surveysResetAction());
  };
}

export function getSurveys(token) {
  return (dispatch) => {
    dispatch(surveysPendingAction());
    return ApiService
      .getSurveys(token)
      .then((data) => {
        dispatch(surveysDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(surveysFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postSurvey(token, survey) {
  return (dispatch) => {
    dispatch(surveySendingAction());

    return ApiService
      .postSurvey(token, survey)
      .then((data) => {
        dispatch(surveyDownloadedAction(data));
        return data.id
      })
      .catch((error) => {
        dispatch(surveysFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postSurveyPicture(token, surveyId, picture, position, onUploadProgress = null) {
  return (dispatch) => {
    dispatch(surveySendingAction());
    return ApiService
      .postPicture(token, {entity_id: surveyId, entity_name: "survey", position: position}, picture, onUploadProgress)
      .then((result) => {
        dispatch(surveyDownloadedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(surveysFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

export function putSurvey(token, surveyId, survey) {
  return (dispatch) => {
    dispatch(surveySendingAction());
    return ApiService
      .putSurvey(token, surveyId, survey)
      .then((data) => {
        const updatedSurvey = {
          ...data,
          id: surveyId,
        };
        dispatch(surveyDownloadedAction(updatedSurvey));
        return data.id
      })
      .catch((error) => {
        dispatch(surveysFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function deleteSurvey(token, survey) {
  return (dispatch) => {
    dispatch(surveySendingAction());
    return ApiService
      .deleteSurvey(token, survey.id)
      .then(() => {
        dispatch(surveyRemovedAction(survey));
      })
      .catch((error) => {
        dispatch(surveysFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
