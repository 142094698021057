import React, {Component} from 'react';
import {compose} from "recompose";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {handleError} from "../../stores/infos/actions";
import LargePaper from "../../components/common/LargePaper";
import withAuthorization from "../../hocs/withAuthorization";
import Spinner from "../../components/common/Spinner";
import {ARTIST_EDIT, ARTIST_ADD} from "../../constants/routes";
import CustomTable from "../../components/common/table/CustomTable";
import AuthService from "../../services/AuthService";
import {ROLE_ADMIN} from "../../constants/roles";
import {deleteArtist, getArtists} from "../../stores/resources/actions";

const INITIAL_STATE = {
  datas: []
};

export class ArtistsPage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    getArtists: PropTypes.func.isRequired,
    deleteArtist: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    authAdmin: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (props.authUser && !props.resources && !props.pending) {
      props.getArtists(props.authUser.ra);
    }
    if (props.resources) {
      if (state.datas === INITIAL_STATE.datas) {
        newState = {...newState, datas: props.resources};
      }
      if (props.resources !== state.datas && state.datas !== INITIAL_STATE.datas) {
        newState = {...newState, datas: props.resources}
      }
    }
    return newState;
  }

  handleAdd = () => {
    this.props.history.push(ARTIST_ADD);
  };

  handleEdit = element => {
    this.props.history.push(ARTIST_EDIT.replace(':artistId', element.id));
  };

  handleDelete = element => {
    this.props.deleteArtist(this.props.authUser.ra, element)
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  render() {

    const columnData = [
      {id: 'lastname', label: 'Nom', align: "left", disablePadding: false},
      {id: 'firstname', label: 'Prénom', align: "left", disablePadding: false},
      {id: 'category', label: 'Catégorie', align: "left", disablePadding: false},
      {id: 'city', label: 'Ville', align: "left", disablePadding: false},
      {id: 'moderated', label: 'Modéré', boolean: true, align: "center", disablePadding: false}
    ];
    return (
      <LargePaper title="Gestion des artistes">
        <div>
          {this.props.pending &&
          <Spinner />
          }
          {!this.props.pending && (
            <CustomTable
              datas={this.state.datas}
              onAdd={this.handleAdd}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
              buttonLabel='Ajouter un artiste'
              paginationLabel='artiste par page'
              emptyDataMessage='Aucun artiste'
              deleteDialogText='Êtes-vous sûr de vouloir supprimer cet artiste ?'
              columnData={columnData}
              sending={this.props.sending}
            />
          )}
        </div>
      </LargePaper>
    );
  }
}


function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    resources: state.resources.content,
    pending: state.resources.pending,
    sending: state.resources.sending,
    error: state.resources.error,
  };
}

export default compose(
  withAuthorization('Artistes'),
  connect(mapStateToProps, {getArtists, deleteArtist, handleError})
)(ArtistsPage);
