import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';

import infosReducer from './infos/reducers';
import usersReducer from './users/reducers';
import rolesReducer from './roles/reducers';
import surveysReducer from './surveys/reducers';
import transportsReducer from './transports/reducers';
import locationsReducer from './locations/reducers';
import housingsReducer from './housings/reducers';
import trainingsReducer from './trainings/reducers';
import treatsReducer from './treats/reducers';
import citizenshipsReducer from './citizenships/reducers';
import jobsReducer from './jobs/reducers';
import signalsReducer from './signals/reducers';
import resourcesReducer from './resources/reducers';

const reducers = combineReducers({
  infos: infosReducer,
  users: usersReducer,
  roles: rolesReducer,
  surveys: surveysReducer,
  transports: transportsReducer,
  locations: locationsReducer,
  housings: housingsReducer,
  trainings: trainingsReducer,
  treats: treatsReducer,
  citizenships: citizenshipsReducer,
  jobs: jobsReducer,
  signals: signalsReducer,
  resources: resourcesReducer
});

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export default store;
