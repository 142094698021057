import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import CitizenshipCenterForm from "../../components/forms/CitizenshipCenterForm";
import LargePaper from "../../components/common/LargePaper";

export const CitizenshipCenterEditPage = props => {
  return (
    <LargePaper title="Éditer une thématique">
      <CitizenshipCenterForm history={props.history} match={props.match} />
    </LargePaper>
  );
};

CitizenshipCenterEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withAuthorization('Citoyenneté')(CitizenshipCenterEditPage);
