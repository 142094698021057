import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from "@material-ui/core/styles/withStyles";
import {VERDUN_GRADIENT_BLUE, VERDUN_BLUE_LIGHT} from "../../../constants/colors";

const styles = () => ({
  head: {
    backgroundColor: VERDUN_GRADIENT_BLUE,
    color: VERDUN_BLUE_LIGHT,
    fontSize: '1em',
    fontWeight: 'bold'
  }
});

export class CustomTableHead extends Component {

  createSortHandler = property => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {order, orderBy, classes, columnData} = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData.map(column => {
            let label = this.props.onRequestSort ?
              (
                <Tooltip
                  title='Trier'
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={(orderBy === column.id || orderBy === column.specialOrderBy)}
                    direction={order === 'asc' ? 'desc' : 'asc'}
                    onClick={
                      this.createSortHandler(
                        (column.specialOrderBy) ? column.specialOrderBy : column.id
                      )
                    }
                  >
                    {`${column.label}`}
                  </TableSortLabel>
                </Tooltip>
              )
              :
              column.label;

            if (column.id) {
              return (
                <TableCell
                  className={classes.head}
                  key={column.id}
                  align={column.align}
                  padding={column.disablePadding ? 'none' : 'default'}
                  sortDirection={
                    (orderBy === column.id || orderBy === column.specialOrderBy) ? order : false
                  }
                >
                  {label}
                </TableCell>
              );
            } else {
              return (
                <TableCell
                  className={classes.head}
                  key='empty'
                />
              );
            }
          })
          }
          <TableCell
            key='empty'
            className={classes.head}
          />
        </TableRow>
      </TableHead>
    )
  }
}

CustomTableHead.propTypes = {
// eslint-disable-next-line react/require-default-props
  onRequestSort: PropTypes.func,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  columnData: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomTableHead);
