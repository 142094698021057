import { ENV_LOCAL, ENV_DEV } from './env';

let backendHost = 'https://verdun-api.openium.fr';
const apiVersion = 'v1';

if (process.env.REACT_APP_ENV) {
  if (process.env.REACT_APP_ENV === ENV_LOCAL) {
    backendHost = 'https://verdun-api-master.openium.fr';
  } else if (process.env.REACT_APP_ENV === ENV_DEV) {
    backendHost = 'https://verdun-api-dev.openium.fr';
  }
}

const API_ROOT = `${backendHost}/api/${apiVersion}`;
export default API_ROOT;
