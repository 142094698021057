import React from 'react';
import PropTypes from "prop-types";
import withAuthorization from "../../hocs/withAuthorization";
import ArtistForm from "../../components/forms/ArtistForm";
import LargePaper from "../../components/common/LargePaper";

export const ArtistAddPage = props => {
  return (
    <LargePaper title="Ajouter un artiste">
      <ArtistForm history={props.history} />
    </LargePaper>
  );
};

ArtistAddPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default withAuthorization('Artistes')(ArtistAddPage);
