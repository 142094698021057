import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';

const styles = () => ({
  smallLayout: {
    margin: '2vh 1.5em 1.5em 1.5em',
    overflow: 'hidden',
    padding: '10px',
    zIndex: 1,
  },
});

export const SmallPaper = (props) => {
  return (props.children) ?
    (
      <Grid container spacing={0}>
        <Grid item xs={false} sm={2} md={3} lg={3} />
        <Grid item xs={12} sm={8} md={6} lg={6}>
          <Paper className={props.classes.smallLayout}>
            {props.children}
          </Paper>
        </Grid>
      </Grid>
    )
    : null
};

SmallPaper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.array.isRequired,
};

export default withStyles(styles)(SmallPaper);
