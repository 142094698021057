import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.TRAINING_CENTERS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.TRAINING_CENTER_SENDING:
      return {
        ...state,
        sending: true,
      };
    case types.TRAININGS_RESET:
      return {
        ...INITIAL_STATE
      };
    case types.TRAINING_CENTERS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.TRAINING_CENTER_DOWNLOADED: {
      const centerDownloaded = action.payload;
      let centers = [];
      let centerIndex = -1;
      if (state.content) {
        centers = state.content.slice();
        centerIndex = centers.findIndex(center => center.id === centerDownloaded.id);
      }
      if (centerIndex === -1) {
        centers.push(centerDownloaded);
      } else {
        centers[centerIndex] = centerDownloaded;
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: centers,
      };
    }
    case types.TRAINING_DOWNLOADED: {
      const centerId = action.payload.centerId;
      const trainingDownloaded = action.payload.training;
      let trainingCenter;
      let centers = [];
      let centerIndex = -1;
      let trainings = [];
      let trainingIndex = -1;
      if (state.content) {
        centers = state.content.slice();
        centerIndex = centers.findIndex(center => center.id === centerId);
        trainingCenter = centers[centerIndex];
        if (!trainingCenter.trainings) {
          trainingCenter.trainings = [];
        }
        trainings = trainingCenter.trainings;
        trainingIndex = trainings.findIndex(training => training.id === trainingDownloaded.id);
      }
      if (trainingIndex === -1) {
        trainingCenter.trainings.push(trainingDownloaded);
      } else {
        trainingCenter.trainings[trainingIndex] = trainingDownloaded;
      }

      centers[centerIndex] = trainingCenter;
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: centers,
      };
    }
    case types.TRAINING_CENTER_REMOVED: {
      const center = action.payload;
      let centers = state.content.slice();
      if (state.content !== null) {
        let isCenter = (centerId) => {
          return function (element) {
            return element.id !== centerId;
          }
        };
        centers = state.content.filter(isCenter(center.id));
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: centers,
      };
    }

    case types.TRAINING_REMOVED: {
      const training = action.payload;
      let centers = state.content.slice();
      let centerIndex = centers.findIndex(center => center.trainings.includes(training));
      if (state.content !== null && centerIndex !== -1) {
        let isTraining = (trainingId) => {
          return function (element) {
            return element.id !== trainingId;
          }
        };
        let center = centers[centerIndex];
        center.trainings = center.trainings.filter(isTraining(training.id));
        centers[centerIndex] = center;
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: centers,
      };
    }
    case types.TRAINING_CENTERS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    default:
      break;
  }
  return state;
}
