import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

// --------------------------------
// Action creators

export const trainingCentersPendingAction = () => ({
  type: types.TRAINING_CENTERS_PENDING,
});
export const trainingCenterSendingAction = () => ({
  type: types.TRAINING_CENTER_SENDING,
});
export const trainingsResetAction = () => ({
  type: types.TRAININGS_RESET,
});

export const trainingCentersDownloadedAction = centers => ({
  type: types.TRAINING_CENTERS_DOWNLOADED,
  payload: centers,
});

export const trainingCenterDownloadedAction = (center) => ({
  type: types.TRAINING_CENTER_DOWNLOADED,
  payload: center
});

export const trainingDownloadedAction = (centerId, training) => ({
  type: types.TRAINING_DOWNLOADED,
  payload: {
    centerId: centerId,
    training: training
  }
});

export const trainingCenterRemovedAction = (center) => ({
  type: types.TRAINING_CENTER_REMOVED,
  payload: center,
});

export const trainingRemovedAction = (training) => ({
  type: types.TRAINING_REMOVED,
  payload: training,
});

export const trainingCentersFailedAction = error => ({
  type: types.TRAINING_CENTERS_FAILED,
  payload: error,
});


// --------------------------------
// Actions

export function resetTrainings() {
  return (dispatch) => {
    dispatch(trainingsResetAction());
  };
}

// Récupèration de la liste des themes
export function getTrainingCenters(token) {
  return (dispatch) => {
    dispatch(trainingCentersPendingAction());
    return ApiService
      .getTrainingCenters(token)
      .then((data) => {
        dispatch(trainingCentersDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(trainingCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'un theme
export function postTrainingCenter(token, trainingCenter) {
  return (dispatch) => {
    dispatch(trainingCenterSendingAction());

    return ApiService
      .postCenter(token, trainingCenter)
      .then((data) => {
        dispatch(trainingCenterDownloadedAction(data));
        return data.id
      })
      .catch((error) => {
        dispatch(trainingCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'un bloc
export function postTraining(token, centerId, training) {
  return (dispatch) => {
    dispatch(trainingCenterSendingAction());

    return ApiService
      .postTraining(token, centerId, training)
      .then((data) => {
        dispatch(trainingDownloadedAction(centerId, data));
        return data.id
      })
      .catch((error) => {
        dispatch(trainingCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Ajout d'une image pour un bloc
export function postTrainingPicture(
  token,
  centerId,
  trainingId,
  picture,
  onUploadProgress = null
) {
  return (dispatch) => {
    dispatch(trainingCenterSendingAction());
    return ApiService
      .postPicture(token, {entity_id: trainingId, entity_name: "training", position: 0}, picture, onUploadProgress)
      .then((result) => {
        dispatch(trainingDownloadedAction(centerId, result));
        return result;
      })
      .catch((error) => {
        dispatch(trainingCentersFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

// Edition d'un theme
export function putTrainingCenter(token, centerId, center) {
  return (dispatch) => {
    dispatch(trainingCenterSendingAction());

    return ApiService
      .putCenter(token, centerId, center)
      .then((data) => {
        const updatedTrainingCenter = {
          ...data,
          id: centerId,
        };
        dispatch(trainingCenterDownloadedAction(updatedTrainingCenter));
        return data.id
      })
      .catch((error) => {
        dispatch(trainingCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Edition d'un theme
export function putTraining(token, centerId, trainingId, training) {
  return (dispatch) => {
    dispatch(trainingCenterSendingAction());

    return ApiService
      .putTraining(token, centerId, trainingId, training)
      .then((data) => {
        const updatedTraining = {
          ...data,
          id: trainingId,
        };
        dispatch(trainingDownloadedAction(centerId, updatedTraining));
        return data.id
      })
      .catch((error) => {
        dispatch(trainingCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Suppression d'un theme
export function deleteTrainingCenter(token, trainingCenter) {
  return (dispatch) => {
    dispatch(trainingCenterSendingAction());
    return ApiService
      .deleteCenter(token, trainingCenter.id)
      .then(() => {
        dispatch(trainingCenterRemovedAction(trainingCenter));
      })
      .catch((error) => {
        dispatch(trainingCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

// Suppression d'un themeBloc
export function deleteTraining(token, centerId, training) {
  return (dispatch) => {
    dispatch(trainingCenterSendingAction());
    return ApiService
      .deleteTraining(token, centerId, training.id)
      .then(() => {
        dispatch(trainingRemovedAction(training));
      })
      .catch((error) => {
        dispatch(trainingCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
