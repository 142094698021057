import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "recompose";

import Grid from "@material-ui/core/Grid";
import 'react-quill/dist/quill.snow.css';
import Typography from "@material-ui/core/Typography";

import {getNbModerated, getStaticDatas, handleError} from "../../stores/infos/actions";
import {TREAT_CENTERS, TREAT_ADD, TREAT_EDIT, TREAT_CENTER_EDIT} from "../../constants/routes";
import Spinner from "../common/Spinner";

import AuthService from "../../services/AuthService";
import {ROLE_ADMIN} from "../../constants/roles";
import CustomTable from "../common/table/CustomTable";
import CustomForm from "../common/form/CustomForm";
import {
  deleteTreat,
  getTreatCenters,
  postTreatCenter, putTreat,
  putTreatCenter
} from "../../stores/treats/actions";

// Intial State of the Component
const INITIAL_STATE = {
  id: '',
  name: '',
  category: '',
  city: '',
  address: '',
  treats: [],
  cities: {},
  categories: {}
};

const stateWithCenter = center => ({
  id: center.id,
  name: center.name,
  category: center.category,
  city: center.city,
  address: center.address,
  treats: center.treats,
});

export class TreatCenterForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    getStaticDatas: PropTypes.func.isRequired,
    postTreatCenter: PropTypes.func.isRequired,
    putTreat: PropTypes.func.isRequired,
    putTreatCenter: PropTypes.func.isRequired,
    deleteTreat: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    let newState = {...state};

    if (!props.cities || !props.categories) {
      props.getStaticDatas();
    }
    if (props.cities) {
      newState = {...newState, cities: props.cities};
    }
    if (props.categories) {
      newState = {...newState, categories: props.categories};
    }
    if (!props.treats && !props.pending
      && props.token && props.match && props.match.params.centerId) {
      props.getTreatCenters(props.token);
    }
    if (props.treats && props.match && props.match.params.centerId) {
      const treats = props.treats;
      const currentCenterId = props.match.params.centerId;
      const foundCenter = treats.find(center => center.id === currentCenterId);
      if (state.id === INITIAL_STATE.id) {
        if (foundCenter) {
          newState = {...newState, ...stateWithCenter(foundCenter)};
        }
      }
      if (foundCenter.treats !== state.treats
        && state.treats !== INITIAL_STATE.treats) {
        newState = {...newState, treats: foundCenter.treats}
      }
    }

    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  onSubmit = (event, formState) => {
    if (this.state.id === INITIAL_STATE.id) {
      this.props.postTreatCenter(
        this.props.token,
        { ...formState, type: 1}
      )
        .then((centerId => {
          this.props.history.push(TREAT_CENTER_EDIT.replace(':centerId', centerId));
        }))
        .catch((error) => {
          this.props.handleError(error);
        });
    } else {
      this.props.putTreatCenter(
        this.props.token,
        this.state.id,
        formState
      )
        .then(() => {
          this.props.getNbModerated(this.props.token);
          this.props.history.push(TREAT_CENTERS);
        })
        .catch((error) => {
          this.props.handleError(error);
        });
    }
    event.preventDefault();
  };

  handleAdd = () => {
    this.props.history.push(TREAT_ADD.replace(':centerId', this.state.id));
  };

  handleEdit = element => {
    this.props.history.push(TREAT_EDIT
      .replace(':centerId', this.state.id)
      .replace(':treatId', element.id));
  };

  handleDelete = element => {
    this.props.deleteTreat(this.props.token, this.state.id, element)
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  render() {

    const {pending, sending} = this.props;

    const {id, cities, categories} = this.state;

    const columnData = [
      {id: 'title', label: 'Titre', align: "left", disablePadding: false},
      {id: 'category', label: 'Catégorie', align: "left", disablePadding: false},
      {id: 'description', label: 'Description', align: "left", disablePadding: false},
      {id: 'moderated', label: 'Modéré', boolean: true, align: "center", disablePadding: false}
    ];

    const buttonLabel = sending ?
      (<Spinner variant="nomargin" />)
      : id === '' ? 'Créer' : 'Éditer';

    const load = (<Spinner />);

    const formFields = [
      {id: 'name', type:'textField', label: 'Nom', isMandatory:true},
      {id: 'category', type:'selectField', gridMD:6, options: categories, label: 'Catégorie', isMandatory:true},
      {id: 'city', type:'selectField', gridMD:6, options: cities, label: 'Ville', isMandatory:true},
      {id: 'address', type:'textField', label: 'Adresse', isMandatory:true},
    ];

    const customFrom = (
      <div>
        <CustomForm
          data={this.state}
          formFields={formFields}
          buttonLabel={buttonLabel}
          onSubmit={this.onSubmit}
          sending={sending}
        />
        {id &&
        (
          <Grid container>
            <Typography align="left" variant="h6" gutterBottom>
              Se soigner
            </Typography>
            <Grid item xs={12} sm={12} md={12}>
              {this.props.pending &&
              <Spinner />
              }
              {!this.props.pending && (
                <CustomTable
                  datas={this.state.treats}
                  onAdd={this.handleAdd}
                  onEdit={this.handleEdit}
                  onDelete={this.handleDelete}
                  buttonLabel='Ajouter un soin'
                  paginationLabel='soin par page'
                  emptyDataMessage='Aucun soin'
                  deleteDialogText='Êtes-vous sûr de vouloir supprimer ce soin ?'
                  columnData={columnData}
                  sending={this.props.sending}
                />
              )}
            </Grid>
          </Grid>
        )}
      </div>
    );

    if (pending) {
      return (load);
    } else {
      return (customFrom);
    }
  }
}

function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    roles: state.roles.content,
    token: state.users.authUser.ra,
    cities: state.infos.communes,
    categories: state.infos.treats.categories,
    treats: state.treats.content,
    pending: state.treats.pending,
    sending: state.treats.sending
  };
}

export default compose(
  connect(mapStateToProps,
    {
      getTreatCenters, postTreatCenter, putTreatCenter, putTreat, deleteTreat, handleError, getStaticDatas,
      getNbModerated
    }),
)(TreatCenterForm);
